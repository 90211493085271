import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import React from "react"
import WebsiteNewsForm from "./_tabs/WebsiteNewsForm"
import WebsiteNewsTable from "./_tabs/WebsiteNewsTable"

const WebsiteNews = () => {
  return (
    <div className="page-content">
      <TabModulesTemplate
        documentTabs={[
          {
            label: "Form",
            Component: opened => <WebsiteNewsForm opened={opened} />,
          },
        ]}
        collectionTabs={[
          {
            label: "Table",
            Component: () => <WebsiteNewsTable />,
          },
        ]}
      />
    </div>
  )
}

export default WebsiteNews
