import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import { withRouter } from "react-router-dom"
import CityBudgetProposalsForm from "./_tabs/CityBudgetProposalsForm"
import CityBudgetProposalsTable from "./_tabs/CityBudgetProposalsTable"
import CityBudgetProposalsComments from "./_tabs/CityBudgetProposalsComments"

const CityBudgetProposals = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Budget Proposal",
          Component: opened => <CityBudgetProposalsForm opened={opened} />,
        },
        {
          label: "Comments",
          Component: opened => <CityBudgetProposalsComments opened={opened} />,
          requiresID: true,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityBudgetProposalsTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityBudgetProposals)
