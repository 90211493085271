import FormModule from "components/Modules/Documents/FormModule"
import { firestoreObject } from "helpers/Firebase"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  createFirestoreDocument,
  getDataFromFirestoreCollection,
  getFirestoreDocument,
} from "helpers/Firestore"
import React from "react"

const CityProblemsReportForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Problem report",
        fields: [
          {
            Name: "Name",
            Label: "Name",
            Type: "Text",
            ID: "uwirvj90h248iovwrehuvbsvnd9p24ihout",
          },
          {
            ID: "339C10F4-389B-461F-94F9-vsrt",
            Type: "Text",
            Name: "Address",
            Label: "Address",
          },
          {
            ID: "339C10F4-389B-461F-94F9-85B7737D3497",
            Type: "Text",
            Name: "Geolocation",
            Label: "Geolocation",
          },
          {
            ID: "AC3EF22B-D27D-4B3B-B327-B196AE1C6317",
            Type: "Dropdown",
            Name: "Category",
            Label: "Category",

            GetSelectionOptions: async (values,{ firebaseUID}) => {
              return await getDataFromFirestoreCollection({
                path: `City/Cities/DataBase/${firebaseUID}/Modules/35/Categories`,
                where: [{ key: "Parent", operation: "==", value: null }],
              })
            },
            CreateSelectionOption: async (input, values, firebaseUID) => {
              return await createFirestoreDocument({
                path: `City/Cities/DataBase/${firebaseUID}/Modules/35/Categories`,
                values: { Name: input, ID: input, Parent: null },
              })
            },
            DeleteSelectionOption: async (optionID, values, firebaseUID) => {
              return await deleteFirestoreDocument({
                path: `City/Cities/DataBase/${firebaseUID}/Modules/35/Categories/${optionID}`,
              })
            },
          },
          {
            ID: "DA248088-A785-472B-9516-9208547",
            Type: "Text",
            Name: "Description",
            Label: "Description",
            FieldType: "textarea",
          },
          {
            ID: "drjnbvlesfdvers",
            Type: "Gallery",
            Name: "Pictures",
            Label: "Pictures",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          return await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase/${opened}`,
          })
        },
        onSubmit: async (values,{ firebaseUID}) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase`,
            values,
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityProblemsReportForm
