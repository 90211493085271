import React, { useEffect, useState } from "react"
import { CustomInput, Label } from "reactstrap"
import { AvGroup } from "availity-reactstrap-validation"

import CheckboxButtons from "./CheckboxButtons"
import { clientTypeCategoryIDs } from "constants/defaultValues/defaultValues"
import { getAllClientsByCategory } from "helpers/API"
import { compareCroatianString } from "helpers/Utils"

const CitySelector = ({ item, value, disabled, updateValue }) => {
  const [cities, setCities] = useState([])
  // const [regions, setRegions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    const data = await getAllClientsByCategory(
      clientTypeCategoryIDs["Local government"]
    )

    const sortedData = data.Relations

    sortedData.sort((a, b) => compareCroatianString(a.Name, b.Name))

    setCities(
      sortedData.map(x =>
        item.useFirebaseUID ? { ...x, ID: x.FirebaseUID, Client_ID: x.ID } : x
      )
    )
    setLoading(false)
  }

  const setSelected = val => {
    updateValue(val)
  }

  return loading ? (
    <div />
  ) : (
    <AvGroup className="error-t-negative error-l-150">
      <Label>{item.Label}</Label>
      <CustomInput
        type="checkbox"
        id="select-all-button"
        label={cities.length === value.length ? "Remove All" : "Select All"}
        checked={cities.length === value.length}
        onChange={() => {
          if (cities.length === value.length) {
            const newList = []
            setSelected(newList)
          } else {
            setSelected([...cities])
          }
        }}
        disabled={disabled}
      />
      <CheckboxButtons
        disabled={disabled}
        item={{
          Label: "",
          GetSelectionOptions: () => cities,
          ID: "city-selection",
        }}
        value={value}
        updateValue={val => setSelected(val)}
      />
    </AvGroup>
  )
}

export default CitySelector
