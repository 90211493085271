import React, { Component } from "react"
import { Button, Card, CardBody, Label } from "reactstrap"
import DropzoneComponent from "react-dropzone-component"
import { connect } from "react-redux"
import { storage } from "../../../helpers/Firebase"

const ReactDOMServer = require("react-dom/server")

const dropzoneComponentConfig = {
  postUrl: "no-url",
}
const dropzoneConfig = {
  thumbnailHeight: 160,
  maxFiles: 1,
  acceptedFiles: ".xlsx",
  autoProcessQueue: false,
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div className="dz-preview dz-file-preview mb-3">
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress />
          </div>
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#/" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
}

class UploadPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  clear() {
    this.pictureDropzone.removeAllFiles(true)
  }

  render() {
    const { firebaseUID } = this.props

    const ref = storage
      .ref()
      .child(`Clients/DataBase/${firebaseUID}/UploadExcel/`)

    return (
      <>
        <Label>Upload your Excel here</Label>
        <DropzoneComponent
          config={dropzoneComponentConfig}
          djsConfig={dropzoneConfig}
          eventHandlers={{
            init: dropzone => {
              this.excelDropzone = dropzone
            },
            addedfile: file => {
              const timeStamp = Date.now()

              const imageRef = ref.child(`${timeStamp}`)

              imageRef.put(file).then(async () => {
                const downloadURL = await imageRef.getDownloadURL()
                this.setState({ downloadURL })
              })
            },
            removedfile: () => {
              const { downloadURL } = this.state

              storage
                .refFromURL(downloadURL)
                .delete()
                .then(() => this.setState({ downloadURL: false }))
            },
          }}
        />
        <Button
          className="float-right mt-2"
          color="primary"
          disabled={!this.state.downloadURL}
          onClick={() => {
            const { downloadURL } = this.state
            console.log(downloadURL)
          }}
        >
          Submit
        </Button>
      </>
    )
  }
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  return { firebaseUID: client.ID }
}
export default connect(mapStateToProps, {})(UploadPage)
