import React from "react"

import StartPage from "../../../../components/Admin/AccountManager/StartPage"
import { getDataFromFirestoreCollection } from "helpers/Firestore"

const CitiesList = ({ history }) => {
  return (
    <StartPage
      title="acccounts-manager.cities"
      getClients={() => {
        return getDataFromFirestoreCollection({
          path: "City/Cities/DataBase",
          sortBy: { key: "Name" },
        })
      }}
      clientURL="cities"
      history={history}
    />
  )
}

export default CitiesList
