import React, { useEffect, useState } from "react"
import { Button, Label, Input } from "reactstrap"
import Select from "react-select"

import { getSelectTheme } from "helpers/Utils"
import {
  createFirestoreDocument,
  firestoreBatchCommit,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import {
  getApplicationMenuByApplicationID,
  getClientByClientID,
} from "helpers/API"
import NotificationAlert from "components/Common/Notifications/NotificationAlert"

const menuItemAppStatusOptions = [
  {
    ID: "Inactive",
    Name: "Inactive",
  },
  {
    ID: "Active",
    Name: "Active",
  },
  {
    ID: "Unpaid",
    Name: "Unpaid",
  },
]

const Modules = ({ clientID }) => {
  const [loading, setLoading] = useState(true)
  const [modules, setModules] = useState([])
  const [working, setWorking] = useState(false)
  const [clientData, setClientData] = useState()
  const [firebaseUID, setFirebaseUID] = useState()

  const menuItemConfigs = {
    108: {
      available: value => {
        return !value.q
      },
      setDefaults: async () => {
        await createFirestoreDocument({
          path: `City/Modules/DataBase/108/WeatherAPIConfigs`,
          values: {
            ID: firebaseUID,
            cityID: firebaseUID,
            q: clientData.Name,
          },
        })

        NotificationAlert({ type: "success", title: "Success" })
      },
    },
  }

  useEffect(() => {
    setLoading(true)
    load()
  }, [clientID])

  const load = async () => {
    const clientData = await getClientByClientID(clientID)
    setFirebaseUID(clientData.FirebaseUID)
    setClientData(clientData)

    const modulesFirestore = await getDataFromFirestoreCollection({
      path: `City/Cities/DataBase/${clientData.FirebaseUID}/Modules`,
    })

    const menu = await getApplicationMenuByApplicationID("5")

    const apiMenuItems = []

    Object.keys(menu.MenuItems).forEach(levelOneID => {
      apiMenuItems.push({
        Name: menu.MenuItems[levelOneID].DisplayName,
        ID: levelOneID,
        Status: "Inactive",
      })

      Object.keys(menu.MenuItems[levelOneID].MenuItems).map(levelTwoID => {
        apiMenuItems.push({
          Name: menu.MenuItems[levelOneID].MenuItems[levelTwoID].DisplayName,
          ID: levelTwoID,
          Status: "Inactive",
        })
      })
    })

    setModules(
      apiMenuItems.map(apiMenuItem => {
        const moduleFirestore = modulesFirestore.find(
          firestoreMenuItem => firestoreMenuItem.ID === apiMenuItem.ID
        )

        if (moduleFirestore)
          return {
            ...apiMenuItem,
            ...moduleFirestore,
            ...menuItemConfigs[apiMenuItem.ID],
          }

        return { ...apiMenuItem, IsNew: true }
      })
    )
    setLoading(false)
  }

  const onSubmit = async () => {
    setWorking(true)
    await firestoreBatchCommit({
      commits: modules.map(module => {
        return {
          type: module.IsNew ? "set" : "update",
          path: `City/Cities/DataBase/${firebaseUID}/Modules/${module.ID}`,
          values: { ID: module.ID, Name: module.Name, Status: module.Status },
        }
      }),
    })

    NotificationAlert({
      type: "success",
      title: "Success",
      expiration: 3000,
    })

    setModules(
      modules.map(module => {
        return { ...module, ...menuItemConfigs[module.ID] }
      })
    )
    setWorking(false)
  }

  const editModule = (ID, value) => {
    setModules(modules.map(x => (x.ID === ID ? { ...x, Status: value } : x)))
  }

  const editAllStatus = value => {
    setModules(modules.map(x => ({ ...x, Status: value })))
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <div className="col-12">
            <div className="d-flex flex-lg-row justify-content-between min-width-zero pl-2 pr-2">
              <div className="col-4 p-1">
                <Label>Name</Label>
              </div>
              <div className="col-2 p-1">
                <Label>ID</Label>
              </div>
              <div className="col-2 p-1">
                <Label>NEW</Label>
              </div>
              <div className="col-4 p-1">
                <Label>Status</Label>
              </div>
            </div>
          </div>

          {modules.map(module => (
            <div className="col-12" key={`menuItemStatusRow_${module.ID}`}>
              <div className="d-flex flex-lg-row justify-content-between min-width-zero pl-2 pr-2 background-grey">
                <div className="col-4 p-1">
                  <Label>{module.Name}</Label>
                </div>

                <div className="col-2 p-1">
                  <Label>{module.ID}</Label>
                </div>

                <div className="col-2 p-1">
                  <Label>{module.isNew ? "NEW" : "OLD"}</Label>
                </div>

                <div className="col-2 p-1">
                  <Select
                    theme={getSelectTheme}
                    classNamePrefix="select2-selection"
                    onChange={e => editModule(module.ID, e.ID)}
                    options={menuItemAppStatusOptions}
                    defaultValue={menuItemAppStatusOptions.filter(
                      x => x.ID === module.Status
                    )}
                    value={menuItemAppStatusOptions.filter(
                      x => x.ID === module.Status
                    )}
                    isOptionSelected={option => option.ID === module.Status}
                    getOptionLabel={option => option.Name}
                  />
                </div>

                <div className="col-2 p-1 ">
                  {typeof module.setDefaults === "function" &&
                    typeof module.available === "function" &&
                    module.available(module) && (
                      <Button
                        className="w-100"
                        onClick={() => {
                          module.setDefaults()
                        }}
                      >
                        Set default
                      </Button>
                    )}
                </div>
              </div>
            </div>
          ))}

          <div className="col-12 mt-1">
            <div className="d-flex flex-lg-row justify-content-between min-width-zero p-2 background-grey">
              <div className="col-8 p-1">
                <Label className="mt-2">Edit all</Label>
              </div>

              <div className="col-2 p-1">
                <Select
                  theme={getSelectTheme}
                  classNamePrefix="select2-selection"
                  onChange={e => editAllStatus(e.ID)}
                  options={menuItemAppStatusOptions}
                  defaultValue={[]}
                  value={[]}
                  isOptionSelected={option => false}
                  getOptionLabel={option => option.Name}
                />
              </div>

              <div className="col-2 p-1 ">
                {modules.find(
                  x =>
                    typeof x.setDefaults === "function" &&
                    typeof module.available === "function" &&
                    module.available(module)
                ) && (
                  <Button
                    className="w-100"
                    onClick={() => {
                      modules.forEach(module => {
                        if (
                          typeof module.setDefaults === "function" &&
                          typeof module.available === "function" &&
                          module.available(module)
                        )
                          module.setDefaults()
                      })
                    }}
                  >
                    Set all defaults
                  </Button>
                )}
              </div>
            </div>
          </div>

          <Button
            color="primary"
            className="float-right mr-4 mb-2"
            onClick={() => onSubmit()}
            disabled={working}
          >
            Submit
          </Button>
        </>
      )}
    </>
  )
}

export default Modules
