const SquareBannerFields = [
  {
    ID: "23",
    Name: "",
    Type: "Separator",
  },
  {
    ID: "EDE95D97-B65D-4C8A-BD44-7AC1C8A5B9DE",
    Name: "Title",
    Label: "Title",
    Type: "Text",
    Prompt: "Title is required",
  },
  {
    ID: "EDE95D97-B65D-4C8A-3547686-7AC1C8A5B9DE",
    Name: "SuperSuperTitle",
    Label: "SuperSuperTitle",
    Type: "Text",
  },
  {
    ID: "EDE95D97-B65D-4C8A-235356-7AC1C8A5B9DE",
    Name: "SuperTitle",
    Label: "SuperTitle",
    Type: "Text",
  },
  {
    ID: "EDE95D97-B65D-4C8A-5365473524-7AC1C8A5B9DE",
    Name: "SubTitle",
    Label: "SubTitle",
    Type: "Text",
  },
  {
    ID: "CF5E0A4F-BF8D-235464-9DFF-0A1DDD1CC4A1",
    Type: "Single Image",
    Name: "Image",
    Label: "Image",
    Path: "Campaigns/",
  },
  {
    ID: "2435",
    Name: "",
    Type: "Separator",
  },
  {
    ID: "CF5E0A4F-BF8D-235464-3654-0A1DDD1CC4A1",
    Type: "Dropdown",
    Name: "OnClick",
    Label: "On Click",
    GetSelectionOptions: values => {
      return [
        {
          Name: "Second Page",
          ID: "SecondPage",
        },
        {
          Name: "Promotion Page",
          ID: "PromotionPage",
        },
        // {
        //   Name: "Visibility",
        //   ID: "Visibility",
        // },
      ]
    },
  },

  //
  // SecondPage
  {
    ID: "CF5E0A4F-BF8D-235464-3654-5235234",
    Type: "Dropdown",
    Name: "SecondPage_Type",
    Visible: vals => vals.OnClick === "SecondPage",
    Label: "Type",
    GetSelectionOptions: values => {
      return [
        {
          Name: "Image with multiple promotions",
          ID: "ImageWithMultiplePromotions",
        },
      ]
    },
  },

  // SecondPage, ImageWithMultiplePromotions
  {
    ID: "EDE95D97-B65D-4C8A-BD44-5243",
    Name: "SecondPage_Title",
    Label: "Title",
    Type: "Text",
    Visible: vals =>
      vals.OnClick === "SecondPage" &&
      vals.SecondPage_Type === "ImageWithMultiplePromotions",
    Prompt: "Title is required",
  },
  {
    ID: "CF5E0A4F-BF8D-235464-9DFF-4325",
    Type: "Single Image",
    Name: "SecondPage_Image",
    Label: "Image",
    Visible: vals =>
      vals.OnClick === "SecondPage" &&
      vals.SecondPage_Type === "ImageWithMultiplePromotions",

    Path: "Campaigns/",
  },
  {
    ID: "339C10F4-389B-461F-94F9-85B7737D3497",
    Type: "Rich Text Field",
    Name: "SecondPage_Description",
    Visible: vals =>
      vals.OnClick === "SecondPage" &&
      vals.SecondPage_Type === "ImageWithMultiplePromotions",

    Label: "Description",
  },

  //
  // Visibility
  {
    ID: "EDE95D97-B65D-4C8A-BD44-125423534",
    Name: "VisibilityID",
    Label: "Visibility ID",
    Type: "Text",
    Visible: vals => vals.OnClick === "Visibility",
    Prompt: "Visibility ID is required",
  },
]

export default SquareBannerFields
