import firebase from "firebase"
import { timestampToDateString } from "helpers/Utils"

export const firebaseConfig = {
  apiKey: "AIzaSyD-8yCzF5J9P7e1sENud85q46aUlaIIgsQ",
  authDomain: "cityx-app.firebaseapp.com",
  databaseURL: "https://cityx-cms.europe-west1.firebasedatabase.app/",
  projectId: "cityx-app",
  storageBucket: "cityx-cms",
  messagingSenderId: "960454362186",
  appId: "1:960454362186:web:36f236e912a56977a392b3",
  measurementId: "G-MJWC0KHJ76",
}

export const defaultCampaign = () => ({
  Title: "",
  StartTime: timestampToDateString(firebase.firestore.Timestamp.now()),
  EndTime: timestampToDateString(firebase.firestore.Timestamp.now()),
})

export const campaignTypeOptions = [
  {
    Name: "Horizontal Banner List",
    ID: "HorizontalBannerList",
  },
  {
    Name: "Square Banner",
    ID: "SquareBanner",
  },
]

export const promotionTypeOptions = [
  {
    Name: "Image With Action Buttons",
    ID: "ImageWithActionButtons",
  },
  // {
  //   Name: "Viator",
  //   ID: "Viator",
  // },
]
