import React, { useEffect, useState } from "react"
import { Label, FormGroup, CustomInput } from "reactstrap"

const CheckboxButtons = ({ item, disabled, value, updateValue }) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const _options = await item.GetSelectionOptions()

    setOptions(_options)
    setLoading(false)
  }

  if (loading) return <div />

  return (
    <>
      <FormGroup check style={{ padding: "0" }} className="mb-3 col-12">
        <Label className="d-block">{item.Label}</Label>
        {options.map(option => (
          <CustomInput
            type="checkbox"
            key={`${item.ID}_checkbox_option_label_${option.ID}`}
            id={`${item.ID}_checkbox_option_${option.ID}`}
            label={option.Label ? option.Label : option.Name}
            checked={value.includes(option.ID)}
            onChange={() => {
              if (!value.includes(option.ID)) {
                const newValues = [...value]
                newValues.push(option.ID)
                updateValue(newValues)
              } else {
                const newValues = [...value]
                newValues.splice(value.indexOf(option.ID), 1)
                updateValue(newValues)
              }
            }}
            className="col-4 mr-0"
            inline
            disabled={disabled}
          />
        ))}
      </FormGroup>
    </>
  )
}

export default CheckboxButtons
