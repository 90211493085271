import RelationFormModule from "components/Modules/Relations/RelationFormModule"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  getFirestoreDocument,
} from "helpers/Firestore"

const CityBudgetProposalsCommentsForm = ({ opened, parent, onCancel }) => {
  return (
    <RelationFormModule
      form={{
        title: "Comments",
        fields: [
          {
            Name: "Text",
            Label: "Text",
            Type: "Text",
            FieldType: "textarea",
            ID: "1",
            Required: true,
          },
          {
            ID: "2",
            Type: "DateTime",
            Name: "DateOfSubmission",
            Label: "Date of submission",
            Prompt: "Date of submission is required",
            Required: true,
            ReadOnly: true,
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          return await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/137/DataBase/${parent}/Answers/${opened}`,
            timestampKeys: ["DateOfSubmission"],
          })
        },
        onSubmit: async (values, { firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/137/DataBase/${parent}/Answers`,
            values: values,
            timestampKeys: ["DateOfSubmission"],
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/137/DataBase/${parent}/Answers/${opened}`,
          })
        },
      }}
      onCancel={onCancel}
      ID={opened}
    />
  )
}

export default CityBudgetProposalsCommentsForm
