import Login from "../pages/auth/login"
import Logout from "../pages/auth/logout"
// import Register from "../pages/user/Register"
// import ForgetPwd from "../pages/user/ForgetPassword"

export default [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
]
