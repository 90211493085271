import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import React from "react"
import { withRouter } from "react-router-dom"
import CityAskALocalForm from "./_tabs/CityAskALocalForm"
import CityAskALocalTable from "./_tabs/CityAskALocalTable"
import CityAskALocalAnswers from "./_tabs/CityAskALocalAnswers"

const CityAskALocal = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Question",
          Component: opened => <CityAskALocalForm opened={opened} />,
        },
        {
          label: "Answers",
          Component: opened => <CityAskALocalAnswers opened={opened} />,
          requiresID: true,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityAskALocalTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityAskALocal)
