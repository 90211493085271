import React from "react"
import { Input } from "reactstrap"
import Select from "react-select"

import { getSelectTheme } from "helpers/Utils"
import Switch from "rc-switch"

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const formatGroupLabel = data => (
  <div style={groupStyles} key={data.ID}>
    <span>{data.Label ? data.Label : data.Name}</span>
  </div>
)

const RoleRow = ({ keyVal, role, changeName, deleteRole, switchRule }) => {
  return (
    <tr key={`row_${keyVal}`}>
      <td
        style={{
          position: "sticky",
          left: 0,
          zIndex: 1,
          backgroundColor: "white",
        }}
      >
        <Input
          type="text"
          style={{
            minWidth: "250px",
          }}
          disabled={role.Administrator || role.Contributor}
          name={`name_${role.ID}`}
          value={role.Name}
          onChange={e => changeName(e.target.value)}
        />
      </td>
      {Object.keys(role.Rules)
        .filter(
          menuItemID => role.Rules[menuItemID].MenuItemCMSStatus === "Active"
        )
        .map(menuItemID => {
          const menuItem = role.Rules[menuItemID]
          return (
            <td key={`allowed_switch_${keyVal}_${menuItemID}`}>
              <Switch
                disabled={role.Administrator}
                className="custom-switch custom-switch-primary custom-switch-small mb-2"
                checked={menuItem.Allowed}
                onChange={e => switchRule(menuItemID)}
              />
            </td>
          )
        })}
      <td key={`delete_${keyVal}`}>
        <button
          className="text-only-button"
          type="button"
          onClick={() => deleteRole()}
        >
          Delete
        </button>
      </td>
    </tr>
  )
}

export default RoleRow
