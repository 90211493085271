import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import UploadPage from "components/ModuleElements/UploadPage/UploadPage"
import React from "react"
import { withRouter } from "react-router-dom"
import CityMayorsDiaryForm from "./_tabs/CityMayorsDiaryForm"
import CityMayorsDiaryTable from "./_tabs/CityMayorsDiaryTable"

const MayorsDiary = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <CityMayorsDiaryForm opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityMayorsDiaryTable />,
        },
        { label: "Upload", Component: () => <UploadPage /> },
      ]}
    />
  )
}

export default withRouter(MayorsDiary)
