import { getSelectTheme } from "helpers/Utils"
import React, { useEffect, useState } from "react"
import AsyncCreatable from "react-select/async-creatable"
import { components } from "react-select"
import { Label } from "reactstrap"

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const formatGroupLabel = data => (
  <div style={groupStyles} key={data.ID}>
    <span>{data.Label ? data.Label : data.Name}</span>
  </div>
)

const Option = props => {
  const { Name } = props.data
  const { editingMode, onDelete } = props

  return (
    <components.Option {...props}>
      <span>{Name}</span>
      {editingMode ? (
        <button
          type="button"
          className="float-right text-only-button"
          onClick={() => onDelete()}
        >
          <i className="simple-icon-trash" />
        </button>
      ) : (
        <div />
      )}
    </components.Option>
  )
}

const DropdownCategory = ({
  item,
  parents,
  index,
  disabled,
  value,
  updateValue,
}) => {
  const [loading, setLoading] = useState(true)
  const [modifiedOptions, setModifiedOptions] = useState([])
  const [editingMode, setEditingMode] = useState(false)

  useEffect(() => {
    load()
  }, [parents])

  const load = async () => {
    const options = await item.GetCategorySelectionOptions(parents)

    setModifiedOptions([...options])
    setLoading(false)
  }

  const loadOptions = async input => {
    return modifiedOptions.filter(x =>
      x.Name.toLowerCase().includes(input.toLowerCase())
    )
  }

  if (loading) return <div />

  return (
    <div className="mb-3">
      <Label>
        {item.Label + " " + index + " "}
        {typeof item.DeleteCategorySelectionOption === "function" ? (
          <>
            (
            <button
              className="text-only-button"
              type="button"
              onClick={() => setEditingMode(!editingMode)}
            >
              <Label>{editingMode ? "Završi" : "Uredi"}</Label>
            </button>
            )
          </>
        ) : (
          <></>
        )}
      </Label>

      <AsyncCreatable
        defaultOptions
        className="mb-3"
        classNamePrefix="select2-selection"
        onCreateOption={async input => {
          if (typeof item.CreateCategorySelectionOption === "function") {
            setLoading(true)
            await item.CreateCategorySelectionOption(input, parents)
            updateValue(input)
            setModifiedOptions([...modifiedOptions, { ID: input, Name: input }])
            setLoading(false)
          }
        }}
        onChange={e => {
          if (editingMode) return
          if (e.ID === value) return

          updateValue(e.ID)
        }}
        value={modifiedOptions.find(option => option.ID === value)}
        isOptionSelected={option => option.ID === value}
        getOptionLabel={option => option.Name}
        getOptionValue={option => option.ID}
        formatGroupLabel={formatGroupLabel}
        isDisabled={disabled}
        theme={getSelectTheme}
        loadOptions={loadOptions}
        components={{
          Option: props => (
            <Option
              editingMode={editingMode}
              onDelete={async () => {
                if (typeof item.DeleteCategorySelectionOption === "function") {
                  setLoading(true)
                  await item.DeleteCategorySelectionOption(
                    props.data.ID,
                    parents
                  )

                  setModifiedOptions([
                    ...modifiedOptions.filter(x => x.ID !== props.data.ID),
                  ])
                  setLoading(false)
                }
              }}
              {...props}
            />
          ),
        }}
        isCreatable={!editingMode}
      />
    </div>
  )
}

export default DropdownCategory
