import React, { useState } from "react"

import { Col, Container, Row } from "reactstrap"

const Dashboard = () => {
  const [loading, setLoading] = useState(false)

  return (
    <React.Fragment>
      {loading ? (
        <div className="loading" />
      ) : (
        <div className="page-content">
          <Container fluid className="pt-4">
            <Row>
              <Col xl={12}>
                <h1>HOME</h1>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default Dashboard
