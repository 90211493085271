import { getBadgeColorForDateTime } from "helpers/Utils"
import React from "react"
import moment from "moment"
import { noImageImage } from "constants/defaultValues/defaultValues"

const DynamicTableStyledCell = ({ _key, content, styling }) => {
  if (styling?.Type === "Color point") {
    return (
      <td key={_key} style={{ width: 30, paddingLeft: 10, paddingRight: 10 }}>
        <button className={"text-only-button"} type="button" onClick={() => {}}>
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: content || "grey",
              borderRadius: 10,
            }}
          />
        </button>
      </td>
    )
  }

  if (styling?.Type === "Status") {
    return (
      <td
        key={_key}
        style={{
          width: 200,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <button
          className={"text-only-button align-items-center"}
          type="button"
          onClick={() => {}}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: content?.color || "grey",
              borderRadius: 10,
              marginTop: 4,
              marginRight: 5,
            }}
            className="float-left"
          />
          {content?.text || ""}
        </button>
      </td>
    )
  }

  if (styling?.Type === "Single Image")
    return (
      <td key={_key}>
        <div>
          <img
            src={content || noImageImage}
            alt=""
            className="avatar-xs rounded-circle me-2"
          />
        </div>
      </td>
    )

  if (content && styling?.Type === "Date") {
    const date = new Date(content)

    const locale = moment(date)
    locale.locale("hr")

    return (
      <td key={_key}>
        <span className={`badge ${getBadgeColorForDateTime(date)}`}>
          {locale.utc().format("D. M. YYYY")}
        </span>
      </td>
    )
  }

  if (content && styling?.Type === "Timestamp") {
    const date = new Date(content.seconds * 1000)

    const locale = moment(date)
    locale.locale("hr")

    return (
      <td key={_key}>
        <span className={`badge ${getBadgeColorForDateTime(date)}`}>
          {locale.utc().format("D. M. YYYY HH:mm:ss")}
        </span>
      </td>
    )
  }

  return (
    <td key={_key} style={{ color: "black" }}>
      {content}
    </td>
  )
}

export default DynamicTableStyledCell
