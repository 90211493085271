import { AvForm } from "availity-reactstrap-validation"
import Dropdown from "components/ModuleElements/Form/Fields/FieldTypes/Dropdown"
import Text from "components/ModuleElements/Form/Fields/FieldTypes/Text"
import TableModule from "components/Modules/Collections/TableModule"
import React, { useState } from "react"
import { Button } from "reactstrap"

const NewsCatcherTable = () => {
  const [refresh, setRefresh] = useState(0)
  const [query, setQuery] = useState("green transition")
  const [language, setLanguage] = useState("en")
  const [page, setPage] = useState(1)

  const [topic, setTopic] = useState()

  const currentURL = `https://newscatcher.p.rapidapi.com/v1/search_free?q=${encodeURIComponent(
    query
  )}&lang=${language}&media=True&page=${page}`

  return (
    <>
      <span>{currentURL}</span>

      <AvForm
        className="av-tooltip tooltip-label-right pt-3 pb-3"
        onSubmit={(event, errors, values) => {
          setRefresh(refresh + 1)
        }}
      >
        <Text
          value={query}
          updateValue={val => {
            setQuery(val)
          }}
          item={{
            Label: "Query",
            Name: "Query",
          }}
        />

        <Dropdown
          item={{
            GetSelectionOptions: () => {
              return [
                "af",
                "ar",
                "bg",
                "bn",
                "ca",
                "cn",
                "cs",
                "cy",
                "da",
                "de",
                "el",
                "en",
                "es",
                "et",
                "fa",
                "fi",
                "fr",
                "gu",
                "he",
                "hi",
                "hr",
                "hu",
                "id",
                "it",
                "ja",
                "kn",
                "ko",
                "lt",
                "lv",
                "mk",
                "ml",
                "mr",
                "ne",
                "nl",
                "no",
                "pa",
                "pl",
                "pt",
                "ro",
                "ru",
                "sk",
                "sl",
                "so",
                "sq",
                "sv",
                "sw",
                "ta",
                "te",
                "th",
                "tl",
                "tr",
                "tw",
                "uk",
                "ur",
                "vi",
              ].map(c => ({ ID: c, Name: c.toUpperCase() }))
            },
            Label: "Language",
          }}
          value={language}
          updateValue={val => setLanguage(val)}
        />

        {/* enterprise functionality */}
        {/* <Dropdown
          item={{
            GetSelectionOptions: () => {
              return [
                { ID: "news", Name: "news" },
                { ID: "sport", Name: "sport" },
                { ID: "tech", Name: "tech" },
                { ID: "world", Name: "world" },
                { ID: "finance", Name: "finance" },
                { ID: "politics", Name: "politics" },
                { ID: "business", Name: "business" },
                { ID: "economics", Name: "economics" },
                { ID: "entertainment", Name: "entertainment" },
                { ID: "beauty", Name: "beauty" },
                { ID: "travel", Name: "travel" },
                { ID: "music", Name: "music" },
                { ID: "food", Name: "food" },
                { ID: "science", Name: "science" },
              ]
            },
            Label: "Topic",
          }}
          value={topic}
          updateValue={val => setTopic(val)}
        /> */}

        <Text
          value={page}
          updateValue={val => {
            setPage(val)
          }}
          item={{
            Label: "Page",
            Name: "Page",
          }}
        />

        <Button color="primary" className="float-right">
          Submit
        </Button>
      </AvForm>

      <TableModule
        getData={async ({ firebaseUID }) => {
          let res = []

          const options = {
            method: "GET",
            headers: {
              "X-RapidAPI-Key":
                "77a3b1c725msh5a9708530824968p1d28cfjsndae7621809af",
              "X-RapidAPI-Host": "newscatcher.p.rapidapi.com",
            },
          }

          await fetch(currentURL, options)
            .then(response => response.json())
            .then(response => {
              res = response.articles
            })
            .catch(err => console.error(err))

          return res
        }}
        noCreateNew
        columns={[{ key: "title", label: "Title" }]}
        searchKey={"title"}
        refresh={refresh}
        noInitialSort
        openInNewTab
      />
    </>
  )
}

export default NewsCatcherTable
