import FormModule from "components/Modules/Documents/FormModule"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
  deleteFirestoreDocument,
} from "helpers/Firestore"
import React from "react"

const CityEmergencyCommunicationForm = ({ opened }) => {
  let fields = [
    {
      ID: "1",
      Name: "title",
      Label: "Title",
      Type: "Text",
      Required: true,
      Prompt: "Title is required",
    },
    {
      ID: "2",
      Name: "description",
      Label: "Description",
      Type: "Text",
      Required: true,
      Prompt: "Description is required",
    },
    {
      ID: "3",
      Name: "expiresDate",
      Label: "Expires Date",
      Type: "DateTime",
      Required: true,
      Prompt: "Expires Date is required",
    },
    {
      ID: "4",
      Name: "type",
      Label: "Type",
      Type: "Dropdown",
      Required: true,
      GetSelectionOptions: async (values, { firebaseUID }) => {
        return [
          {
            ID: "emergency",
            Name: "Emergency",
          },
        ]
      },
      Prompt: "Type is required",
    },
    {
      ID: "5",
      Name: "sendNotification",
      Label: "Send Notification",
      Type: "Switch",
    },
  ]

  if (!opened) {
    fields = fields.filter(x => x.Name !== "sendNotification")
  }

  return (
    <FormModule
      form={{
        title: "Emergency Communication",
        fields: fields,
        fieldsToTranslate: ["title", "description"],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened)
            return {
              type: "emergency",
              sendNotification: true,
            }

          const data = await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/132/DataBase/${opened}`,
            timestampKeys: ["expiresDate", "publishDate"],
          })

          return { ...data, sendNotification: false }
        },
        onSubmit: async (values, { firebaseUID }) => {
          const _values = { ...values }

          if (!_values.publishDate) _values.publishDate = new Date()

          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/132/DataBase`,
            timestampKeys: ["expiresDate", "publishDate"],
            values: _values,
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/132/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityEmergencyCommunicationForm
