import TableModule from "components/Modules/Collections/TableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import moment from "moment"
import React from "react"

const CityMayorsDiaryTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        const data = await getDataFromFirestoreCollection({
          sortBy: { key: "expiresDate", inverse: true },
          path: `City/Cities/DataBase/${firebaseUID}/Modules/132/DataBase`,
          timestampKeys: ["expiresDate", "publishDate"],
        })

        return data.map(item => ({
          ...item,
          expiresDateString: item.expiresDate
            ? moment(item.expiresDate).format("MM/DD/YYYY hh:mm A")
            : "",
        }))
      }}
      columns={[
        { key: "title", label: "Title" },
        { key: "expiresDateString", label: "Expires" },
      ]}
      searchKey={"title"}
    />
  )
}

export default CityMayorsDiaryTable
