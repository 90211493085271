import CryptoJS from "crypto-js"

import { APIPath } from "../constants/defaultValues/defaultValues"

import NotificationAlert from "../components/Common/Notifications/NotificationAlert"

// prepares URLs for API Calls by finding the places in the URL where a
// value should be and replacing them with the appropriate value
// Example:
// URL: 'http://{{ID}}/items/{{SecondID}}', values: {ID: '123', secondID: '256'}
// -> 'http://123/items/256'
export const makeURL = (inputURL, values) => {
  let url = inputURL.replace("{{APIURL}}", APIPath)
  let match = url.match(/\{{(.*?)\}}/)
  while (match) {
    url = url.replace(match[0], values[match[1]])
    match = url.match(/\{{(.*?)\}}/)
  }
  return url
}

// creates a hash for header authorization
export const createHash = (req, eeID, eeSecret) => {
  let requestBody = req

  if (requestBody === "") {
    requestBody = "{}"
  } else {
    requestBody = JSON.stringify(JSON.parse(requestBody))
  }

  const preHashValue = `${eeSecret}${eeID}${eeSecret}${requestBody}${eeSecret}${eeID}${eeSecret}`

  return CryptoJS.SHA512(preHashValue).toString()
}

export const getApplicationsList = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : []

  return data
}

export const getMenuItemsByID = async menuItemID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/menuitems/${menuItemID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createOrUpdateMenuItem = async values => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}structure/cms/menuitems/createOrUpdateMenuItem`,
    "PUT",
    values
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const deleteMenuItemByID = async menuItemID => {
  const apiCall = await makeAPICall(`{{APIURL}}structure/menuitems`, "DELETE", {
    ID: menuItemID,
  })

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getMenuItemsByParentMenuItemID = async menuItemID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/menuitems/${menuItemID}/menuitems`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getApplicationMenuItemsByApplicationID = async applicationID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications/${applicationID}/menuitems`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getApplicationMenuByApplicationID = async applicationID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/cms/applications/${applicationID}/getMenu`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getApplicationByApplicationID = async applicationID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications/${applicationID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createOrUpdateApplication = async values => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications`,
    values.ID ? "PUT" : "POST",
    values
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const deleteApplicationByApplicationID = async applicationID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications`,
    "DELETE",
    { ID: applicationID }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllGlobalEntities = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}globalentity/globalentities`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllClientTypes = async () => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/clienttypes",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllClientTypeCategories = async () => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/clienttypecategories",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createOrUpdateClient = async values => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/clients",
    "PUT",
    values
  )

  return apiCall
}

export const getAllClients = async () => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/clients",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllClientsByType = async typeID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clienttypes/${typeID}/clients`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllClientsByCategory = async categoryID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clienttypecategories/${categoryID}/clients`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientByClientID = async ID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clients/${ID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientApplicationByClientID = async ID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clients/${ID}/applications`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setApplicationForClient = async (appID, clientID) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}authentication/clients/addApplicationToClient`,
    "POST",
    { Client_ID: clientID, Application_ID: appID }
  )

  return apiCall
}

export const removeApplicationFromClient = async (appID, clientID) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}authentication/clientsapplications`,
    "DELETE",
    { Client_ID: clientID, Application_ID: appID }
  )

  return apiCall
}

export const deleteClientByClientID = async ID => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}authentication/clients`,
    "DELETE",
    { ID }
  )

  return apiCall
}

export const getAllUsers = async () => {
  const apiCall = await makeAPICall(`{{APIURL}}authentication/users`, "GET", {})

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getUserByUserID = async userID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/users/${userID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createOrUpdateUser = async values => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/users`,
    values.ID ? "PUT" : "POST",
    values
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const deleteUserByID = async userID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/users`,
    "DELETE",
    { ID: userID }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientAdministrators = async clientID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clients/${clientID}}/users`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setRolesToUser = async (userID, roles) => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/usersroles/setUserRoles",
    "PUT",
    {
      User_ID: userID,
      Relations: roles,
    }
  )

  return apiCall
}

export const updateUserCall = async values => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/users",
    "PUT",
    values
  )

  return apiCall
}

export const setClientApplicationBuild = async (
  Client_ID,
  Application_ID,
  BuildNumber
) => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/clients/setClientApplicationBuild",
    "PUT",
    { Client_ID, Application_ID, BuildNumber }
  )

  return apiCall
}

export const getClientApplicationRolesByClientAndApplicationID = async (
  clientID,
  appID
) => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clients/${clientID}/applications/${appID}/getRoles`,
    "GET",
    {}
  )

  return apiCall
}

export const getClientsByApplicationID = async appID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/applications/${appID}/clients`,
    "GET",
    {}
  )

  return apiCall
}

export const setClientApplicationRoles = async (clientID, appID, roles) => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/clientsapplicationsroles/setClientApplicationRoles",
    "PUT",
    { Client_ID: clientID, Application_ID: appID, Relations: roles }
  )

  return apiCall
}

export const getAllCountries = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}globalentity/countries`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllCounties = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}globalentity/counties`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllRegions = async () => {
  const apiCall = await makeAPICall(`{{APIURL}}globalentity/regions`, "GET", {})

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientMenuItemStatuses = async (clientID, appID) => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/cms/clients/${clientID}/applications/${appID}/getClientMenuItemStatuses`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setClientMenuItemStatuses = async (clientID, appID, menuItems) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}structure/cms/clients/setClientMenuItemStatuses`,
    "PUT",
    {
      Client_ID: clientID,
      Application_ID: appID,
      Data: { ...menuItems },
    }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientAdministrator = async ID => {
  const apiCall = await makeAPICallWithErrorHandling(
    `{{APIURL}}authentication/users/${ID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientAdministratorRoles = async (clientID, ID) => {
  const apiCall = await makeAPICallWithErrorHandling(
    `{{APIURL}}authentication/clients/${clientID}/users/${ID}/roles`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createClientAdministrator = async (
  values,
  clientID,
  appID,
  role
) => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/users/create",
    "POST",
    values
  )

  console.log("API CALL", apiCall)

  if (!apiCall.ErrorMessage || apiCall.StatusCode === 500) {
    const { ID } = apiCall.Data.MetaData

    const roles = []

    if (role !== "None") {
      roles.push({
        Role_ID: role,
      })
    }

    const rolesAPICall = await makeAPICallWithConfirmation(
      "{{APIURL}}authentication/clientsusersroles/setClientUserRoles",
      "PUT",
      {
        Client_ID: clientID,
        Application_ID: appID,
        User_ID: ID,
        Relations: roles,
      }
    )

    console.log("ROLES API CALL", rolesAPICall)

    if (!rolesAPICall.ErrorMessage) {
      return { MetaData: apiCall.Data.MetaData, Roles: rolesAPICall }
    }
  } else {
    NotificationAlert({
      type: "error",
      title: "Error",
      message: apiCall.ErrorMessage.Message || apiCall.ErrorMessage,
    })
  }
}

export const updateClientAdministrator = async (
  values,
  clientID,
  appID,
  role
) => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/users",
    "PUT",
    values
  )

  if (!apiCall || !apiCall.ErrorMessage) {
    const { ID } = values

    const roles = []

    if (role !== "None") {
      roles.push({
        Role_ID: role,
      })
    }

    const rolesAPICall = await makeAPICallWithConfirmation(
      "{{APIURL}}authentication/clientsusersroles/setClientUserRoles",
      "PUT",
      {
        Client_ID: clientID,
        Application_ID: appID,
        User_ID: ID,
        Relations: roles,
      }
    )

    if (!rolesAPICall.ErrorMessage) {
      return { MetaData: apiCall.Data, Roles: rolesAPICall }
    }
  } else {
    NotificationAlert({
      type: "error",
      title: "Error",
      message: apiCall.ErrorMessage.Message || apiCall.ErrorMessage,
    })
  }
}

export const getUserByEMail = async email => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/users?EMail=${email}`,
    "GET",
    {}
  )

  const data = apiCall.Success
    ? apiCall.Data.length > 0
      ? apiCall.Data[0]
      : undefined
    : undefined

  return data
}

export const makeApplicationBuild = async values => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}structure/cms/applications/build",
    "POST",
    values
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getNewOfferNumber = async () => {
  const apiCall = await makeAPICallWithErrorHandling(
    "{{APIURL}}management/offers/getNewOfferNumber",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createOffer = async (
  Client_ID,
  ClientOIB,
  ClientName,
  Number,
  Amount,
  DocumentURL,
  DataJSON
) => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}management/offers",
    "POST",
    {
      Client_ID,
      ClientOIB,
      ClientName,
      Number: Number.split("/")[1],
      Amount,
      DocumentURL,
      DataJSON: JSON.stringify(DataJSON),
    }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientOffers = async clientID => {
  const apiCall = await makeAPICallWithErrorHandling(
    `{{APIURL}}management/offers?Client_ID=${clientID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}
