import { useState } from "react"
import { withTranslation } from "react-i18next"
import { Button, Col, Row } from "reactstrap"

import TabNavigation from "../../../Common/UI/TabNavigation"

import AdministratorsEditor from "./Administrators"

// editing clients
const ClientEditor = ({ history, location, ID, tabs, form, t }) => {
  const [activeTab, setActiveTab] = useState("settings")

  const _tabs = tabs ? tabs : []

  const navItems = [
    { name: "settings", label: "client-editor.settings" },
    ..._tabs,
    {
      name: "administrator",
      label: "client-editor.administrator",
    },
  ]

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              <>
                {ID && (
                  <Row>
                    <Col>
                      <h1>{t(name ? name : " ")}</h1>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <Button
                        color="secondary"
                        onClick={() =>
                          history.push(location.pathname.split("/edit")[0])
                        }
                      >
                        {t("form.cancel")}
                      </Button>
                    </Col>
                  </Row>
                )}

                {ID && (
                  <TabNavigation
                    activeTab={activeTab}
                    setActive={val => setActiveTab(val)}
                    navList={navItems}
                  />
                )}

                {/* settings for client */}
                {activeTab === "settings" && <>{form()}</>}

                {/* admin settings */}
                {activeTab === "administrator" && (
                  <AdministratorsEditor
                    clientID={ID}
                    appID={chosenApplication}
                  />
                )}

                {_tabs.map(tab => (
                  <div key={tab.name}>
                    {activeTab === tab.name ? tab.Component() : <div />}
                  </div>
                ))}
              </>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(ClientEditor)
