import React, { useEffect, useState } from "react"
import CollectionTabModule from "./Collections/CollectionTabModule"
import DocumentTabModule from "./Documents/DocumentTabModule"

const TabModulesTemplate = ({ collectionTabs, documentTabs }) => {
  const [opened, setOpened] = useState()

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    const opened = params.get("opened")
    setOpened(opened ? decodeURIComponent(opened) : opened)
  }, [location.pathname, location.search])

  if (opened)
    return (
      <DocumentTabModule
        tabs={documentTabs}
        opened={opened === "new" ? undefined : opened}
      />
    )

  return <CollectionTabModule tabs={collectionTabs} />
}

export default TabModulesTemplate
