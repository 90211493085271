// VIATOR functions

import axios from "axios"

export const makeViatorCall = async (url, method, body) => {
  const options = {
    method,
    url: `https://api.viator.com/partner/${url}`,
    headers: {
      "Accept-Language": "en",
      Accept: "application/json;version=2.0",
      "exp-api-key": "c99bc57a-65ad-4b11-8139-dfe354df3161",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization",
    },
  }

  let res

  await axios
    .request(options)
    .then(response => {
      res = response.data
    })
    .catch(_error => {
      console.log("ERROR", _error)
    })

  return res
}

export const getViatorProduct = async ID => {
  return await makeViatorCall(`products/${ID}`, "GET")
}

export const getViatorDestinations = async () => {
  return await makeViatorCall("v1/taxonomy/destinations", "GET")
}

export const searchViatorProducts = async (search, cityID) => {
  const products = await makeViatorCall("search/freetext", "PATCH", {
    searchTerm: search,
    productFiltering: {
      destination: cityID,
    },
    searchTypes: [
      {
        searchType: "PRODUCTS",
        pagination: {
          start: 1,
          count: 50,
        },
      },
    ],
    currency: "EUR",
  })
  return products.results
}
