import { firestore } from "helpers/Firebase"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const Tools = ({ t }) => {
  const [loading, setLoading] = useState(true)
  const [tools, setTools] = useState([])

  useEffect(() => {
    setTimeout(async () => {
      const tool = await getTools()
      setTools(tool)
      setLoading(false)
    }, 100)
  }, [])

  const getTools = async () => {
    let size
    await firestore
      .collection("City/Requests/UserCreateNew")
      .where("Status", "==", "Pending")
      .get()
      .then(snapshot => {
        size = snapshot.size
      })
    return [
      {
        label: "tools.user-create-new",
        url: `./tools/user-create-new`,
        notifications: size,
      },
      {
        label: "tools.news-api",
        url: `./tools/news-api`,
      },
      {
        label: "tools.news-catcher",
        url: `./tools/news-catcher`,
      },
      {
        label: "tools.website-news",
        url: `./tools/website-news`,
      },
      {
        label: "tools.website-blog",
        url: `./tools/website-blog`,
      },
    ]
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              {loading ? (
                <div className="loading" />
              ) : (
                <>
                  <div className="d-flex flex-column min-width-zero margin-bottom-10">
                    <h1>{t("Tools")}</h1>
                  </div>

                  {tools.map(tool => (
                    <div
                      key={tool.url}
                      className="d-flex flex-column min-width-zero margin-bottom-10"
                    >
                      <Row className="row">
                        <Col lg={6}>
                          <a href={tool.url} target="_blank">
                            {t(tool.label)}
                          </a>
                        </Col>
                        <Col lg={6}>
                          <a href={tool.url} target="_blank">
                            {tool.notifications}
                          </a>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  {tools.length === 0 && (
                    <div className="d-flex flex-column min-width-zero margin-bottom-10">
                      <h3>There are no tools for this application</h3>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(Tools)
