import RelationFormModule from "components/Modules/Relations/RelationFormModule"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  getFirestoreDocument,
  createFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import React, { useEffect, useState } from "react"

const CityAskALocalAnswersForm = ({ opened, parent, onCancel }) => {
  const [parentCategory, setParentCategory] = useState()

  return (
    <RelationFormModule
      form={{
        title: "Answers",
        fields: [
          {
            Name: "Text",
            Label: "Text",
            Type: "Text",
            FieldType: "textarea",
            ID: "uwirvj90h248iovwrehuvbsvnd9p24ihout",
            Required: true,
          },
          {
            ID: "DA248088-A785-472B-9516-2A7E67F1FDB7",
            Type: "DateTime",
            Name: "DateOfSubmission",
            Label: "Date of submission",
            Prompt: "Date of submission is required",
            Required: true,
          },
          {
            ID: "DA248088-A785-472B-9516-9208547",
            Type: "Text",
            Name: "User_ID",
            Label: "User_ID",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          return await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/111/DataBase/${parent}/Answers/${opened}`,
            timestampKeys: ["DateOfSubmission"],
          })
        },
        onSubmit: async (values,{ firebaseUID}) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/111/DataBase/${parent}/Answers`,
            values: values,
            timestampKeys: ["DateOfSubmission"],
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/111/DataBase/${parent}/Answers/${opened}`,
          })
        },
      }}
      onCancel={onCancel}
      ID={opened}
    />
  )
}

export default CityAskALocalAnswersForm
