import RelationTableModule from "components/Modules/Relations/RelationTableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import CityBudgetProposalsCommentsForm from "./_elements/CityBudgetProposalsCommentsForm"

const CityBudgetProposalsComments = ({ opened }) => {
  return (
    <RelationTableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          sortBy: { key: "DateOfSubmission", inverse: true },
          path: `City/Cities/DataBase/${firebaseUID}/Modules/137/DataBase/${opened}/Answers`,
        })
      }}
      OpenedComponent={(ID, onCancel) => (
        <CityBudgetProposalsCommentsForm
          opened={ID}
          parent={opened}
          onCancel={onCancel}
        />
      )}
      CreateComponent={onCancel => (
        <CityBudgetProposalsCommentsForm parent={opened} onCancel={onCancel} />
      )}
      columns={[
        { key: "Text", label: "Text" },
        {
          key: "DateOfSubmission",
          label: "Date of Submission",
          styling: { Type: "Timestamp" },
        },
      ]}
      searchKey={"Text"}
    />
  )
}

export default CityBudgetProposalsComments
