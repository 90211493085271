import React, { useEffect, useState } from "react"
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation"
import { Label, Button } from "reactstrap"

import {
  createFirestoreDocument,
  createOrUpdateFirestoreDocument,
  getDataFromFirestoreCollection,
  getFirestoreDocument,
} from "../../../../../helpers/Firestore"
import Select from "react-select"
import { cityStatusOptions } from "constants/defaultValues/defaultValues"
import { getSelectTheme } from "helpers/Utils"
import { getClientByClientID } from "helpers/API"
import MultipleChoiceDropdown from "components/ModuleElements/Form/Fields/FieldTypes/MultipleChoiceDropdown"
import { supportedLanguages } from "applications/city/helpers/defaultValues"
import NotificationAlert from "components/Common/Notifications/NotificationAlert"

const CityInfo = ({ clientID }) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState()
  const [languages, setLanguages] = useState([])

  useEffect(() => {
    setLoading(true)
    setTimeout(async () => {
      const clientData = await getClientByClientID(clientID)

      const _data = await getFirestoreDocument({
        path: `City/Cities/DataBase/${clientData.FirebaseUID}`,
      })

      setStatus(_data.Status)
      setLanguages(
        _data?.SupportedLanguages
          ? _data?.SupportedLanguages.map(x => ({ ID: x }))
          : []
      )
      setData(_data)
      setLoading(false)
    }, 100)
  }, [])

  const onSubmit = async (event, errors, values) => {
    if (errors.length === 0) {
      await createOrUpdateFirestoreDocument({
        values: {
          ...data,
          ...values,
          SupportedLanguages: languages.map(x => x.ID),
          Status: status,
        },
        path: `City/Cities/DataBase`,
      })

      NotificationAlert({ type: "success", title: "Success" })
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <AvForm
          className="av-tooltip tooltip-label-right p-4"
          onSubmit={(event, errors, values) => onSubmit(event, errors, values)}
          model={data}
        >
          <h4>Status</h4>
          <Select
            theme={getSelectTheme}
            className="mb-3"
            classNamePrefix="select2-selection"
            onChange={e => setStatus(e.ID)}
            options={cityStatusOptions}
            defaultValue={cityStatusOptions.filter(x => x.ID === status)}
            isOptionSelected={option => option.ID === status}
            getOptionLabel={option => option.Name}
          />

          <AvGroup className="error-t-negative error-l-150">
            <Label>Grb grada</Label>
            <AvField name="Crest" type="text" />
          </AvGroup>

          <AvGroup className="error-t-negative error-l-150">
            <Label>Header fotografija</Label>
            <AvField name="HeaderImage" type="text" />
          </AvGroup>

          <AvGroup className="error-t-negative error-l-150">
            <Label>Boja grada</Label>
            <AvField name="CityColor" type="text" />
          </AvGroup>

          <AvGroup className="error-t-negative error-l-150">
            <Label>{"Geolokacija (lat, lng)"}</Label>
            <AvField name="Geolocation" type="text" />
          </AvGroup>

          <MultipleChoiceDropdown
            item={{
              Label: "Supported languages",
              GetSelectionOptions: () => {
                return supportedLanguages
              },
            }}
            value={languages}
            updateValue={val => {
              setLanguages(val)
            }}
          />

          <Button color="primary float-right">Submit</Button>
        </AvForm>
      )}
    </>
  )
}

export default CityInfo
