import RelationFormModule from "components/Modules/Relations/RelationFormModule"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  getFirestoreDocument,
  createFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import React, { useEffect, useState } from "react"

const CityProblemsReportStatusesForm = ({ opened, parent, onCancel }) => {
  const [parentCategory, setParentCategory] = useState()

  return (
    <RelationFormModule
      form={{
        title: "Statuses",
        fields: [
          {
            Name: "Name",
            Label: "Name",
            Type: "Text",
            Required: true,
            ID: "iruei4h2inowvs",
          },
          {
            ID: "AC3EF22B-D27D-4B3B-B327-B196AE1C6317",
            Type: "Dropdown",
            Name: "Categories",
            Label: "Categories",

            GetSelectionOptions: async (values,{ firebaseUID}) => {
              if (!parentCategory) return []

              return await getDataFromFirestoreCollection({
                path: `City/Cities/DataBase/${firebaseUID}/Modules/35/Categories/${parentCategory}/Statuses`,
              })
            },
            CreateSelectionOption: parentCategory
              ? async (input, values, firebaseUID) => {
                  return await createFirestoreDocument({
                    path: `City/Cities/DataBase/${firebaseUID}/Modules/35/Categories/${parentCategory}/Statuses`,
                    values: { Name: input, ID: input },
                  })
                }
              : undefined,
            DeleteSelectionOption: parentCategory
              ? async (optionID, values, firebaseUID) => {
                  return await deleteFirestoreDocument({
                    path: `City/Cities/DataBase/${firebaseUID}/Modules/35/Categories/${parentCategory}/Statuses/${optionID}`,
                  })
                }
              : undefined,
          },
          {
            Name: "Description",
            Label: "Description",
            Type: "Text",
            FieldType: "textarea",
            ID: "i42hguvinwreds",
          },
          {
            ID: "drjnbvlesfdvers",
            Type: "Gallery",
            Name: "Pictures",
            Label: "Pictures",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          const parentValues = await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase/${parent}`,
          })

          setParentCategory(parentValues.Category)

          return await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase/${parent}/Statuses/${opened}`,
          })
        },
        onSubmit: async (values,{ firebaseUID}) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase/${parent}/Statuses`,
            values: values,
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase/${parent}/Statuses/${opened}`,
          })
        },
      }}
      onCancel={onCancel}
      ID={opened}
    />
  )
}

export default CityProblemsReportStatusesForm
