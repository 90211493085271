import FormModule from "components/Modules/Documents/FormModule"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  getFirestoreDocument,
} from "helpers/Firestore"

const CityBudgetProposalsForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Budget Proposal",
        fields: [
          {
            ID: "1",
            Name: "Title",
            Label: "Title",
            Type: "Text",
            Required: true,
            Prompt: "Name is required",
          },
          {
            ID: "2",
            Name: "Text",
            Label: "Description",
            Type: "Text",
            FieldType: "textarea",
            Required: true,
            Prompt: "Text is required",
          },
          {
            ID: "2.5",
            Name: "Funds",
            Label: "Estimated funds",
            Type: "Text",
            Required: true,
            Prompt: "Funds are required",
            FieldType: "textarea",
          },
          {
            ID: "2.7",
            Name: "Removal",
            Label: "What should be removed",
            Type: "Text",
            FieldType: "textarea",
          },
          {
            ID: "3",
            Type: "Dropdown",
            Name: "Status",
            Label: "Status",
            GetSelectionOptions: async () => {
              return [
                { ID: "Active", Name: "Active" },
                { ID: "Inactive", Name: "Inactive" },
                { ID: "Pending", Name: "Pending" },
              ]
            },
          },
          {
            ID: "4",
            Type: "DateTime",
            Name: "DateOfSubmission",
            Label: "Date of submission",
            Prompt: "Date of submission is required",
            Required: true,
            ReadOnly: true,
          },
          {
            ID: "5",
            Type: "Text",
            Name: "UpvotesNumber",
            Label: "Upvotes",
            ReadOnly: true,
          },
          {
            ID: "6",
            Type: "Text",
            Name: "DownvotesNumber",
            Label: "Downvotes",
            ReadOnly: true,
          },
          {
            ID: "7",
            Type: "Text",
            Name: "FullName",
            Label: "Full Name",
            ReadOnly: true,
          },
          {
            ID: "8",
            Type: "Text",
            Name: "ContactEmail",
            Label: "Email",
            ReadOnly: true,
          },
        ],
        fieldsToTranslate: [],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          const data = await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/137/DataBase/${opened}`,
            timestampKeys: ["DateOfSubmission"],
          })

          return {
            ...data,
            UpvotesNumber: data.UpVotes ? data.UpVotes.length : 0,
            DownvotesNumber: data.DownVotes ? data.DownVotes.length : 0,
          }
        },
        onSubmit: async (values, { firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/137/DataBase`,
            values: values,
            timestampKeys: ["DateOfSubmission"],
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/137/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityBudgetProposalsForm
