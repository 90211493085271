import React, { useEffect, useState } from "react"
import { AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { Label } from "reactstrap"

const RadioButton = ({ item, disabled, updateValue }) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])

  useEffect(() => {
    setTimeout(async () => {
      const _options = await item.GetSelectionOptions()

      setOptions(_options)
      setLoading(false)
    }, 100)
  }, [])

  if (loading) return <div />

  return (
    <>
      <Label>{item.Label}</Label>
      <AvRadioGroup
        className="error-l-150 radio-buttons"
        name={item.Name}
        inline
        validate={{ required: { value: item.Required } }}
        disabled={disabled}
      >
        {options.map(option => (
          <AvRadio
            key={`${item.ID}_option_${option.ID}`}
            customInput
            label={option.Name}
            value={option.ID}
            onClick={() => updateValue(option.ID)}
          />
        ))}
      </AvRadioGroup>
    </>
  )
}

export default RadioButton
