import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/storage"
import "firebase/firestore"
import "firebase/functions"

import { firebaseConfig } from "../constants/defaultValues/firebase"

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const database = firebase.database()
const storage = firebase.storage()
const firestore = firebase.firestore()
const functions = firebase.functions()
const europeFunctions = firebase.app().functions("europe-west1")

const authObject = firebase.auth
const databaseObject = firebase.database
const storageObject = firebase.storage
const firestoreObject = firebase.firestore
const functionsObject = firebase.functions

export {
  auth,
  database,
  storage,
  firestore,
  functions,
  europeFunctions,
  authObject,
  databaseObject,
  storageObject,
  firestoreObject,
  functionsObject,
}
