import TableModule from "components/Modules/Collections/TableModule"
import {
  createOrUpdateFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import destinationsJSON from "../../../../helpers/destinations.json"

import React, { useEffect } from "react"
import { firestoreObject } from "helpers/Firebase"

const CitySportTable = ({ type }) => {
  const test = async () => {
    const promotions = await getDataFromFirestoreCollection({
      path: "City/Business/Promotions",
    })

    promotions.forEach(async promotion => {
      await createOrUpdateFirestoreDocument({
        path: "City/Business/Promotions",
        values: {
          ID: promotion.ID,
          Status: "Active",
        },
      })
    })
  }

  useEffect(() => {
    // test()
  }, [])

  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          path: `City/Modules/DataBase/113/Clubs`,
          where: [
            {
              key: "Cities",
              operation: "array-contains",
              value: firebaseUID,
            },
            {
              key: "Type",
              operation: "==",
              value: type,
            },
          ],
        })
      }}
      columns={[{ key: "Name", label: "Name" }]}
      searchKey={"Name"}
      refresh={type}
    />
  )
}

export default CitySportTable
