import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import UploadPage from "components/ModuleElements/UploadPage/UploadPage"
import React from "react"
import { withRouter } from "react-router-dom"
import CityVisibilityForm from "./_tabs/CityVisibilityForm"
import CityVisibilityTable from "./_tabs/CityVisibilityTable"
import CityVisibilityCitySelection from "./_tabs/CityVisibilityCitySelection"

const CityNews = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <CityVisibilityForm opened={opened} />,
        },
        {
          label: "Cities",
          Component: opened => <CityVisibilityCitySelection opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityVisibilityTable />,
        },
        { label: "Upload", Component: () => <UploadPage /> },
      ]}
    />
  )
}

export default withRouter(CityNews)
