import RelationTableModule from "components/Modules/Relations/RelationTableModule"
import {
  createOrUpdateFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import React from "react"
import PromotionAddExistingWrapper from "./PromotionAddTables/PromotionAddExistingWrapper"
import PromotionForm from "./PromotionAddTables/PromotionForm/PromotionForm"

const CityCampaignPromotionRelationTable = ({ opened }) => {
  return (
    <RelationTableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          path: "City/Business/Promotions",
          where: [
            {
              key: "Campaign_ID",
              operation: "==",
              value: opened,
            },
          ],
          sortBy: { key: "Order" },
        })
      }}
      // AddExistingComponent={onCancel => (
      //   <PromotionAddExistingWrapper parent={opened} onCancel={onCancel} />
      // )}
      CreateComponent={onCancel => (
        <PromotionForm parent={opened} onCancel={onCancel} />
      )}
      OpenedComponent={(ID, onCancel) => (
        <PromotionForm opened={ID} parent={opened} onCancel={onCancel} />
      )}
      deleteRelation={async (firebaseUID, x) => {
        await createOrUpdateFirestoreDocument({
          path: `City/Business/Promotions`,
          values: { ID: x.ID, Status: "Removed" },
        })
      }}
      columns={[
        { key: "Title", label: "Title" },
        { key: "Status", label: "Status" },
      ]}
      searchKey={"Title"}
    />
  )
}

export default CityCampaignPromotionRelationTable
