import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import UploadPage from "components/ModuleElements/UploadPage/UploadPage"
import React from "react"
import { withRouter } from "react-router-dom"
import CityProblemsReportForm from "./_tabs/CityProblemsReportForm"
import CityProblemsReportTable from "./_tabs/CityProblemsReportTable"
import CityProblemsReportStatuses from "./_tabs/CityProblemsReportStatuses"

const CityProblemsReport = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Report",
          Component: opened => <CityProblemsReportForm opened={opened} />,
        },
        {
          label: "Statuses",
          Component: opened => <CityProblemsReportStatuses opened={opened} />,
          requiresID: true,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityProblemsReportTable />,
        },
        { label: "Upload", Component: () => <UploadPage /> },
      ]}
    />
  )
}

export default withRouter(CityProblemsReport)
