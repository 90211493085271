export const supportedLanguages = [
  {
    Name: "English",
    Code: "en",
    ID: "en",
  },
  {
    Name: "Croatian",
    Code: "hr",
    ID: "hr",
  },
  {
    Name: "French",
    Code: "fr",
    ID: "fr",
  },
  {
    Name: "German",
    Code: "de",
    ID: "de",
  },
  {
    Name: "Spanish",
    Code: "es",
    ID: "es",
  },
  {
    Name: "Italian",
    Code: "it",
    ID: "it",
  },
  {
    Name: "Swedish",
    Code: "sv",
    ID: "sv",
  },
  {
    Name: "Dutch",
    Code: "nl",
    ID: "nl",
  },
  {
    Name: "Finnish",
    Code: "fi",
    ID: "fi",
  },
  {
    Name: "Danish",
    Code: "da",
    ID: "da",
  },
]
