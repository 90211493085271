import TableModule from "components/Modules/Collections/TableModule"
import {
  createFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import React from "react"

const CityPromotionsTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          path: `City/Business/Promotions`,
          where: [
            {
              key: "City_ID",
              operation: "==",
              value: firebaseUID,
            },
          ],
        })
      }}
      columns={[
        { key: "Title", label: "Title" },
        { key: "Status", label: "Status" },
      ]}
      searchKey={"Title"}
    />
  )
}

export default CityPromotionsTable
