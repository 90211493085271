import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { withTranslation } from "react-i18next"

import CitySelection from "components/ModuleElements/CitySelection/CitySelection"

import { connect } from "react-redux"

const CitySelectionModule = ({
  getDefaultValues,
  onSubmit,
  ID,
  clientID,
  firebaseUID,
}) => {
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setTimeout(async () => {
      const defaultValues = await getDefaultValues(
        {firebaseUID, clientID}
      )

      setSelected(defaultValues)
      setLoading(false)
    }, 100)
  }, [getDefaultValues, ID])

  const _onSubmit = async () => {
    await onSubmit(selected, firebaseUID, clientID)
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <div className="p-2">
          <CitySelection
            disabled={!ID}
            setSelected={val => setSelected(val)}
            selected={selected}
          />

          <Button color="primary" onClick={() => _onSubmit()}>
            Submit
          </Button>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { clientID, firebaseUID } = CMS

  return { clientID, firebaseUID }
}
export default connect(
  mapStateToProps,
  {}
)(withTranslation()(CitySelectionModule))
