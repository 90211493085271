import { useEffect } from "react"
import { connect } from "react-redux"

import { auth, europeFunctions, functions } from "./helpers/Firebase"
import { getUserInfo, logoutUser } from "./store/auth/actions"

import ApplicationSpecificConfig from "applications/ApplicationSpecificConfig"
import { applicationSubdomain } from "constants/defaultValues/defaultValues"
import { setStyle } from "helpers/Utils"
import App from "./App"

const AppLoader = ({ getUserInfoAction, loading }) => {
  const location = window.location

  useEffect(() => {
    const useEmulators = false
    if (location.hostname === "localhost" && useEmulators) {
      functions.useEmulator("localhost", 5002)
      europeFunctions.useEmulator("localhost", 5002)
    }

    const unsubscribe = auth.onAuthStateChanged(user => {
      getUserInfoAction(user && user.uid)
    })

    setStyle(ApplicationSpecificConfig[applicationSubdomain].style)

    return () => {
      unsubscribe()
    }
  }, [])

  return loading ? <div className="loading" /> : <App />
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, {
  getUserInfoAction: getUserInfo,
})(AppLoader)
