import React, { useEffect, useState } from "react"
import TableModule from "../Collections/TableModule"

// template module for adding existing entries to a relation
// includes a search at the top and shows the results in a table below with actions to
// deleteRelation or addRelation based on if the entry ID is already in the list of relations passed in relations
const RelationAddExistingModule = ({
  getData,
  getRelations,
  noCreateNew,
  noInitialSort,
  searchKey,
  openInNewTab,
  columns,
  refresh,
  refreshRelations,
  openButtonIndex,
  actionButtons,
  addRelation,
  deleteRelation,
  shouldQueryDataInParent,
  isActive,
}) => {
  const [relations, setRelations] = useState([])

  useEffect(() => {
    update()
  }, [refreshRelations])

  const update = async () => {
    const _relations = await getRelations()
    setRelations(_relations)
  }

  const checkIsActive = x => {
    if (typeof isActive === "function") {
      return isActive(relations, x)
    }

    return relations.includes(x.ID)
  }

  return (
    <>
      <TableModule
        getData={getData}
        noCreateNew={noCreateNew}
        noInitialSort={noInitialSort}
        searchKey={searchKey}
        openInNewTab={openInNewTab}
        columns={columns}
        refresh={refresh}
        openButtonIndex={openButtonIndex}
        actionButtons={[
          ...(actionButtons || []),
          {
            ID: "23547283752028543",
            title: "Relations",
            buttonTitle: i => (checkIsActive(i) ? "Remove" : "Add"),
            function: async i => {
              if (checkIsActive(i)) {
                await deleteRelation(i)
              } else {
                await addRelation(i)
              }
            },
          },
        ]}
        shouldQueryDataInParent={shouldQueryDataInParent}
      />
    </>
  )
}

export default RelationAddExistingModule
