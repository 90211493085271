import FormModule from "components/Modules/Documents/FormModule"
import { firestoreObject } from "helpers/Firebase"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
  deleteFirestoreDocument,
  getDataFromFirestoreCollection,
  createFirestoreDocument,
} from "helpers/Firestore"
import React from "react"

const CityVisibilityForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Visibility",
        fields: [
          {
            ID: "EDE95D97-B65D-4C8A-BD44-7AC1C8A5B9DE",
            Name: "Name",
            Label: "Name",
            Type: "Text",
            Required: true,
            Prompt: "Name is required",
          },
          {
            ID: "FF8F216E-107A-4C1E-A663-74867B5A549E",
            Type: "Text",
            Name: "OIB",
            Label: "OIB",
          },
          {
            ID: "18E5F152-AC4A-4F7D-829B-832FEE2FF1B2",
            Type: "Text",
            Name: "Address",
            Label: "Address",
          },
          {
            ID: "FF8F216E-107A-fresf-A663-74867B5A549E",
            Type: "Text",
            Name: "Geolocation",
            Label: "Geolocation",
          },
          {
            ID: "FF8F216E-107A-gd-A663-74867B5A549E",
            Type: "Text",
            Name: "PhoneNumber",
            Label: "Phone number",
          },
          {
            ID: "FF8F216E-107A-cads-A663-74867B5A549E",
            Type: "Text",
            Name: "Website",
            Label: "Website",
          },
          {
            ID: "339C10F4-389B-adcs-94F9-85B7737D3497",
            Type: "Rich Text Field",
            Name: "FullText",
            Label: "Text",
          },
          {
            ID: "339C10F4-389B-cas-94F9-85B7737D3497",
            Type: "Working Hours",
            Name: "WorkingHours",
            Label: "Working hours",
          },
          {
            ID: "AC3EF22B-D27D-4B3B-B327-B196AE1C6317",
            Type: "Dropdown Categories",
            Name: "Categories",
            Label: "Categories",
            GetCategorySelectionOptions: async (
              parents,
              values,
              firebaseUID
            ) => {
              return await getDataFromFirestoreCollection({
                path: `City/Modules/DataBase/107/Categories`,
                where: [
                  {
                    key: "Parent_ID",
                    operation: "==",
                    value: [null, ...parents][parents.length],
                  },
                ],
              })
            },
            CreateCategorySelectionOption: async (
              input,
              parents,
              values,
              firebaseUID
            ) => {
              return await createFirestoreDocument({
                path: `City/Modules/DataBase/107/Categories`,
                values: {
                  Name: input,
                  Parent_ID: [null, ...parents][parents.length],
                  Parents: parents,
                },
              })
            },
            DeleteCategorySelectionOption: async (
              optionID,
              parents,
              values,
              firebaseUID
            ) => {
              return await deleteFirestoreDocument({
                path: `City/Modules/DataBase/107/Categories/${optionID}`,
              })
            },
          },
          {
            ID: "CF5E0A4F-BF8D-4553-9DFF-0A1DDD1CC4A1",
            Type: "Single Image",
            Name: "HeaderImage",
            Label: "Header image",
            Path: "Visibility/",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          const data = await getFirestoreDocument({
            path: `City/Modules/DataBase/107/DataBase/${opened}`,
          })

          return {
            ...data,
          }
        },
        onSubmit: async (values,{ firebaseUID}) => {
          const _values = { ...values }

          if (!_values.Client_ID || _values.Client_ID.length === 0) {
            _values.Client_ID = []
            _values.Client_ID.push(firebaseUID)
          }

          const Filter_Categories = {}

          if (_values.Categories)
            _values.Categories.forEach(cat => {
              Filter_Categories[cat] = true
            })

          const Filter_Client_ID = {}

          if (_values.Client_ID)
            _values.Client_ID.forEach(cli => {
              Filter_Client_ID[cli] = true
            })

          return await createOrUpdateFirestoreDocument({
            path: `City/Modules/DataBase/107/DataBase`,
            values: {
              ..._values,
              Filter_Categories,
              Filter_Client_ID,
            },
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Modules/DataBase/107/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityVisibilityForm
