import Dropdown from "components/ModuleElements/Form/Fields/FieldTypes/Dropdown"
import TableModule from "components/Modules/Collections/TableModule"
import RelationAddExistingModule from "components/Modules/Relations/RelationAddExistingModule"
import RelationAddExistingWithFormModule from "components/Modules/Relations/RelationAddExistingWithFormModule"
import {
  createFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  getViatorDestinations,
  getViatorProduct,
  searchViatorProducts,
} from "./functions"

const ViatorPromotions = ({ onCancel, parent, firebaseUID }) => {
  const [refresh, setRefresh] = useState(0)

  const [city, setCity] = useState(firebaseUID)

  const [lastVisible, setLastVisible] = useState(undefined)

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const d = await getViatorProduct("239592P2")
    console.log(d)
  }

  return (
    <div>
      <div className="pl-3 pr-3 pt-3 row">
        <Dropdown
          className={"col-6"}
          noMargin
          item={{
            ID: "EDE95D97-B65D-4C8A-BD44-52063897643",
            Name: "City",
            Label: "City",
            Type: "Dropdown",
            GetSelectionOptions: async () => {
              const viatorDestinations = await getViatorDestinations()

              const cities = await getDataFromFirestoreCollection({
                path: `City/Cities/DataBase`,
                sortBy: { key: "Name" },
              })

              return [...cities]
            },
          }}
          value={city}
          updateValue={cityID => {
            setCity(cityID)
            setRefresh(refresh + 1)
          }}
        />
      </div>

      <RelationAddExistingWithFormModule
        getData={async params => {
          return await searchViatorProducts(params.searchKeyword, 479)
        }}
        getRelations={async () => {
          // const relations = await getDataFromFirestoreCollection({
          //   path: "City/Business/Promotions",
          //   where: [
          //     {
          //       key: "Campaign_ID",
          //       operation: "==",
          //       value: parent,
          //     },
          //   ],
          // })

          // return relations.map(i => i.Partner_ID)

          return []
        }}
        noInitialSort
        columns={[{ key: "title", label: "title" }]}
        noCreateNew
        refresh={refresh}
        shouldQueryDataInParent={true}
        FormComponent={(values, onFormCancel) => (
          <PromotionForm
            parent={parent}
            defaultValues={{
              City_ID: firebaseUID,
              Title: values.title,
              Type: "Viator",
              Status: "Active",
              Viator_ID: values.productCode,
            }}
            onCancel={onFormCancel}
          />
        )}
      />
    </div>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { firebaseUID, clientID } = CMS

  return { firebaseUID, clientID }
}
export default connect(mapStateToProps, {})(ViatorPromotions)
