import { functions, europeFunctions } from "./Firebase"

export const processImage = async data => {
  const fbFunction = europeFunctions.httpsCallable("general-processImage")
  return await fbFunction(data)
}

export const defaultProcessImage = async ({ width, path }) => {
  return await processImage({
    params: {
      resize: { width: width || 1200 },
      jpeg: { quality: 60, progressive: true },
      sharpen: true,
      contentType: "image/jpeg",
    },
    bucket: "gs://cityx-cms",
    path: path,
  })
}
