import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Row } from "reactstrap"

import TableControler from "components/ModuleElements/Table/TableControler"
import { queryDataByParameters } from "helpers/Utils"
import { adminRoot } from "../../../constants/defaultValues/defaultValues"

const StartPage = ({ title, getClients, clientURL, history, t }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const _data = await getClients()
      setData(_data)
      setLoading(false)
    }
    fetchData()
  }, [])

  const openClient = clientID => {
    history.push(`${adminRoot}manager/${clientURL}/edit?ID=${clientID}`)
  }

  const queryData = async params => {
    const _params = { ...params }
    if (params.searchKeyword) {
      _params.searchBy = { key: "Name", value: params.searchKeyword }
    }
    return queryDataByParameters(data, _params)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              <div className="d-flex flex-column min-width-zero margin-bottom-10">
                <h1>{t(title)}</h1>
              </div>

              {loading ? (
                <div className="loading" />
              ) : (
                <TableControler
                  getData={async params => {
                    return queryData(params)
                  }}
                  columns={[{ key: "Name", label: "Name" }]}
                  openButton={{
                    show: true,
                    onClick: ID => openClient(ID),
                    index: 0,
                  }}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = () => {
  return {}
}
const mapActionToProps = {}

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(StartPage)
)
