import React from "react"
import DropdownCategory from "./_elements/DropdownCategory"

const DropdownCategories = ({ item, disabled, value, updateValue }) => {
  return (
    <div className="mb-3">
      {[null, ...value].map((parentID, index) => {
        const parents = value.slice(0, index)
        return (
          <div key={`key_${parents}`}>
            <DropdownCategory
              parents={parents}
              item={item}
              disabled={disabled}
              index={index}
              value={value[index]}
              updateValue={val => {
                const newValue = []
                for (let i = 0; i < index; i++) {
                  newValue.push(value[i])
                }
                newValue.push(val)
                updateValue(newValue)
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default DropdownCategories
