import TableModule from "components/Modules/Collections/TableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"

const CityNewsTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          sortBy: { key: "DateOfPublication", inverse: true },
          where: [
            {
              key: "Cities",
              operation: "array-contains-any",
              value: ["ALL", firebaseUID],
            },
          ],
          path: `City/Modules/DataBase/83/DataBase`,
        })
      }}
      columns={[{ key: "Title", label: "Title" }]}
      searchKey={"Title"}
    />
  )
}

export default CityNewsTable
