import Module from "../pages/app/user/module/module"
import Administrators from "../pages/app/user/admin-settings/administrators"
import Roles from "../pages/app/user/admin-settings/roles"

import { adminRoot } from "../constants/defaultValues/defaultValues"

export default [
  {
    path: `${adminRoot}module/:ID`,
    component: Module,
  },
  {
    path: `${adminRoot}administrators`,
    component: Administrators,
  },
  {
    path: `${adminRoot}roles`,
    component: Roles,
  },
]
