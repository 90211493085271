import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Row, Button, Table, Col } from "reactstrap"

import {
  getClientApplicationRolesByClientAndApplicationID,
  setClientApplicationRoles,
} from "../../../../helpers/API"
import RoleRow from "../../../../components/User/AdminSettings/RoleRow"

// TODO
const Roles = ({ cms, appID, clientID, cmsLoading, history }) => {
  const [loading, setLoading] = useState(true)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    setTimeout(async () => {
      if (cmsLoading || !cms) return

      const rolesRequest =
        await getClientApplicationRolesByClientAndApplicationID(clientID, appID)

      const sortedRoles = rolesRequest.Data.sort((a, b) => {
        const aValue = a.Administrator ? 3 : a.Contributor ? 2 : 1
        const bValue = b.Administrator ? 3 : b.Contributor ? 2 : 1
        return bValue - aValue
      })

      setRoles(sortedRoles)
      setLoading(false)
    }, 100)
  }, [cmsLoading, cms, clientID, appID])

  const changeName = (roleIndex, newValue) => {
    const newRoles = [...roles]

    newRoles[roleIndex].Name = newValue

    setRoles(newRoles)
  }

  const deleteRole = roleIndex => {
    const newRoles = [...roles]

    newRoles.splice(roleIndex, 1)

    setRoles(newRoles)
  }

  const addRole = () => {
    const newRoles = [...roles]

    const newRole = new Map()
    newRole.Rules = new Map()

    newRole.Name = ""

    Object.keys(roles[0].Rules).forEach(menuItemID => {
      const menuItemRules = roles[0].Rules[menuItemID]

      newRole.Rules[menuItemID] = {
        Allowed: false,
        MenuItemCMSStatus: menuItemRules.MenuItemCMSStatus,
        MenuItemClientSpecificName: menuItemRules.MenuItemClientSpecificName,
        MenuItemDisplayName: menuItemRules.MenuItemDisplayName,
        MenuItem_ID: menuItemID,
      }
    })

    newRoles.push({ ...newRole })

    setRoles(newRoles)
  }

  const publishRoles = async () => {
    setLoading(true)

    const newRoles = await setClientApplicationRoles(clientID, appID, roles)

    if (newRoles.ErrorMessage) {
      history.push("/500")
      return
    }

    const sortedRoles = newRoles.sort((a, b) => {
      const aValue = a.Administrator ? 3 : a.Contributor ? 2 : 1
      const bValue = b.Administrator ? 3 : b.Contributor ? 2 : 1
      return bValue - aValue
    })

    setRoles(sortedRoles)
    setLoading(false)
  }

  const switchRule = (roleIndex, menuItemID) => {
    const newRoles = [...roles]

    newRoles[roleIndex].Rules[menuItemID].Allowed =
      !newRoles[roleIndex].Rules[menuItemID].Allowed

    setRoles(newRoles)
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <div className="page-content">
            <div className="container-fluid p-1">
              <Row>
                <Col>
                  <Row className="mb-3 p-2">
                    <Col>
                      <Button color="primary" onClick={() => addRole()}>
                        Add New Role
                      </Button>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <Button color="secondary" onClick={() => publishRoles()}>
                        Publish
                      </Button>
                    </Col>
                  </Row>
                  <Table
                    responsive
                    className="responsive-bordered-table table-sm"
                    style={{
                      backgroundColor: "white",
                      marginBottom: "250px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          rowSpan="2"
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                          }}
                        >
                          Name
                        </th>
                        {Object.keys(roles[0].Rules)
                          .filter(
                            menuItemID =>
                              roles[0].Rules[menuItemID].MenuItemCMSStatus ===
                              "Active"
                          )
                          .map(menuItemID => {
                            const menuItem = roles[0].Rules[menuItemID]
                            return (
                              <th key={`title_${menuItemID}`}>
                                {menuItem.MenuItemClientSpecificName}
                              </th>
                            )
                          })}
                        <th rowSpan="2">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roles.map((role, roleIndex) => (
                        <RoleRow
                          key={`role_table_${role.ID}`}
                          keyVal={`role_table_${role.ID}`}
                          role={role}
                          changeName={newValue =>
                            changeName(roleIndex, newValue)
                          }
                          deleteRole={() => deleteRole(roleIndex)}
                          switchRule={menuItemID =>
                            switchRule(roleIndex, menuItemID)
                          }
                        />
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { cms, appID, clientID, loading } = CMS
  return { cms, appID, clientID, cmsLoading: loading }
}
export default connect(mapStateToProps, {})(Roles)
