import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import UploadPage from "components/ModuleElements/UploadPage/UploadPage"
import React from "react"
import { withRouter } from "react-router-dom"
import CityNewsForm from "./_tabs/CityNewsForm"
import CityNewsTable from "./_tabs/CityNewsTable"

const CityNews = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <CityNewsForm opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityNewsTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityNews)
