import FormModule from "components/Modules/Documents/FormModule"
import { firestoreObject } from "helpers/Firebase"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  createFirestoreDocument,
  getDataFromFirestoreCollection,
  getFirestoreDocument,
} from "helpers/Firestore"
import React from "react"

const CityAskALocalForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Question",
        fields: [
          {
            ID: "339C10F4-389B-461F-94F9-02937865247",
            Type: "Text",
            Name: "Title",
            Label: "Title",
            Required: true,
          },
          {
            ID: "339C10F4-389B-461F-94F9-02937865247",
            Type: "Text",
            Name: "Text",
            Label: "Text",
            FieldType: "textarea",
            Required: true,
          },
          {
            ID: "DA248088-A785-472B-9516-2A7E67F1FDB7",
            Type: "DateTime",
            Name: "DateOfSubmission",
            Label: "Date of submission",
            Prompt: "Date of submission is required",
            Required: true,
          },
          {
            ID: "DA248088-A785-472B-9516-9208547",
            Type: "Text",
            Name: "User_ID",
            Label: "User_ID",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          return await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/111/DataBase/${opened}`,
            timestampKeys: ["DateOfSubmission"],
          })
        },
        onSubmit: async (values,{ firebaseUID}) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/111/DataBase`,
            values,
            timestampKeys: ["DateOfSubmission"],
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/111/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityAskALocalForm
