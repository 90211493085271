import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import { useEffect, useState } from "react"
import Select from "react-select"
import { Button, Label } from "reactstrap"

import { firestoreObject } from "helpers/Firebase"
import {
  createFirestoreDocument,
  createOrUpdateFirestoreBusiness,
  createOrUpdateFirestoreDocument,
} from "helpers/Firestore"
import NotificationAlert from "../../../../../components/Common/Notifications/NotificationAlert"
import ButtonWithWarning from "../../../../../components/Common/UI/ButtonWithWarning"
import { adminRoot } from "../../../../../constants/defaultValues/defaultValues"
import {
  createOrUpdateClient,
  deleteClientByClientID,
  getAllCountries,
  getClientByClientID,
  setApplicationForClient,
} from "../../../../../helpers/API"
import { getSelectTheme, validateOIBAndMB } from "../../../../../helpers/Utils"

const CityForm = ({ ID, deleteEnabled, history }) => {
  const [loading, setLoading] = useState(true)
  const [working, setWorking] = useState(false)

  const [defaultValues, setDefaultValues] = useState()

  const [countries, setCountries] = useState([])
  const [country, setCountry] = useState()
  // const [county, setCounty] = useState()

  const types = [
    { label: "City", ID: 4 },
    { label: "Municipality", ID: 1 },
  ]

  useEffect(() => {
    setTimeout(async () => {
      const countriesAPI = await getAllCountries()

      if (ID) {
        const defaultValuesAPI = await getClientByClientID(ID)
        setDefaultValues(defaultValuesAPI)

        setCountry(defaultValuesAPI.Country_ID || countriesAPI[0].ID)
        // setCounty(defaultValuesAPI.County_ID)
      } else {
        setDefaultValues({
          ClientType_ID: 4,
        })
      }

      setCountries(countriesAPI)

      setLoading(false)
    }, 100)
  }, [ID])

  const onSubmit = async (event, errors, values) => {
    setWorking(true)
    if (errors.length === 0 && country) {
      const client = await createOrUpdateClient({
        ...values,
        Country_ID: country,
        ID: ID,
      })

      if (client.ErrorMessage) return

      const firestoreValues = {
        Country_ID: country,
        Client_ID: client.ID,
        ID: client.FirebaseUID,
        Name: values.Name,
        PersonalIdentificationNumber: client.PersonalIdentificationNumber,
      }

      let application

      if (!ID) {
        application = await setApplicationForClient("5", client.ID)

        const _firestoreValues = {
          ...firestoreValues,
          Status: "Inactive",
          InsertDate: firestoreObject.Timestamp.now(),
        }

        const firestoreEntry = await createFirestoreDocument({
          path: "City/Cities/DataBase",
          values: _firestoreValues,
        })

        _firestoreValues.ID = firestoreEntry.ID

        await createOrUpdateFirestoreBusiness({
          values: _firestoreValues,
          isNew: true,
        })

        await createOrUpdateClient({
          ...values,
          Country_ID: country,
          ID: client.ID,
          FirebaseUID: firestoreEntry.ID,
        })
      } else {
        await createOrUpdateFirestoreDocument({
          path: "City/Cities/DataBase",
          values: { ...firestoreValues },
        })

        await createOrUpdateFirestoreBusiness({
          values: firestoreValues,
          isNew: false,
        })
      }

      if (!client.ErrorMessage && !application?.ErrorMessage) {
        history.push(`${adminRoot}manager/cities/edit?ID=${client.ID}`)
      }
    } else {
      let errorMessage = "Missing: "
      errors.forEach(error => {
        errorMessage += error
        errorMessage += ", "
      })
      if (!country) errorMessage += "Country, "

      NotificationAlert({
        type: "warning",
        title: "",
        message: errorMessage,
        expiration: 3000,
      })
    }

    setWorking(false)
  }

  const deleteClient = async () => {
    const api = await deleteClientByClientID(ID)

    await deleteFirestoreBusiness({ ID: defaultValues.FirebaseUID })

    if (api.ErrorMessage) {
      NotificationAlert({
        type: "error",
        title: "Error",
        message: api.ErrorMessage.Message,
        expiration: 3000,
      })
    } else {
      history.push(`${adminRoot}manager/cities`)
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <AvForm
            className="av-tooltip tooltip-label-right p-1 pt-3"
            model={defaultValues}
            onSubmit={(event, errors, values) =>
              onSubmit(event, errors, values)
            }
          >
            <AvRadioGroup
              className="error-l-150 radio-buttons"
              name="ClientType_ID"
              required
              inline
            >
              <Label className="d-block">Type</Label>
              {types.map(clientType => (
                <AvRadio
                  customInput
                  label={clientType.label}
                  value={clientType.ID}
                  key={clientType.ID}
                />
              ))}
            </AvRadioGroup>

            <AvGroup>
              <Label>City name</Label>
              <AvInput name="Name" required value={defaultValues.Name} />
              <AvFeedback>City name is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>OIB</Label>
              <AvInput
                name="PersonalIdentificationNumber"
                type="number"
                value={defaultValues.PersonalIdentificationNumber}
                validate={{ async: validateOIBAndMB }}
              />
              <AvFeedback>OIB is required!</AvFeedback>
            </AvGroup>

            {defaultValues.FirebaseUID && (
              <AvGroup>
                <Label>FirebaseUID</Label>
                <AvInput
                  name="FirebaseUID"
                  value={defaultValues.FirebaseUID}
                  disabled={true}
                  validate={{ required: false }}
                />
              </AvGroup>
            )}

            <Label>Country</Label>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => setCountry(e.ID)}
              options={countries}
              defaultValue={countries.filter(x => x.ID === country)}
              isOptionSelected={option => option.ID === country}
              getOptionLabel={option => option.Name}
            />

            {/* <Label>County</Label>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => setCounty(e.ID)}
              options={counties}
              defaultValue={counties.filter(x => x.ID === county)}
              isOptionSelected={option => option.ID === county}
              getOptionLabel={option => option.Name}
            /> */}

            {/* <h4>Contact person</h4>

            <AvGroup>
              <Label>Display name</Label>
              <AvInput
                name="ContactPerson"
                value={defaultValues.ContactPerson}
              />
              <AvFeedback>Display name is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>Phone number</Label>
              <AvInput
                name="ContactMobilePhoneNumber"
                type="phone"
                value={defaultValues.ContactMobilePhoneNumber}
              />
              <AvFeedback>Phone number is required!</AvFeedback>
            </AvGroup>

            <AvGroup className="mb-3">
              <Label>Email</Label>
              <AvInput
                name="ContactEMail"
                type="email"
                value={defaultValues.ContactEMail}
              />
              <AvFeedback>Email is required!</AvFeedback>
            </AvGroup> */}

            {deleteEnabled && (
              <ButtonWithWarning color="danger" onClick={() => deleteClient()}>
                Delete
              </ButtonWithWarning>
            )}

            <Button color="primary" className="float-right" disabled={working}>
              Submit
            </Button>
          </AvForm>
        </>
      )}
    </>
  )
}

export default CityForm
