import TableModule from "components/Modules/Collections/TableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"

const CityProblemsReportTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          sortBy: { key: "Name" },
          path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase`,
        })
      }}
      columns={[{ key: "Name", label: "Name" }]}
      searchKey={"Name"}
    />
  )
}

export default CityProblemsReportTable
