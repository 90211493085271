import ApplicationSpecificConfig from "applications/ApplicationSpecificConfig"
import { applicationSubdomain } from "constants/defaultValues/defaultValues"
import { connect } from "react-redux"
import { withRouter } from "react-router"

// checks user roles and returns a ModulePicker component if Read is allowed
const Module = ({ match, reducersLoading }) => {
  const Component = ApplicationSpecificConfig[applicationSubdomain].ModulePicker

  return (
    <div className="page-content">
      {reducersLoading ? (
        <div className="loading" />
      ) : (
        <Component ID={match.params.ID} />
      )}
    </div>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { loading: authLoading } = Auth

  return {
    reducersLoading: authLoading,
  }
}
export default connect(mapStateToProps, {})(withRouter(Module))
