import TableModule from "components/Modules/Collections/TableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"

const CityAskALocalTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          sortBy: { key: "DateOfSubmission" },
          path: `City/Cities/DataBase/${firebaseUID}/Modules/111/DataBase`,
        })
      }}
      columns={[{ key: "Title", label: "Title" }]}
      searchKey={"Title"}
    />
  )
}

export default CityAskALocalTable
