import FormModule from "components/Modules/Documents/FormModule"
import { promotionTypeOptions } from "constants/defaultValues/firebase"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
} from "helpers/Firestore"
import { useState } from "react"
import ImageWithActionButtons from "./Types/ImageWithActionButtons"
import Viator from "./Types/Viator"

const Fields = type => {
  if (type === "ImageWithActionButtons") {
    return ImageWithActionButtons
  }
  if (type === "Viator") {
    return Viator
  }

  return []
}

const CityPromotionsForm = ({ opened }) => {
  const [type, setType] = useState()

  return (
    <FormModule
      form={{
        title: "Promotions",
        fields: [
          {
            ID: "CF5E0A4F-BF8D-235464-3654-23452435234",
            Type: "Dropdown",
            Name: "Status",
            Label: "Status",
            Required: true,
            GetSelectionOptions: values => {
              return [
                { Name: "Active", ID: "Active" },
                { Name: "Inactive", ID: "Inactive" },
                { Name: "Deleted", ID: "Deleted" },
                { Name: "Removed", ID: "Removed" },
              ]
            },
          },
          {
            ID: "CF5E0A4F-BF8D-235464-3654-0A1DDD1CC4A1",
            Type: "Dropdown",
            Name: "Type",
            Label: "Type",
            GetSelectionOptions: values => {
              return promotionTypeOptions
            },
            updateValue: (values, val) => {
              setType(val)
              return { ...values, Type: val }
            },
          },
          {
            ID: "87E30CD9-82D0-442F-8698-2435234523",
            Type: "Text",
            FieldType: "number",
            Name: "Order",
            Label: "Order",
            Required: true,
            Prompt: "Order is required",
          },
          ...Fields(type),
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) {
            return {}
          }

          const data = await getFirestoreDocument({
            path: `City/Business/Promotions/${opened}`,
          })

          setType(data.Type)

          return data
        },
        onSubmit: async (values, { firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Business/Promotions`,
            values: { ...values },
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Business/Promotions`,
            values: { ID: opened, Status: "Deleted" },
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityPromotionsForm
