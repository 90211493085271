import FormModule from "components/Modules/Documents/FormModule"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
  deleteFirestoreDocument,
  getDataFromFirestoreCollection,
  createFirestoreDocument,
} from "helpers/Firestore"
import React from "react"

const WebsiteBlogForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Website Blog",
        fields: [
          {
            ID: "EDE95D97-B65D-4C8A-BD44-7AC1C8A5B9DE",
            Name: "title",
            Label: "Title",
            Type: "Text",
            Required: true,
            Prompt: "Title is required",
          },
          {
            ID: "DA248088-A785-472B-9516-9208547",
            Type: "DateTime",
            Name: "publishDate",
            Required: true,
            Label: "Publish Date",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-405643490689",
            Name: "shortText",
            Label: "Short Text",
            Type: "Text",
            FieldType: "textarea",
          },
          {
            ID: "339C10F4-389B-adcs-94F9-85B7737D3497",
            Type: "Rich Text Field",
            Name: "paragraph",
            Label: "Full Text",
          },
          {
            ID: "AC3EF22B-D27D-4B3B-B327-B196AE1C6317",
            Type: "Multiple Choice Dropdown",
            Name: "categories",
            Label: "Categories",
            GetSelectionOptions: async (values, { firebaseUID }) => {
              return [] // defined categories of blog
            },
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-4536475896",
            Name: "tags",
            Label: `Tags (seperated by a coma ", ")`,
            Type: "Text",
          },
          {
            ID: "CF5E0A4F-BF8D-4553-9DFF-0A1DDD1CC4A1",
            Type: "Single Image",
            Name: "image",
            Label: "Image",
            Path: "Web/Corporate/Blog/Blog",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-4523345234",
            Name: "imageAlt",
            Label: "Image Alt",
            Type: "Text",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-4523678",
            Name: "authorName",
            Label: "Author Name",
            Type: "Text",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-546785254",
            Name: "authorImage",
            Label: "Author Name",
            Type: "Single Image",
            Path: "Web/Corporate/Blog/Author",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened)
            return {
              authorName: "CityX",
              authorImage:
                "https://firebasestorage.googleapis.com/v0/b/cityx-app/o/CityX%20Logos%2FCityX_logo.png?alt=media&token=ab379850-ea2b-496e-9f77-3ea5aaf6b7c0",
            }

          const data = await getFirestoreDocument({
            path: `Web/Corporate/DataBase/Blog/DataBase/${opened}`,
            timestampKeys: ["publishDate"],
          })

          return {
            ...data,
            authorName: data.author.name,
            authorImage: data.author.image,
            tags: data.tags.join(", "),
          }
        },
        onSubmit: async (values, { firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: `Web/Corporate/DataBase/Blog/DataBase`,
            timestampKeys: ["publishDate"],
            values: {
              ...values,
              authorName: undefined,
              authorImage: undefined,
              tags: values.tags.split(", "),
              author: {
                name: values.authorName,
                image: values.authorImage,
              },
            },
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `Web/Corporate/DataBase/Blog/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default WebsiteBlogForm
