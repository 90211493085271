import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import React from "react"
import NewsAPIForm from "./_tabs/NewsAPIForm"
import NewsAPITable from "./_tabs/NewsAPITable"

const NewsAPI = () => {
  return (
    <div className="page-content">
      <TabModulesTemplate
        documentTabs={[
          {
            label: "Form",
            Component: opened => <NewsAPIForm opened={opened} />,
          },
        ]}
        collectionTabs={[
          {
            label: "Table",
            Component: () => <NewsAPITable />,
          },
        ]}
      />
    </div>
  )
}

export default NewsAPI
