import { adminRoot } from "constants/defaultValues/defaultValues"

export default [
  {
    id: "my-city",
    label: "My city",
    to: `${adminRoot}`,
    icon: "iconsminds-chrysler-building",
    subs: [
      {
        id: "my-city.emergency-communication",
        label: "Emergency communication",
        icon: "iconsminds-danger",
        to: `${adminRoot}module/132`,
      },
      {
        id: "my-city.news",
        label: "News",
        to: `${adminRoot}module/83`,
        icon: "iconsminds-newspaper",
      },
      {
        id: "my-city.surveys",
        label: "Surveys",
        to: `${adminRoot}module/56`,
        icon: "simple-icon-question",
      },
      {
        id: "my-city.address-book",
        label: "Address book",
        to: `${adminRoot}module/133`,
        icon: "iconsminds-book",
      },
      {
        id: "my-city.budget-proposals",
        label: "Budget proposals",
        to: `${adminRoot}module/137`,
        icon: "iconsminds-bar-chart-4",
      },
    ],
  },
  {
    id: "campaigns",
    label: "Campaigns",
    to: `${adminRoot}`,
    icon: "iconsminds-megaphone",
    subs: [
      {
        id: "campaigns.campaigns",
        label: "Campaigns",
        to: `${adminRoot}module/123`,
        icon: "iconsminds-megaphone",
      },
      // {
      //   id: "campaigns.promotions",
      //   label: "Promotions",
      //   to: `${adminRoot}module/124`,
      //   icon: "iconsminds-gift-box",
      // },
    ],
  },
]
