import RelationFormModule from "components/Modules/Relations/RelationFormModule"
import { promotionTypeOptions } from "constants/defaultValues/firebase"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
} from "helpers/Firestore"
import { useState } from "react"
import ImageWithActionButtons from "../../../../promotions/_tabs/Types/ImageWithActionButtons"
import Viator from "../../../../promotions/_tabs/Types/Viator"

const Fields = type => {
  if (type === "ImageWithActionButtons") {
    return ImageWithActionButtons
  }
  if (type === "Viator") {
    return Viator
  }

  return []
}

const PromotionForm = ({ opened, parent, onCancel, defaultValues }) => {
  const [type, setType] = useState()

  return (
    <RelationFormModule
      form={{
        title: "Promotions",
        fields: [
          {
            ID: "CF5E0A4F-BF8D-235464-3654-23452435234",
            Type: "Dropdown",
            Name: "Status",
            Label: "Status",
            Required: true,
            GetSelectionOptions: values => {
              return [
                { Name: "Active", ID: "Active" },
                { Name: "Inactive", ID: "Inactive" },
                { Name: "Deleted", ID: "Deleted" },
                { Name: "Removed", ID: "Removed" },
              ]
            },
          },
          {
            ID: "CF5E0A4F-BF8D-235464-3654-0A1DDD1CC4A1",
            Type: "Dropdown",
            Name: "Type",
            Label: "Type",
            GetSelectionOptions: values => {
              return promotionTypeOptions
            },
            updateValue: (values, val) => {
              setType(val)
              return { ...values, Type: val }
            },
          },
          {
            ID: "87E30CD9-82D0-442F-8698-2435234523",
            Type: "Text",
            FieldType: "number",
            Name: "Order",
            Label: "Order",
            Required: true,
            Prompt: "Order is required",
          },
          ...Fields(type),
        ],
        fieldsToTranslate: ["Title", "SubTitle", "Description"],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) {
            if (defaultValues) {
              setType(defaultValues.Type)
              return { ...defaultValues }
            }

            return {}
          }

          const data = await getFirestoreDocument({
            path: `City/Business/Promotions/${opened}`,
          })

          setType(data.Type)

          return data
        },
        onSubmit: async (values, { firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Business/Promotions`,
            values: {
              ...values,
              Campaign_ID: parent,
              Cities: [firebaseUID, ...(values.Cities || [])],
            },
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Business/Promotions`,
            values: { ID: opened, Status: "Deleted" },
          })
        },
      }}
      onCancel={onCancel}
      ID={opened}
    />
  )
}

export default PromotionForm
