import TabNavigation from "components/Common/UI/TabNavigation"
import React, { useState } from "react"

// module for handling tabs
// expects a list of tabs with:
// label - title
// requiresID - does this tab require an ID of the item to be present to be clickable
//            - used to not show relations like child's parents until a child is created
// Component - component to render when that tab is opened
// and opened - ID of the opened item or undefined if item doesn't have an ID yet (create new)

const DocumentTabModule = ({ tabs, opened }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      {tabs.length > 1 && (
        <TabNavigation
          activeTab={activeTab}
          setActive={val => setActiveTab(val)}
          navList={tabs.map((tab, i) => ({
            ...tab,
            name: i,
            disabled: tab.requiresID && opened === undefined,
          }))}
        />
      )}

      {tabs[activeTab].Component(opened)}
    </>
  )
}

export default DocumentTabModule
