import React from "react"
import { Label } from "reactstrap"
import { AvGroup, AvField, AvFeedback } from "availity-reactstrap-validation"

const Text = ({ item, value, disabled, updateValue }) => {
  return (
    <AvGroup className="error-t-negative error-l-150">
      <AvField
        value={value}
        label={item.Label}
        name={item.Name}
        type={item.FieldType || "text"}
        validate={item.Required ? { required: { value: true } } : {}}
        disabled={disabled}
        onChange={e => {
          if (typeof updateValue !== "function") return
          if (item.FieldType === "number") {
            updateValue(Number(e.target.value))
          } else updateValue(e.target.value)
        }}
      />
      <AvFeedback>{item.Prompt}</AvFeedback>
    </AvGroup>
  )
}

export default Text
