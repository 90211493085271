const HorizontalBannerListFields = [
  {
    ID: "223453",
    Name: "",
    Type: "Separator",
  },
  {
    ID: "EDE95D97-B65D-4C8A-BD44-53543",
    Name: "Title",
    Label: "Title (not visible in app)",
    Type: "Text",
    Prompt: "Title is required",
  },
  {
    ID: "EDE95D97-B65D-4C8A-3547686-7AC1C842536A5B9DE",
    Name: "SuperSuperTitle",
    Label: "SuperSuperTitle",
    Type: "Text",
  },
  {
    ID: "EDE95D97-B65D-4C8A-235356-7AC1C2348A5B9DE",
    Name: "SuperTitle",
    Label: "SuperTitle",
    Type: "Text",
  },
]

export default HorizontalBannerListFields
