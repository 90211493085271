import TableModule from "components/Modules/Collections/TableModule"
import {
  createFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import React from "react"

const CityVisibilityTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          path: `City/Modules/DataBase/107/DataBase`,
          where: [
            {
              key: "Client_ID",
              operation: "array-contains",
              value: firebaseUID,
            },
          ],
        })
      }}
      columns={[{ key: "Name", label: "Name" }]}
      searchKey={"Name"}
    />
  )
}

export default CityVisibilityTable
