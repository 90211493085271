import TableModule from "components/Modules/Collections/TableModule"
import {
  createFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"

const StatusColors = {
  Active: "#02a499",
  Inactive: "#ec4561",
  Pending: "#f8b425",
}

const CityBudgetProposalsTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        const data = await getDataFromFirestoreCollection({
          sortBy: { key: "DateOfSubmission", inverse: true },
          path: `City/Cities/DataBase/${firebaseUID}/Modules/137/DataBase`,
        })

        return data.map(item => ({
          ...item,
          Status: {
            ID: item.Status,
            text: item.Status,
            color: StatusColors[item.Status],
          },
        }))
      }}
      columns={[
        { key: "Title", label: "Title" },
        {
          key: "DateOfSubmission",
          label: "Date of Submission",
          styling: { Type: "Timestamp" },
        },
        {
          key: "Status",
          label: "Status",
          styling: {
            Type: "Status",
          },
        },
      ]}
      searchKey={"Title"}
      noInitialSort
    />
  )
}

export default CityBudgetProposalsTable
