import { supportedLanguages } from "applications/city/helpers/defaultValues"
import FormControler from "components/ModuleElements/Form/FormControler"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// a template form module for editing/creating entries in a modal for relations
const RelationFormModule = ({
  form,
  ID,
  firebaseUID,
  onCancel,
  languages,
  defaultLanguage,
}) => {
  const fields = []

  form.fields.forEach(x => {
    const field = {
      ...x,
      GetSelectionOptions:
        x.GetSelectionOptions !== undefined
          ? async stateValues =>
              x.GetSelectionOptions(stateValues, { firebaseUID })
          : undefined,
      CreateSelectionOption:
        x.CreateSelectionOption !== undefined
          ? async (input, stateValues) =>
              x.CreateSelectionOption(input, stateValues, {
                firebaseUID,
              })
          : undefined,
      DeleteSelectionOption:
        x.DeleteSelectionOption !== undefined
          ? async (optionID, stateValues) =>
              x.DeleteSelectionOption(optionID, stateValues, {
                firebaseUID,
              })
          : undefined,
      GetCategorySelectionOptions:
        x.GetCategorySelectionOptions !== undefined
          ? async (parents, stateValues) =>
              x.GetCategorySelectionOptions(parents, stateValues, {
                firebaseUID,
              })
          : undefined,
      CreateCategorySelectionOption:
        x.CreateCategorySelectionOption !== undefined
          ? async (input, parents, stateValues) =>
              x.CreateCategorySelectionOption(input, parents, stateValues, {
                firebaseUID,
              })
          : undefined,
      DeleteCategorySelectionOption:
        x.DeleteCategorySelectionOption !== undefined
          ? async (optionID, parents, stateValues) =>
              x.DeleteCategorySelectionOption(optionID, parents, stateValues, {
                firebaseUID,
              })
          : undefined,
    }

    fields.push(field)

    if (form.fieldsToTranslate?.includes(x.Name)) {
      languages.forEach(lang => {
        if (lang.ID === defaultLanguage) {
          return
        }

        fields.push({
          ...field,
          Name: `Translate.${lang.ID}.${x.Name}`,
          Label: `${x.Label} (${lang.Name})`,
          ID: `${x.ID}-${lang.ID}`,
        })
      })
    }
  })

  return (
    <FormControler
      form={{
        ...form,
        fields,
        getDefaultValues: async () => {
          return await form.getDefaultValues({ firebaseUID })
        },
        onSubmit: async values => await form.onSubmit(values, { firebaseUID }),
        onDelete:
          form.onDelete !== undefined
            ? async () => {
                await form.onDelete({ firebaseUID })
              }
            : undefined,
      }}
      ID={ID}
      onCancel={() => {
        onCancel()
      }}
    />
  )
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  const clientLanguages = client?.MetaData?.SupportedLanguages || []

  return {
    firebaseUID: client.ID,
    languages: supportedLanguages.filter(x => clientLanguages.includes(x.ID)),
    defaultLanguage: client?.MetaData?.DefaultLanguage,
  }
}
export default connect(mapStateToProps, {})(withRouter(RelationFormModule))
