import { Redirect } from "react-router-dom"

// Home
import Account from "../pages/app/account"
import Home from "../pages/app/home"

import { adminRoot } from "../constants/defaultValues/defaultValues"
import _accountEditorRoutes from "./_accountEditorRoutes"
import _accountManagerRoutes from "./_accountManagerRoutes"
import _authRoutes from "./_authRoutes"
import _userRoutes from "./_userRoutes"
import _utilityRoutes from "./_utilityRoutes"

const userRoutes = [
  { path: `${adminRoot}home`, exact: true, strict: true, component: Home },
  {
    path: `${adminRoot}account`,
    component: Account,
  },

  ..._userRoutes,

  // ADMIN ROUTES
  ..._accountEditorRoutes,
  ..._accountManagerRoutes,

  // this route should be at the end of all other routes
  {
    path: `${adminRoot}`,
    exact: true,
    component: () => <Redirect to={`${adminRoot}home`} />,
  },
]

const authRoutes = [..._authRoutes]

const utilityRoutes = [..._utilityRoutes]

export { authRoutes, userRoutes, utilityRoutes }

