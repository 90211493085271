import TableModule from "components/Modules/Collections/TableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"

const CityAddressBookTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        const data = await getDataFromFirestoreCollection({
          sortBy: { key: "name" },
          path: `City/Cities/DataBase/${firebaseUID}/Modules/133/DataBase`,
        })

        data.forEach(item => {
          if (item.workingHours) {
            console.log(item)
          }
        })

        return data
      }}
      columns={[{ key: "name", label: "Name" }]}
      searchKey={"name"}
    />
  )
}

export default CityAddressBookTable
