import { AvForm } from "availity-reactstrap-validation"
import DateTime from "components/ModuleElements/Form/Fields/FieldTypes/DateTime"
import Dropdown from "components/ModuleElements/Form/Fields/FieldTypes/Dropdown"
import Text from "components/ModuleElements/Form/Fields/FieldTypes/Text"
import TableModule from "components/Modules/Collections/TableModule"
import React, { useState } from "react"
import { Button } from "reactstrap"

const NewsAPITable = () => {
  const [refresh, setRefresh] = useState(0)
  const [query, setQuery] = useState("green transition")
  const [country, setCountry] = useState()
  const [language, setLanguage] = useState("en")
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [sortBy, setSortBy] = useState("popularity")

  const currentURL = `https://newsapi.org/v2/everything?q=${encodeURIComponent(
    query.split(" ").join(" AND ")
  )}${country ? `&country=${country}` : ""}${
    from ? `&from=${from.toISOString()}` : ""
  }${
    to ? `&to=${to.toISOString()}` : ""
  }&language=${language}&sortBy=${sortBy}&apiKey=1d0899ed434747b2b61142f5bec242fd`

  return (
    <>
      <span>{currentURL}</span>

      <AvForm
        className="av-tooltip tooltip-label-right pt-3 pb-3"
        onSubmit={(event, errors, values) => {
          setRefresh(refresh + 1)
        }}
      >
        <Text
          value={query}
          updateValue={val => {
            setQuery(val)
          }}
          item={{
            Label: "Query",
            Name: "Query",
          }}
        />

        <DateTime
          item={{ Label: "From" }}
          value={from}
          updateValue={val => setFrom(val)}
        />

        <DateTime
          item={{ Label: "To" }}
          value={to}
          updateValue={val => setTo(val)}
        />

        <Dropdown
          item={{
            GetSelectionOptions: () => {
              return [
                "ar",
                "de",
                "en",
                "es",
                "fr",
                "he",
                "it",
                "nl",
                "no",
                "pt",
                "ru",
                "sv",
                "ud",
                "zh",
              ].map(c => ({ ID: c, Name: c.toUpperCase() }))
            },
            Label: "Language",
          }}
          value={language}
          updateValue={val => setLanguage(val)}
        />

        <Dropdown
          item={{
            GetSelectionOptions: () => {
              return [
                { ID: "relevancy", Name: "Relevancy" },
                { ID: "popularity", Name: "Popularity" },
                { ID: "publishedAt", Name: "Published at" },
              ]
            },
            Label: "Sort by",
          }}
          value={sortBy}
          updateValue={val => setSortBy(val)}
        />

        {/* 
        <Dropdown
          item={{
            GetSelectionOptions: () => {
              return [
                "ae",
                "ar",
                "at",
                "au",
                "be",
                "bg",
                "br",
                "ca",
                "ch",
                "cn",
                "co",
                "cu",
                "cz",
                "de",
                "eg",
                "fr",
                "gb",
                "gr",
                "hk",
                "hu",
                "id",
                "ie",
                "il",
                "in",
                "it",
                "jp",
                "kr",
                "lt",
                "lv",
                "ma",
                "mx",
                "my",
                "ng",
                "nl",
                "no",
                "nz",
                "ph",
                "pl",
                "pt",
                "ro",
                "rs",
                "ru",
                "sa",
                "se",
                "sg",
                "si",
                "sk",
                "th",
                "tr",
                "tw",
                "ua",
                "us",
                "ve",
                "za",
              ].map(c => ({ ID: c, Name: c.toUpperCase() }))
            },
            Label: "Country",
          }}
          value={country}
          updateValue={val => setCountry(val)}
        /> */}

        <Button color="primary" className="float-right">
          Submit
        </Button>
      </AvForm>

      <TableModule
        getData={async ({ firebaseUID }) => {
          let res

          await fetch(currentURL)
            .then(response => response.text())
            .then(result => {
              res = JSON.parse(result).articles
            })
            .catch(error => {
              console.log(error)
            })

          return res
        }}
        noCreateNew
        columns={[{ key: "title", label: "Title" }]}
        searchKey={"title"}
        refresh={refresh}
        noInitialSort
        openInNewTab
      />
    </>
  )
}

export default NewsAPITable
