import FormModule from "components/Modules/Documents/FormModule"
import { firestoreObject } from "helpers/Firebase"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
  deleteFirestoreDocument,
  getDataFromFirestoreCollection,
  createFirestoreDocument,
} from "helpers/Firestore"
import React from "react"

const CitySportForm = ({ opened, type, typeID }) => {
  return (
    <FormModule
      form={{
        title: type + " club",
        fields: [
          {
            ID: "EDE95D97-B65D-4C8A-BD44-52063897643",
            Name: "Cities",
            Label: "Cities",
            Type: "Checkbox Buttons",
            GetSelectionOptions: async (values,{ firebaseUID}) => {
              return await getDataFromFirestoreCollection({
                path: `City/Cities/DataBase`,
                sortBy: { key: "Name" },
              })
            },
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-7AC1C8A5B9DE",
            Name: "Name",
            Label: "Name",
            Type: "Text",
            Required: true,
            Prompt: "Name is required",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-543657",
            Name: "Code",
            Label: "Code",
            Type: "Text",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-tryet",
            Name: "WidgetBackgroundColor",
            Label: "Widget Background Color (#111111)",
            Type: "Text",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-523523",
            Name: "TextColor",
            Label: "Text Color (#111111)",
            Type: "Text",
          },
          {
            ID: "CF5E0A4F-BF8D-4553-9DFF-5234523425",
            Type: "Single Image",
            Name: "LogoImage",
            Label: "Logo image",
            Path: "SportsClub/",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-2435432",
            Name: "APISports_ID",
            Label: "APISports_ID",
            Type: "Text",
            FieldType: "number",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          const cities = await getDataFromFirestoreCollection({
            path: "City/Cities/DataBase",
          })

          const thisCity = cities.find(x => x.ID === firebaseUID)

          if (!opened)
            return {
              Cities: cities
                .filter(x => x.Country_ID === thisCity.Country_ID)
                .map(x => x.ID),
            }

          const data = await getFirestoreDocument({
            path: `City/Modules/DataBase/113/Clubs/${opened}`,
          })

          return {
            ...data,
          }
        },
        onSubmit: async (values,{ firebaseUID}) => {
          const club = await createOrUpdateFirestoreDocument({
            path: `City/Modules/DataBase/113/Clubs`,
            values: {
              Type: type,
              ...values,
            },
          })

          await Promise.all(
            values.Cities.map(async city => {
              await createOrUpdateFirestoreDocument({
                path: `City/Cities/DataBase/${city}/Modules`,
                values: { ID: typeID, [`WidgetData.Clubs.${club.ID}`]: club },
              })
            })
          )

          return club
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Modules/DataBase/113/Clubs/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CitySportForm
