import FormModule from "components/Modules/Documents/FormModule"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  firestoreBatchCommit,
  getDataFromFirestoreCollection,
  getFirestoreDocument,
} from "helpers/Firestore"

const CitySurveysForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Survey",
        fields: [
          {
            Name: "Name",
            Label: "Name",
            Type: "Text",
            ID: "uwirvj90h248iovwrehuvbsvnd9p24ihout",
          },
          {
            ID: "339C10F4-389B-461F-94F9-85B7737D3497",
            Type: "Rich Text Field",
            Name: "FullText",
            Label: "Full text",
          },
          {
            ID: "DA248088-A785-472B-9516-9208547",
            Type: "DateTime",
            Name: "DateStart",
            Label: "Date of survey start",
          },
          {
            ID: "DA248088-A785-472B-9516-2/5069052",
            Type: "DateTime",
            Name: "DateEnd",
            Label: "Date of survey end",
          },
          {
            ID: "CF5E0A4F-BF8D-4553-9DFF-fdrgt",
            Type: "Editable Table",
            Name: "Answers",
            DeletedName: "DeletedAnswers",
            Label: "Answers",
            Columns: [
              {
                Name: "ID",
                Label: "ID",
              },
              { Name: "Name", Label: "Name", Editable: true },
              {
                Name: "Translate.en.Name",
                Label: "Name in English",
                Editable: true,
              },
            ],
            Required: false,
            Creatable: true,
            Orderable: true,
          },
        ],
        fieldsToTranslate: ["Name", "FullText"],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return { Answers: [], DeletedAnswers: [] }

          const survey = await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/56/DataBase/${opened}`,
            timestampKeys: ["DateStart", "DateEnd"],
          })

          const answers = await getDataFromFirestoreCollection({
            sortBy: { key: "Order" },
            path: `City/Cities/DataBase/${firebaseUID}/Modules/56/DataBase/${opened}/Answers`,
          })

          return {
            ...survey,
            Answers: answers,
            DeletedAnswers: [],
          }
        },
        onSubmit: async (values, { firebaseUID }) => {
          const cityData = await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}`,
          })

          const _values = { ...values }

          delete _values.Answers
          delete _values.DeletedAnswers

          const survey = await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/56/DataBase`,
            values: _values,
            timestampKeys: ["DateStart", "DateEnd"],
          })

          const _answers = []
          await Promise.all(
            values.Answers.map(async answer => {
              _answers.push({ ...answer })
            })
          )

          const answers = await firestoreBatchCommit({
            commits: [
              ..._answers.map((answer, index) => {
                if (answer.ID.startsWith("NEW")) {
                  return {
                    type: "add",
                    path: `City/Cities/DataBase/${firebaseUID}/Modules/56/DataBase/${survey.ID}/Answers`,
                    values: {
                      Name: answer.Name,
                      Order: index,
                      Translate: answer.Translate,
                      User_IDs: [],
                      NumberOfAnswers: 0,
                    },
                  }
                }

                return {
                  type: "update",
                  path: `City/Cities/DataBase/${firebaseUID}/Modules/56/DataBase/${survey.ID}/Answers/${answer.ID}`,
                  values: {
                    Name: answer.Name,
                    ID: answer.ID,
                    Order: index,
                    Translate: answer.Translate,
                  },
                }
              }),
              ...values.DeletedAnswers.map(deletedAnswer => ({
                type: "delete",
                path: `City/Cities/DataBase/${firebaseUID}/Modules/56/DataBase/${survey.ID}/Answers/${deletedAnswer}`,
              })),
            ],
          })

          return {
            ...survey,
            Answers: answers.newValues,
            DeletedAnswers: [],
          }
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/56/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CitySurveysForm
