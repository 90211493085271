import RelationTableModule from "components/Modules/Relations/RelationTableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"
import CityAskALocalAnswersForm from "./_elements/CityAskALocalAnswersForm"

const CityAskALocalAnswers = ({ opened }) => {
  return (
    <RelationTableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          sortBy: { key: "DateOfSubmission" },
          path: `City/Cities/DataBase/${firebaseUID}/Modules/111/DataBase/${opened}/Answers`,
        })
      }}
      OpenedComponent={(ID, onCancel) => (
        <CityAskALocalAnswersForm
          opened={ID}
          parent={opened}
          onCancel={onCancel}
        />
      )}
      CreateComponent={onCancel => (
        <CityAskALocalAnswersForm parent={opened} onCancel={onCancel} />
      )}
      columns={[{ key: "Text", label: "Text" }]}
      searchKey={"Text"}
    />
  )
}

export default CityAskALocalAnswers
