import React, { useState } from "react"
import { AvGroup, AvFeedback } from "availity-reactstrap-validation"
import { InputGroup, CustomInput, Label } from "reactstrap"
import { connect } from "react-redux"

import { storage } from "../../../../../helpers/Firebase"
import NotificationAlert from "../../../../Common/Notifications/NotificationAlert"

const Audio = ({ item, updateValue, value, firebaseUID, disabled, path }) => {
  const ref = storage
    .ref()
    .child(`Clients/DataBase/${firebaseUID}/ClientData/${path}`)

  const uploadFile = file => {
    const timeStamp = Date.now()

    const imageRef = ref.child(`${timeStamp}`)

    imageRef.put(file).then(
      async () => {
        const downloadURL = await imageRef.getDownloadURL()

        updateValue(downloadURL)
      },
      () => {
        NotificationAlert({ type: "error", title: "Failed" })
      }
    )
  }

  return (
    <>
      <AvGroup className="error-t-negative error-l-150">
        <Label>{item.Label}</Label>
        <div className="align-items-center row">
          {value && (
            <div className="col-2">
              <img
                src={
                  "https://www.seekpng.com/png/detail/466-4666660_file-audio-o-comments-sound-file-icon.png"
                }
                className="w-100"
                alt="img"
              />
            </div>
          )}
          <InputGroup className={`mb-3 ${value ? "col-10" : "col-12"}`}>
            <CustomInput
              className="text-truncate"
              label={value}
              type="file"
              accept="audio/*"
              id={`file_upload_custom_input_${item.ID}`}
              name={item.name}
              onChange={e => {
                uploadFile(e.target.files[0])
              }}
              disabled={disabled}
            />
          </InputGroup>
        </div>

        <AvFeedback>{item.Prompt}</AvFeedback>
      </AvGroup>
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  return { firebaseUID: client.ID }
}
export default connect(mapStateToProps, {})(Audio)
