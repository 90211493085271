import React from "react"
import { withRouter } from "react-router"

import Controler from "../../../../components/Admin/AccountManager/Editor/Controler"
import CityForm from "./Tabs/CityForm"

const Creator = ({ history, location }) => {
  return (
    <Controler
      history={history}
      location={location}
      form={() => <CityForm history={history} />}
    />
  )
}

export default withRouter(Creator)
