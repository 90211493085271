import Dropdown from "components/ModuleElements/Form/Fields/FieldTypes/Dropdown"
import TableModule from "components/Modules/Collections/TableModule"
import RelationAddExistingModule from "components/Modules/Relations/RelationAddExistingModule"
import {
  createFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Modal, ModalBody } from "reactstrap"

const RelationAddExistingWithFormModule = ({
  getData,
  getRelations,
  noCreateNew,
  noInitialSort,
  searchKey,
  openInNewTab,
  columns,
  refresh,
  refreshRelations,
  openButtonIndex,
  actionButtons,
  FormComponent,
  deleteRelation,
  shouldQueryDataInParent,
  isActive,
}) => {
  const [opened, setOpened] = useState()

  console.log(refresh)

  return (
    <div>
      <Modal
        size="lg"
        isOpen={opened !== undefined}
        toggle={() => {
          setOpened(undefined)
        }}
      >
        <ModalBody>
          {opened !== undefined &&
            FormComponent(opened, () => {
              setOpened(undefined)
            })}
        </ModalBody>
      </Modal>

      <RelationAddExistingModule
        getData={getData}
        getRelations={getRelations}
        noCreateNew={noCreateNew}
        noInitialSort={noInitialSort}
        searchKey={searchKey}
        openInNewTab={openInNewTab}
        columns={columns}
        refresh={refresh}
        refreshRelations={refreshRelations}
        openButtonIndex={openButtonIndex}
        actionButtons={actionButtons}
        addRelation={item => {
          setOpened(item)
        }}
        deleteRelation={deleteRelation}
        shouldQueryDataInParent={shouldQueryDataInParent}
        isActive={isActive}
      />
    </div>
  )
}

const mapStateToProps = ({}) => {
  return {}
}
export default connect(mapStateToProps, {})(RelationAddExistingWithFormModule)
