// import $ from 'jquery';

import firebase from "firebase/app"

import { firestore, storage } from "./Firebase"
import {
  adminRoot,
  UserRole,
  OcpApimSubscriptionKey,
  croatianAlphabet,
  applicationSubdomain,
} from "../constants/defaultValues/defaultValues"
import { ThemeColors } from "./ThemeColors"
import NotificationAlert from "../components/Common/Notifications/NotificationAlert"
import customStyles from "../constants/styles"
import ApplicationSpecificConfig from "applications/ApplicationSpecificConfig"

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key]
    const B = b[key]
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1
    }
    return -1
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return `${dd}.${mm}.${yyyy}`
}

export const getCurrentTime = () => {
  const now = new Date()
  return `${now.getHours()}:${now.getMinutes()}`
}

// export const checkIfUserIsSignedIn = () => {
//   auth.onAuthStateChanged((user) => {
//     if (!user) {
//       console.log('LOGGING USER OUT');
//       console.log(localStorage.getItem('authUser'));
//       logoutUser();
//     }
//   });
// };

// export const signOutUser = () => {
//   console.log('SIGNING USER OUT');
//   auth.signOut();
// };

export const getNewID = () => {
  const ref = firestore.collection(" ").doc()

  return ref.id
}

export const getCurrentUser = () => {
  let user = null

  try {
    user =
      localStorage.getItem("authUser") != null
        ? JSON.parse(localStorage.getItem("authUser"))
        : null
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error)
    user = null
  }

  return user
}

export const setCurrentUser = user => {
  try {
    if (user) {
      localStorage.setItem("authUser", JSON.stringify(user))
    } else {
      localStorage.removeItem("authUser")
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error)
  }
}

// gets a random image from Firebase storage
// used for getting images for background in Login/Register...
export const getRandomBackgroundImage = async () => {
  let url = ""
  await storage
    .ref("/background_images")
    .listAll()
    .then(async result => {
      const randomIndex = Math.round(Math.random() * (result.items.length - 1))
      url = await result.items[randomIndex].getDownloadURL()
    })

  return url
}

const validateOIB = qvalue => {
  const value = [...qvalue]
  const controlDigit = Number(value[value.length - 1])
  value.pop()
  let sum = 10
  value.forEach(digit => {
    sum += Number(digit)
    if (sum !== 10) sum %= 10
    sum = (sum * 2) % 11
  })

  sum = 11 - sum
  if (sum === 10) {
    sum = 0
  }

  return sum === controlDigit
}

const validateMBForCityOrProvince = qvalue => {
  const value = [...qvalue]
  const controlDigit = Number(value[value.length - 1])
  value.pop()
  const sum = value[0] * 4 + value[1] * 3 + value[2] * 2

  const raz = 11 - (sum % 11)

  if (raz === 11) return false

  return raz === controlDigit || (raz === 10 && controlDigit === 0)
}

const validateMBForBusinessSubject = qvalue => {
  const value = [...qvalue]
  const controlDigit = Number(value[value.length - 1])
  value.pop()
  let sum = 0
  value.forEach((digit, index) => {
    sum += Number(digit) * (qvalue.length - index)
  })

  const raz = 11 - (sum % 11)

  if (raz === 11) return false

  return raz === controlDigit || (raz === 10 && controlDigit === 0)
}

export const validateOIBAndMB = (qvalue, optional) => {
  if (optional && qvalue === "") return true

  if (!qvalue) return false

  // returning true for now as the options are too complicated to handle well
  return true

  switch (qvalue.length) {
    case 11:
      return validateOIB(qvalue)
    case 4:
      return validateMBForCityOrProvince(qvalue)
    default:
      return validateMBForBusinessSubject(qvalue)
  }
}

export const prepareStringForManipulating = oldString => {
  return oldString
    .toLowerCase()
    .replace("č", "c")
    .replace("ć", "c")
    .replace("đ", "d")
    .replace("ž", "z")
    .replace("š", "s")
}

export const filterUnwantedKeysForTable = keys => {
  return keys.filter(
    k =>
      k !== "ID" && k !== "Client_ID" && k !== "Display" && k !== "FirebaseUID"
  )
}

// makes the menuItems for Users by receiving the list of CMSs that should be inclouded
export const makeUserMenu = (cmsJSON, clientSpecificInformation, clientID) => {
  const currentUser = getCurrentUser()

  const clients = currentUser.Clients
  const roles = clients[clientID].Applications[cmsJSON.ID].Roles
  const role = roles[Object.keys(roles)[0]]

  const checkIfItemShouldShowLevelOne = x => {
    if (clientSpecificInformation[x].CMSStatus !== "Active") return false

    if (role.Administrator) return true

    return role.Rules[x].Allowed
  }

  const checkIfItemShouldShowLevelTwo = (x, y) => {
    if (clientSpecificInformation[x].MenuItems[y].CMSStatus !== "Active")
      return false

    if (role.Administrator) return true

    return role.Rules[y].Allowed
  }

  const menuItems = [
    {
      id: "menu-home",
      label: "menu.home",
      icon: "simple-icon-home",
      to: `${adminRoot}home`,
    },
    {
      id: cmsJSON.ID,
      label: cmsJSON.DisplayName,
      icon: cmsJSON.IconURL,
      to: `${adminRoot}module`,
      subs: [
        ...Object.keys(cmsJSON.MenuItems)
          .filter(x => checkIfItemShouldShowLevelOne(x))
          .sort((a, b) => {
            return cmsJSON.MenuItems[a].Order - cmsJSON.MenuItems[b].Order
          })
          .map(levelOneID =>
            Object.keys(cmsJSON.MenuItems[levelOneID].MenuItems).length > 0
              ? {
                  id: levelOneID,
                  label: cmsJSON.MenuItems[levelOneID].DisplayName,
                  to: `${adminRoot}module`,
                  icon: cmsJSON.MenuItems[levelOneID].IconURL,
                  subs: [
                    ...Object.keys(cmsJSON.MenuItems[levelOneID].MenuItems)
                      .filter(y => checkIfItemShouldShowLevelTwo(levelOneID, y))
                      .sort((a, b) => {
                        return (
                          cmsJSON.MenuItems[levelOneID].MenuItems[a].Order -
                          cmsJSON.MenuItems[levelOneID].MenuItems[b].Order
                        )
                      })
                      .map(levelTwoID => ({
                        id: levelTwoID,
                        icon: cmsJSON.MenuItems[levelOneID].MenuItems[
                          levelTwoID
                        ].IconURL,
                        label:
                          cmsJSON.MenuItems[levelOneID].MenuItems[levelTwoID]
                            .DisplayName,
                        to: `${adminRoot}module/${levelTwoID}`,
                      })),
                  ],
                }
              : {
                  id: levelOneID,
                  label: cmsJSON.MenuItems[levelOneID].DisplayName,
                  to: `${adminRoot}module/${levelOneID}`,
                  icon: cmsJSON.MenuItems[levelOneID].IconURL,
                }
          ),
      ],
    },
  ]

  if (role.Administrator)
    menuItems.push({
      id: "menu.settings",
      label: "menu.settings",
      icon: "iconsminds-gear",
      to: `${adminRoot}`,
      subs: [
        {
          id: "menu.admin-settings",
          label: "menu.admin-settings",
          icon: "iconsminds-male-female",
          to: `${adminRoot}`,
          subs: [
            {
              id: "menu.administrators",
              label: "menu.administrators",
              icon: "iconsminds-business-man",
              to: `${adminRoot}administrators`,
            },
            {
              id: "menu.roles",
              label: "menu.roles",
              icon: "iconsminds-diploma-2",
              to: `${adminRoot}roles`,
            },
          ],
        },
        // removed gallery when the decision was made to upload files independently every time
        // {
        //   id: "menu.gallery",
        //   label: "menu.gallery",
        //   icon: "iconsminds-photo",
        //   to: `${adminRoot}gallery`,
        // },
      ],
    })
  // else
  //   menuItems.push({
  //     id: "menu.settings",
  //     label: "menu.settings",
  //     icon: "iconsminds-gear",
  //     to: `${adminRoot}`,
  //     subs: [
  //       {
  //         id: "menu.gallery",
  //         label: "menu.gallery",
  //         icon: "iconsminds-photo",
  //         to: `${adminRoot}gallery`,
  //       },
  //     ],
  //   })

  return menuItems
}

// makes the menuItems for Users by receiving the list of CMSs that should be inclouded
export const makeAccountEditorUserMenu = (cmsJSON, clientID) => {
  const colors = ThemeColors()
  const currentUser = getCurrentUser()

  if (!(currentUser.role < 4))
    return [
      {
        id: "menu.home",
        label: "menu.home",
        icon: "simple-icon-home",
        to: adminRoot,
      },
    ]

  return [
    {
      id: "menu-home",
      label: "menu.home",
      icon: "simple-icon-home",
      subs: [
        {
          id: "menu-home",
          label: "menu.home",
          icon: "simple-icon-home",
          to: `${adminRoot}home`,
          search: `?clientID=${clientID}`,
        },
      ],
    },
    {
      id: cmsJSON.ID,
      label: cmsJSON.DisplayName,
      icon: cmsJSON.IconURL,
      to: `${adminRoot}module`,
      subs: [
        ...Object.keys(cmsJSON.MenuItems)
          .sort((a, b) => {
            return cmsJSON.MenuItems[a].Order - cmsJSON.MenuItems[b].Order
          })
          .map(levelOneID =>
            Object.keys(cmsJSON.MenuItems[levelOneID].MenuItems).length > 0
              ? {
                  id: levelOneID,
                  label: cmsJSON.MenuItems[levelOneID].DisplayName,
                  to: `${adminRoot}module`,
                  icon: cmsJSON.MenuItems[levelOneID].IconURL,
                  subs: [
                    ...Object.keys(cmsJSON.MenuItems[levelOneID].MenuItems)
                      .sort((a, b) => {
                        return (
                          cmsJSON.MenuItems[levelOneID].MenuItems[a].Order -
                          cmsJSON.MenuItems[levelOneID].MenuItems[b].Order
                        )
                      })
                      .map(levelTwoID => ({
                        id: levelTwoID,
                        icon: cmsJSON.MenuItems[levelOneID].MenuItems[
                          levelTwoID
                        ].IconURL,
                        label:
                          cmsJSON.MenuItems[levelOneID].MenuItems[levelTwoID]
                            .DisplayName,
                        to: `${adminRoot}module/${levelTwoID}`,
                        search: `?clientID=${clientID}`,
                      })),
                  ],
                }
              : {
                  id: levelOneID,
                  label: cmsJSON.MenuItems[levelOneID].DisplayName,
                  to: `${adminRoot}module/${levelOneID}`,
                  icon: cmsJSON.MenuItems[levelOneID].IconURL,
                  search: `?clientID=${clientID}`,
                }
          ),
      ],
    },
    {
      id: "menu.settings",
      label: "menu.settings",
      icon: "iconsminds-gear",
      to: `${adminRoot}`,
      subs: [
        {
          id: "menu.admin-settings",
          label: "menu.admin-settings",
          icon: "iconsminds-male-female",
          to: `${adminRoot}`,
          subs: [
            {
              id: "menu.administrators",
              label: "menu.administrators",
              icon: "iconsminds-business-man",
              to: `${adminRoot}administrators`,
              search: `?clientID=${clientID}`,
            },
            {
              id: "menu.roles",
              label: "menu.roles",
              icon: "iconsminds-diploma-2",
              to: `${adminRoot}roles`,
              search: `?clientID=${clientID}`,
            },
          ],
        },
        // {
        //   id: "menu.campaigns",
        //   label: "menu.campaigns",
        //   icon: "iconsminds-project",
        //   to: `${adminRoot}campaigns`,
        //   search: `?clientID=${clientID}`,
        // },
        // {
        //   id: "menu.gallery",
        //   label: "menu.gallery",
        //   icon: "iconsminds-photo",
        //   to: `${adminRoot}gallery`,
        //   search: `?clientID=${clientID}`,
        // },
      ],
    },
    {
      id: "menu.application.back",
      icon: "iconsminds-left",
      label: "menu.application.back",
      to: `${adminRoot}home`,
      color: colors.primary,
      action: true,
    },
  ]
}

// makes the menuItems for Admins by receiving the list of applications and list of system settings
export const makeAdminMenu = applicationsList => {
  const colors = ThemeColors()

  return [
    {
      id: "menu-home",
      label: "menu.home",
      icon: "simple-icon-home",
      to: `${adminRoot}home`,
      subs: [
        {
          id: "menu-home-sub",
          label: "menu.home",
          icon: "simple-icon-home",
          to: `${adminRoot}home`,
        },
      ],
    },
    {
      id: "account-editor",
      icon: "iconsminds-pen-2",
      label: "menu.account-editor",
      to: `${adminRoot}editor`,
      roles: [
        UserRole["Super Administrator"],
        UserRole["API Manager"],
        UserRole["Accounts Manager"],
        UserRole["Accounts Editor"],
      ],
      subs: ApplicationSpecificConfig[
        applicationSubdomain
      ].makeAccountEditorMenu(),
    },
    {
      id: "accounts-manager",
      icon: "simple-icon-people",
      label: "menu.accounts-manager",
      to: `${adminRoot}manager`,
      roles: [
        UserRole["Super Administrator"],
        UserRole["API Manager"],
        UserRole["Accounts Manager"],
      ],
      subs: ApplicationSpecificConfig[
        applicationSubdomain
      ].makeAccountManagerMenu(),
    },
    {
      id: "api-manager",
      icon: "iconsminds-coding",
      label: "menu.api-manager",
      to: `${adminRoot}apiManager`,
      roles: [UserRole["Super Administrator"], UserRole["API Manager"]],
      subs: [
        {
          id: "api-manager-partners",
          label: "menu.api-manager-partners",
          to: `${adminRoot}apiManager/partners`,
          icon: "simple-icon-people",
          subs: [
            {
              id: "api-manager-view-and-edit-partners",
              label: "menu.api-manager-view-and-edit-partners",
              to: `${adminRoot}apiManager/partners/view-and-edit`,
              icon: "simple-icon-people",
            },
            {
              id: "api-manager-add-new-partner",
              label: "menu.api-manager-add-new-partner",
              to: `${adminRoot}apiManager/partners/add-new`,
              icon: "simple-icon-note",
              color: colors.primary,
            },
          ],
        },
        {
          id: "api-manager-modules",
          label: "menu.api-manager-modules",
          to: `${adminRoot}apiManager/modules`,
          icon: "iconsminds-coding",
          subs: [
            {
              id: "api-manager-latest-modules",
              label: "menu.api-manager-latest-modules",
              icon: "iconsminds-coding",
              to: `${adminRoot}apiManager/modules/latest`,
            },
            {
              id: "api-manager-waiting-for-review",
              label: "menu.api-manager-waiting-for-review",
              to: `${adminRoot}apiManager/modules/waiting-for-review`,
              icon: "simple-icon-clock",
              color: colors.secondary,
            },
            {
              id: "api-manager-error-logs",
              label: "menu.api-manager-error-logs",
              to: `${adminRoot}apiManager/modules/error-logs`,
              icon: "iconsminds-danger",
              color: colors.danger,
            },
          ],
        },
      ],
    },
    {
      id: "cms-creator",
      icon: "iconsminds-factory-1",
      label: "menu.cms-creator",
      roles: [UserRole["Super Administrator"]],
      subs: [
        {
          id: "application-list",
          label: "menu.cms-creator-cms",
          to: `${adminRoot}applicationEditor`,
          icon: "iconsminds-factory",
          subs: [
            ...applicationsList.map(item => ({
              id: `${item.ID}`,
              label: item.DisplayName,
              icon: item.IconURL,
              to: `${adminRoot}applicationEditor`,
              search: `?appID=${item.ID}`,
              action: true,
            })),
            {
              id: "application-list-create-new",
              label: "menu.cms-creator-create-new-cms",
              to: `${adminRoot}applicationEditor`,
              icon: "simple-icon-note",
              color: colors.primary,
            },
          ],
        },
        {
          id: "admin-settings",
          label: "menu.admin-settings",
          to: `${adminRoot}adminSettings`,
          icon: "iconsminds-male-female",
          subs: [
            {
              id: "admin-settings-sub",
              icon: "iconsminds-male-female",
              label: "menu.admins",
              to: `${adminRoot}adminSettings`,
            },
          ],
        },
      ],
    },
  ]
}

// makes the menuItems for Admins when editing an Application
export const makeAdminApplicationEditingMenu = applicationMenu => {
  const colors = ThemeColors()

  return [
    {
      id: applicationMenu.ID,
      icon: applicationMenu.IconURL,
      label: applicationMenu.DisplayName,
      to: `${adminRoot}menuItemEditor`,
      subs: [
        ...Object.keys(applicationMenu.MenuItems)
          .sort((a, b) => {
            return (
              applicationMenu.MenuItems[a].Order -
              applicationMenu.MenuItems[b].Order
            )
          })
          .map(relationID => ({
            id: relationID,
            label: applicationMenu.MenuItems[relationID].DisplayName,
            icon: applicationMenu.MenuItems[relationID].IconURL,
            to: `${adminRoot}menuItemEditor/${relationID}`,
            subs: [
              ...Object.keys(applicationMenu.MenuItems[relationID].MenuItems)
                .sort((a, b) => {
                  return (
                    applicationMenu.MenuItems[relationID].MenuItems[a].Order -
                    applicationMenu.MenuItems[relationID].MenuItems[b].Order
                  )
                })
                .map(subRelationID => ({
                  id: subRelationID,
                  icon: applicationMenu.MenuItems[relationID].MenuItems[
                    subRelationID
                  ].IconURL,
                  label:
                    applicationMenu.MenuItems[relationID].MenuItems[
                      subRelationID
                    ].DisplayName,
                  to: `${adminRoot}menuItemEditor/${subRelationID}`,
                  search: `?appID=${applicationMenu.ID}&parent=${relationID}&ID=${subRelationID}`,
                })),
              {
                id: `${relationID}_add_new`,
                label: "menu.relation-add-new-level-2",
                icon: "simple-icon-note",
                to: `${adminRoot}menuItemEditor/new/${relationID}`,
                search: `?appID=${applicationMenu.ID}&parent=${relationID}`,
                color: colors.primary,
              },
              {
                id: `${relationID}_edit`,
                label: "menu.relation-settings",
                icon: "iconsminds-pen-2",
                to: `${adminRoot}menuItemEditor/edit/${relationID}`,
                search: `?appID=${applicationMenu.ID}&ID=${relationID}`,
                color: colors.secondary,
              },
            ],
          })),
        {
          id: "menu.relation-add-new",
          label: "menu.relation-add-new-level-1",
          to: `${adminRoot}menuItemEditor`,
          search: `?appID=${applicationMenu.ID}`,
          color: colors.primary,
        },
      ],
    },
    {
      id: "menu.application.settings",
      icon: "simple-icon-gears",
      label: "menu.settings",
      to: `${adminRoot}questionCollections`,
      subs: [
        {
          id: "menu.application.edit",
          icon: "iconsminds-pen-2",
          label: "menu.application.edit",
          to: `${adminRoot}applicationEditor`,
          search: `?appID=${applicationMenu.ID}`,
        },
        {
          id: "menu.application.build",
          icon: "simple-icon-wrench",
          label: "menu.application.build",
          to: `${adminRoot}applicationBuilder`,
          search: `?appID=${applicationMenu.ID}`,
        },
      ],
    },

    {
      id: "menu.application.back",
      icon: "iconsminds-left",
      label: "menu.application.back",
      to: `${adminRoot}home`,
      color: colors.primary,
      action: true,
    },
  ]
}

export const compareCroatianString = (n1, n2) => {
  const minimum = Math.min(n1.length, n2.length)
  for (let i = 0; i < minimum; i += 1) {
    const charA = n1[i].toLowerCase()
    const charB = n2[i].toLowerCase()
    let charAint = 0
    let charBint = 0
    if (Object.prototype.hasOwnProperty.call(croatianAlphabet, charA)) {
      charAint = croatianAlphabet[charA] ?? 0
    }
    if (Object.prototype.hasOwnProperty.call(croatianAlphabet, charB)) {
      charBint = croatianAlphabet[charB] ?? 0
    }
    if (charAint > charBint) return 1
    if (charAint < charBint) return -1
  }

  if (n1.length < n2.length) return -1
  if (n1.length > n2.length) return 1
  return 0
}

export const compareDynamicData = (n1, n2, inverse) => {
  if (!n1 && !n2) return 0
  if (!n1) return -1
  if (!n2) return 1

  if (typeof n1 === "string" && typeof n2 === "string") {
    return compareCroatianString(n1, n2) * (inverse ? -1 : 1)
  }

  return (n1 - n2) * (inverse ? -1 : 1)
}

export const queryDataByParameters = (data, params) => {
  const { page, entriesPerPage, sortBy, searchBy } = params
  let _data = [...data]

  if (searchBy) {
    _data = _data.filter(x =>
      x[searchBy.key].toLowerCase().includes(searchBy.value.toLowerCase())
    )
  }

  if (sortBy) {
    _data.sort((a, b) =>
      compareDynamicData(a[sortBy.key], b[sortBy.key], sortBy.inverse)
    )
  }

  _data = _data.splice(page * entriesPerPage, _data.length)

  return _data
}

export const getCompanyDataFromVAT = async VAT => {
  const url = `https://sudreg-api.pravosudje.hr/javni/subjekt_detalji?tipIdentifikatora=oib&identifikator=${VAT}&expand_relations=True`
  let ret = null

  const myHeaders = new Headers()
  myHeaders.append("Ocp-Apim-Subscription-Key", OcpApimSubscriptionKey)

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  }

  await fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => {
      ret = JSON.parse(result)
    })
    .catch(error => {
      console.log(error)
    })

  return ret
}

export const getCraftDataFromVAT = async VAT => {
  let url = `https://portor.contrib.hr/v1/?vatId=${VAT}`
  let ret = null

  const myHeaders = new Headers()

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  }

  await fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => {
      ret = JSON.parse(result)
    })
    .catch(error => {
      console.log(error)
    })

  if (ret.data) return ret

  url = `https://portor.contrib.hr/v1/?id=${VAT}`

  await fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => {
      ret = JSON.parse(result)
    })
    .catch(error => {
      console.log(error)
    })

  return ret
}

export const getLevelTwoMenuItemsFromApplicationMenu = appMenu => {
  const levelTwoMenuItems = []

  Object.keys(appMenu.MenuItems).forEach(levelOneMenuItemKey => {
    Object.keys(appMenu.MenuItems[levelOneMenuItemKey].MenuItems).forEach(
      levelTwoMenuItemKey => {
        levelTwoMenuItems.push({
          Name: appMenu.MenuItems[levelOneMenuItemKey].MenuItems[
            levelTwoMenuItemKey
          ].DisplayName,
          ID: levelTwoMenuItemKey,
        })
      }
    )
  })

  return levelTwoMenuItems
}

export const getFileFromURL = async (url, callback) => {
  const xhr = new XMLHttpRequest()
  xhr.responseType = "blob"
  xhr.onload = () => {
    callback(xhr.response)
  }
  xhr.open("GET", url)
  xhr.send()
}

export const dateStringToTimestamp = dateString => {
  return firebase.firestore.Timestamp.fromDate(new Date(dateString))
}

export const timestampToDateString = timestamp => {
  const ret = new Date(0)

  ret.setUTCSeconds(timestamp.seconds)

  let dd = ret.getDate()
  let mm = ret.getMonth() + 1 // January is 0!
  let hh = ret.getHours()
  let min = ret.getMinutes()

  const yyyy = ret.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  if (hh < 10) {
    hh = `0${hh}`
  }
  if (min < 10) {
    min = `0${min}`
  }

  return `${yyyy}-${mm}-${dd}T${hh}:${min}`
}

export const getHomepageFromFirestore = async (clientID, callback) => {
  firestore
    .collection("City/Business/Campaigns")
    .where("City_ID", "==", clientID)
    .orderBy("Order", "asc")
    .onSnapshot(snapshot => {
      callback([...snapshot.docs.map(doc => ({ ...doc.data(), ID: doc.id }))])
    })
}

export const addCampaignToFirestore = async (clients, val, callback) => {
  const batch = firestore.batch()

  const id_list = []

  clients.forEach(client => {
    id_list.push({ ID: randomFirestoreID(), City_ID: client })
  })

  clients.forEach((client, index) => {
    const id = id_list[index].ID
    const ref = firestore.collection("City/Business/Campaigns").doc(id)

    console.log({
      ...val,
      ID: id,
      StartTime: dateStringToTimestamp(val.StartTime),
      EndTime: dateStringToTimestamp(val.EndTime),
      City_ID: client,
      SameCampaign_IDs: id_list.filter(x => x.ID !== id),
    })

    batch.set(ref, {
      ...val,
      ID: id,
      StartTime: dateStringToTimestamp(val.StartTime),
      EndTime: dateStringToTimestamp(val.EndTime),
      City_ID: client,
      SameCampaign_IDs: id_list.filter(x => x.ID !== id),
    })
  })

  batch.commit().then(res => callback(res))
}

export const getBusinessesFromFirestore = async () => {
  firestore.collection("Visibility").get()
}

export const checkIfFormIsReadyForSubmit = form => {
  let broken = false
  form.FieldGroups.forEach((fieldGroup, fieldGroupIndex) => {
    if (!fieldGroup.DisplayName) {
      NotificationAlert({
        type: "error",
        title: "Error",
        message: `Field Group at ${
          fieldGroupIndex + 1
        } doesn't have a DisplayName`,
        expiration: 3000,
      })
      broken = true
      return
    }

    if (!fieldGroup.Type) {
      NotificationAlert({
        type: "error",
        title: "Error",
        message: `Field Group ${fieldGroup.DisplayName} doesn't have a Type`,
        expiration: 3000,
      })
      broken = true
      return
    }
    if (!fieldGroup.Entity_ID) {
      NotificationAlert({
        type: "error",
        title: "Error",
        message: `Field Group ${fieldGroup.DisplayName} doesn't have an Entity`,
        expiration: 3000,
      })
      broken = true
      return
    }

    if (fieldGroup.Type === "Table" && !fieldGroup.MenuItem_ID) {
      NotificationAlert({
        type: "error",
        title: "Error",
        message: `Field Group ${fieldGroup.DisplayName} doesn't have a Menu Item`,
        expiration: 3000,
      })
      broken = true
      return
    }

    fieldGroup.Fields.forEach((field, fieldIndex) => {
      if (!field.Label) {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: `Field at ${fieldIndex + 1} doesn't have a Label`,
          expiration: 3000,
        })
        broken = true
        return
      }

      if (!field.Type) {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: `Field ${field.Label} doesn't have a Type`,
          expiration: 3000,
        })
        broken = true
        return
      }

      if (!field.Name) {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: `Field ${field.Label} doesn't have a SQL Name`,
          expiration: 3000,
        })
        broken = true
      }
    })

    fieldGroup.Buttons.forEach((button, buttonIndex) => {
      if (!button.Name) {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: `Button at ${buttonIndex + 1} doesn't have a Name`,
          expiration: 3000,
        })
        broken = true
        return
      }

      if (!button.FieldGroupButtonType_ID) {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: `Button ${button.Name} doesn't have a Type`,
          expiration: 3000,
        })
        broken = true
        return
      }

      if (!button.RequestURL) {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: `Button ${button.Name} doesn't have a Request URL`,
          expiration: 3000,
        })
        broken = true
        return
      }

      if (!button.RequestMethod) {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: `Button ${button.Name} doesn't have a Request Method`,
          expiration: 3000,
        })
        broken = true
        return
      }

      if (!button.Text) {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: `Button ${button.Name} doesn't have Text`,
          expiration: 3000,
        })
        broken = true
      }
    })
  })

  return !broken
}

export const uploadOfferPDF = async (
  Client_ID,
  file,
  offerNumber,
  callback
) => {
  const ref = storage.ref().child(`Offers/DataBase/${Client_ID}/${offerNumber}`)

  ref.put(file).then(async () => {
    const downloadURL = await ref.getDownloadURL()
    callback(downloadURL)
  })
}

export const getSelectTheme = theme => {
  const colors = ThemeColors()

  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: colors.primary25,
      primary: colors.primary,
    },
  }
}

export const setStyle = (style, altStyle) => {
  if (
    (!style && !altStyle) ||
    (!customStyles[style] && !customStyles[altStyle])
  )
    return
  let _style = style
  if (!style || !customStyles[style]) _style = altStyle

  document.body.classList.forEach(x => document.body.classList.remove(x))
  document.body.classList.add(_style)
  const properties = customStyles[_style]
  if (properties && properties.colors)
    Object.keys(properties.colors).forEach(prop =>
      document.documentElement.style.setProperty(prop, properties.colors[prop])
    )

  return _style
}

export const makeStylingForDynamicTableFromFieldGroup = fieldGroup => {
  const styling = {}

  fieldGroup.Fields.forEach(field => {
    styling[field.Name] = {
      Type: field.Type,
      Order: field.Order,
      Name: field.Label,
    }
  })

  if (fieldGroup.ID === "AF099307-1A91-4643-A0D2-807E1905E3F3") {
    styling.custom = "News"
  }

  return styling
}

export const getBadgeColorForDateTime = val => {
  const now = new Date()

  if (now.getTime() < val.getTime()) {
    return "bg-secondary"
  }

  return "bg-primary"
}

export const randomFirestoreID = () => {
  const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  let autoId = ""

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length))
  }
  return autoId
}

export const swapPositionsInArray = (arr, a, b) => {
  const newArr = [...arr]
  newArr[a] = arr[b]
  newArr[b] = arr[a]

  return newArr
}

export const translateTextWithGoogleAPI = async (
  text,
  { source, target, plainText }
) => {
  const API_KEY = "AIzaSyDd_VYCttHcxhINRrhpJHQTJfcfl7aA3t0"

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`
  url += "&q=" + encodeURIComponent(text)
  url += `&source=${source || "en"}`
  url += `&target=${target}`
  url += `&format=${plainText ? "text" : "html"}`

  let ret

  await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(res => res.json())
    .then(response => {
      ret = response
    })
    .catch(error => {
      console.log("There was an error with the translation request: ", error)
    })

  return ret
}

export const translateTextsWithGoogleAPI = async (
  texts,
  { source, targets }
) => {
  const ret = {}

  await Promise.all(
    targets.map(async target => {
      ret[target] = []

      await Promise.all(
        texts.map(async text => {
          const translation = await translateTextWithGoogleAPI(text, {
            source,
            target,
          })

          ret[target].push(translation.data.translations[0].translatedText)
        })
      )
    })
  )

  return ret
}

export const translateObjectWithGoogleAPI = async (
  object,
  { source, targets, fieldsToTranslate, plainTextFields }
) => {
  const ret = {}

  let _object = {}

  if (fieldsToTranslate) {
    fieldsToTranslate.forEach(field => {
      if (object[field]) {
        _object[field] = object[field]
      }
    })
  } else {
    _object = { ...object }
  }

  await Promise.all(
    targets.map(async target => {
      ret[target] = {}

      await Promise.all(
        Object.keys(_object).map(async key => {
          const translation = await translateTextWithGoogleAPI(_object[key], {
            source,
            target,
            plainText: plainTextFields && plainTextFields.includes(key),
          })

          ret[target][key] = translation.data.translations[0].translatedText
        })
      )
    })
  )

  return ret
}
