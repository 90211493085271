import FormModule from "components/Modules/Documents/FormModule"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
  deleteFirestoreDocument,
} from "helpers/Firestore"
import React from "react"

const CityMayorsDiaryForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Mayor's diary",
        fields: [
          {
            ID: "EDE95D97-B65D-4C8A-BD44-7AC1C8A5B9DE",
            Name: "Title",
            Label: "Title",
            Type: "Text",
            Required: true,
            Prompt: "Title is required",
          },
          {
            ID: "18E5F152-AC4A-4F7D-829B-832FEE2FF1B2",
            Type: "Text",
            Name: "Subtitle",
            Label: "Subtitle",
          },
          {
            ID: "14A6377F-5778-41D2-B29A-A9ED890D3112",
            Type: "DateTime",
            Name: "DateOfEntry",
            Label: "Date of entry",
            Prompt: "Date of Entry is required",
            Required: true,
          },
          {
            ID: "DA248088-A785-472B-9516-2A7E67F1FDB7",
            Type: "DateTime",
            Name: "DateOfPublication",
            Label: "Date of publication",
            Prompt: "Date of publication is required",
            Required: true,
          },
          {
            ID: "87E30CD9-82D0-442F-8698-FF87BF3E8A96",
            Type: "Text",
            FieldType: "textarea",
            Name: "ShortText",
            Label: "Short text",
          },
          {
            ID: "339C10F4-389B-461F-94F9-85B7737D3497",
            Type: "Rich Text Field",
            Name: "FullText",
            Label: "Text",
          },
          {
            ID: "CF5E0A4F-BF8D-4553-9DFF-0A1DDD1CC4A1",
            Type: "Single Image",
            Name: "Picture",
            Label: "Picture",
            Path: "MayorsDiary/",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          return await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/13/DataBase/${opened}`,
            timestampKeys: ["DateOfEntry", "DateOfPublication"],
          })
        },
        onSubmit: async (values,{ firebaseUID}) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/13/DataBase`,
            timestampKeys: ["DateOfEntry", "DateOfPublication"],
            values,
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/13/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityMayorsDiaryForm
