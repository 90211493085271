import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"

import StartPage from "../../../components/Admin/AccountEditor/StartPage"

const Cities = ({ history }) => {
  return (
    <StartPage
      title="acccounts-manager.cities"
      getClients={async () => {
        const cities = await getDataFromFirestoreCollection({
          path: "City/Cities/DataBase",
          where: [
            {
              key: "Status",
              operation: "==",
              value: "Active",
            },
          ],
        })

        return cities.map(city => ({ ...city, ID: city.Client_ID }))
      }}
      history={history}
    />
  )
}

export default Cities
