import React, { Suspense } from "react"

import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom"

import { NotificationContainer } from "./components/Common/Notifications"
import NonAuthLayout from "./components/Layout/NonAuthLayout"
import Authmiddleware from "./routes/middleware/Authmiddleware"

import { adminRoot } from "./constants/defaultValues/defaultValues"
import AppView from "./pages/app"
import { authRoutes, utilityRoutes } from "./routes/allRoutes"

// Import scss
// import "./assets/vendor/bootstrap.min.css"

import "chartist/dist/scss/chartist.scss"
import "dropzone/dist/min/dropzone.min.css"
import "moment/locale/hr"
import "react-datepicker/dist/react-datepicker.css"
import "react-quill/dist/quill.snow.css"
import "./assets/fonts/iconsmind-s/css/iconsminds.css"
import "./assets/fonts/simple-line-icons.css"
import "./assets/scss/theme.scss"

const App = () => {
  return (
    <React.Fragment>
      <NotificationContainer />
      <Suspense fallback={<div className="loading" />}>
        <Router>
          <Switch>
            <Authmiddleware
              path={`${adminRoot}`}
              component={AppView}
              isAuthProtected={true}
              isClientProtected={true}
            />

            <Redirect path="/" exact to={`${adminRoot}`} />

            {utilityRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                roles={route.roles}
              />
            ))}

            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                roles={route.roles}
                isAuthProtected={route.isAuthProtected}
              />
            ))}

            <Redirect to="/404" />
          </Switch>
        </Router>
      </Suspense>
    </React.Fragment>
  )
}

export default App
