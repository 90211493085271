import { combineReducers } from "redux"

import Layout from "./layout/reducer"
import Auth from "./auth/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Auth,
})

export default rootReducer
