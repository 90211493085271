import FormModule from "components/Modules/Documents/FormModule"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
} from "helpers/Firestore"
import React from "react"
import { withRouter } from "react-router-dom"

const CityAirQuality = () => {
  return (
    <FormModule
      noCancel
      form={{
        title: "News",
        fields: [
          {
            ID: "EDE95D97-B65D-4C8A-BD44-7AC1C8A5B9DE",
            Name: "functionName",
            Label: "functionName",
            Type: "Text",
          },
          {
            ID: "EDE95D97-B65D-4C8A-BD44-23452345234",
            Name: "updateTime",
            Label: "updateTime",
            Type: "Text",
            FieldType: "number",
          },
          {
            ID: "18E5F152-AC4A-4F7D-829B-832FEE2FF1B2",
            Type: "Text",
            Name: "functionParamsCityID",
            Label: "functionParamsCityID",
          },
          {
            ID: "14A6377F-5778-41D2-B29A-A9ED890D3112",
            Type: "Text",
            Name: "functionParamsQ",
            Label: "functionParamsQ",
          },
        ],
        getDefaultValues: async ({ firebaseUID }) => {
          const weather = await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/110`,
          })
          return {
            ...weather?.Config,
            functionParamsCityID: weather?.Config?.functionParams?.cityID,
            functionParamsQ: weather?.Config?.functionParams?.q,
          }
        },
        onSubmit: async (values,{ firebaseUID}) => {
          const weather = await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules`,
            values: {
              ID: "110",
              Config: {
                functionName: values.functionName,
                updateTime: values.updateTime,
                functionParams: {
                  cityID: values.functionParamsCityID,
                  q: values.functionParamsQ,
                },
              },
            },
          })
          return {
            ...weather?.Config,
            functionParamsCityID: weather?.Config?.functionParams?.cityID,
            functionParamsQ: weather?.Config?.functionParams?.q,
          }
        },
      }}
      ID={"config"}
    />
  )
}

export default withRouter(CityAirQuality)
