import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import React from "react"
import { withRouter } from "react-router-dom"
import CityCampaignForm from "./_tabs/CityCampaignForm"
import CityCampaignPromotionRelationTable from "./_tabs/CityCampaignPromotionRelationTable"
import CityCampaignTable from "./_tabs/CityCampaignTable"

const CityCampaigns = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <CityCampaignForm opened={opened} />,
        },
        {
          label: "Promotions",
          Component: opened => (
            <CityCampaignPromotionRelationTable opened={opened} />
          ),
          requiresID: true,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityCampaignTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityCampaigns)
