import TableModule from "components/Modules/Collections/TableModule"
import {
  createFirestoreDocument,
  getDataFromFirestoreCollection,
} from "helpers/Firestore"
import React from "react"

const CityCampaignTable = () => {
  const getColorForCampaign = val => {
    const now = new Date()

    const startTime = new Date(val.StartTime)
    const endTime = new Date(val.EndTime)

    if (now.getTime() < startTime.getTime()) return "#ee7454"

    if (now.getTime() > endTime.getTime()) return "#8173ab"

    return "#23d2ae"
  }

  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        const data = await getDataFromFirestoreCollection({
          path: `City/Business/Campaigns`,
          where: [
            {
              key: "City_ID",
              operation: "==",
              value: firebaseUID,
            },
          ],
          sortBy: { key: "Order" },
          timestampKeys: ["StartTime", "EndTime"],
        })

        return data.map(d => ({ ...d, Color: getColorForCampaign(d) }))
      }}
      openButtonIndex={1}
      noInitialSort
      columns={[
        {
          key: "Color",
          label: "",
          styling: { Type: "Color point" },
        },
        { key: "Title", label: "Title" },
        { key: "Order", label: "Order" },
      ]}
      searchKey={"Title"}
    />
  )
}

export default CityCampaignTable
