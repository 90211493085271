import React from "react"
import { AvGroup, AvFeedback } from "availity-reactstrap-validation"
import { InputGroup, CustomInput, Label } from "reactstrap"
import { connect } from "react-redux"

import { storage } from "../../../../../helpers/Firebase"
import NotificationAlert from "../../../../Common/Notifications/NotificationAlert"
import { defaultProcessImage } from "helpers/Functions"

const SingleImage = ({
  item,
  updateValue,
  value,
  firebaseUID,
  disabled,
  path,
}) => {
  const fullPath = `Clients/DataBase/${firebaseUID}/ClientData/${item.Path}`

  const ref = storage.ref().child(fullPath)

  const uploadFile = file => {
    const timeStamp = Date.now()

    const imageRef = ref.child(`${timeStamp}`)

    imageRef.put(file).then(
      async () => {
        await defaultProcessImage({
          path: `${fullPath}${timeStamp}`,
          width: item.ResizeWidth,
        })

        const downloadURL = await imageRef.getDownloadURL()

        updateValue(downloadURL)
      },
      () => {
        NotificationAlert({ type: "error", title: "Failed" })
      }
    )
  }

  return (
    <>
      <AvGroup className="error-t-negative error-l-150">
        <Label>{item.Label}</Label>
        <div className="align-items-center row">
          {value && <img src={value} className="col-2" alt="img" />}
          <InputGroup className={`mb-3 ${value ? "col-10" : "col-12"}`}>
            <CustomInput
              className="text-truncate"
              label={value}
              type="file"
              accept="image/*"
              id={`file_upload_custom_input_${item.ID}`}
              name={item.name}
              onChange={e => {
                uploadFile(e.target.files[0])
              }}
              disabled={disabled}
            />
          </InputGroup>
        </div>

        <AvFeedback>{item.Prompt}</AvFeedback>
      </AvGroup>
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  return { firebaseUID: client.ID }
}
export default connect(mapStateToProps, {})(SingleImage)
