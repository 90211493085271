import RelationTableModule from "components/Modules/Relations/RelationTableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"
import CityProblemsReportStatusesForm from "./_elements/CityProblemsReportStatusesForm"

const CityProblemsReportStatuses = ({ opened }) => {
  return (
    <RelationTableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          sortBy: { key: "Name" },
          path: `City/Cities/DataBase/${firebaseUID}/Modules/35/DataBase/${opened}/Statuses`,
        })
      }}
      OpenedComponent={(ID, onCancel) => (
        <CityProblemsReportStatusesForm
          opened={ID}
          parent={opened}
          onCancel={onCancel}
        />
      )}
      CreateComponent={onCancel => (
        <CityProblemsReportStatusesForm parent={opened} onCancel={onCancel} />
      )}
      columns={[{ key: "Name", label: "Name" }]}
      searchKey={"Name"}
    />
  )
}

export default CityProblemsReportStatuses
