import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import { withRouter } from "react-router-dom"
import CityAddressBookForm from "./_tabs/CityAddressBookForm"
import CityAddressBookTable from "./_tabs/CityAddressBookTable"

const CityAddressBook = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <CityAddressBookForm opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityAddressBookTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityAddressBook)
