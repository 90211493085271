import React from "react"
import { connect } from "react-redux"

import Text from "./FieldTypes/Text"
import RichText from "./FieldTypes/RichText"
import DateInput from "./FieldTypes/DateInput"
import Time from "./FieldTypes/Time"
import DateTime from "./FieldTypes/DateTime"
import WorkingHours from "./FieldTypes/WorkingHours"
import RadioButton from "./FieldTypes/RadioButton"
import CheckboxButton from "./FieldTypes/CheckboxButtons"
import Dropdown from "./FieldTypes/Dropdown"
import MultipleChoiceDropdown from "./FieldTypes/MultipleChoiceDropdown"
import Switch from "./FieldTypes/Switch"
import Gallery from "./FieldTypes/Gallery"
import SingleImage from "./FieldTypes/SingleImage"
import Audio from "./FieldTypes/Audio"
import CitySelector from "./FieldTypes/CitySelector"
import EditableTable from "./FieldTypes/EditableTable"
import DropdownCategories from "./FieldTypes/DropdownCategories"

const FieldPicker = ({
  item,
  updateStateValues,
  values,
  disabled,
  currentUser,
}) => {
  // field can have a Visible function defined to accept current form values and return a boolean if the field
  // shouldn't be invisible in that state
  // For example, a Dropdown can be hidden until the Dropdown above it is selected
  if (typeof item.Visible === "function" && item.Visible(values) === false) {
    return <div />
  }

  if (item.AdminOnly && currentUser.role > 3) return <div />

  // adds the current state values to callable functions to enable different results based on other values
  // For example, in a second dropdown return only the categories of the option selected in the first
  const _item = {
    ...item,
    GetSelectionOptions:
      typeof item.GetSelectionOptions === "function"
        ? async () => item.GetSelectionOptions(values)
        : undefined,
    CreateSelectionOption:
      typeof item.CreateSelectionOption === "function"
        ? async input => item.CreateSelectionOption(input, values)
        : undefined,
    DeleteSelectionOption:
      typeof item.DeleteSelectionOption === "function"
        ? async optionID => item.DeleteSelectionOption(optionID, values)
        : undefined,
    GetCategorySelectionOptions:
      typeof item.GetCategorySelectionOptions === "function"
        ? async parents => item.GetCategorySelectionOptions(parents, values)
        : undefined,
    CreateCategorySelectionOption:
      typeof item.CreateCategorySelectionOption === "function"
        ? async (input, parents) =>
            item.CreateCategorySelectionOption(input, parents, values)
        : undefined,
    DeleteCategorySelectionOption:
      typeof item.DeleteCategorySelectionOption === "function"
        ? async (optionID, parents) =>
            item.DeleteCategorySelectionOption(optionID, parents, values)
        : undefined,
  }

  const value = item.Name.split(".").reduce((o, i) => o?.[i], values)

  const updateValue = val => {
    if (typeof _item.updateValue === "function") {
      // a field can have an updateValue function defined to accept the current state values and the new field value
      // the function is called everytime the field is updated and can be used to change other fields in the form
      updateStateValues(_item.updateValue(values, val))
    } else {
      // Split the key into parts
      const keys = item.Name.split(".")

      // Recursive function to update the value
      function update(obj, keyParts, value) {
        // Get the current key part
        const currentKey = keyParts[0]

        // If this is the last key part, update the value
        if (keyParts.length === 1) {
          obj[currentKey] = value
          return
        }

        // If the next level does not exist, create an empty object
        if (!obj[currentKey]) {
          obj[currentKey] = {}
        }

        // Recurse into the next level of the object
        update(obj[currentKey], keyParts.slice(1), value)
      }

      // Create a deep copy of the oldValues to avoid modifying the original object
      const updatedValues = JSON.parse(JSON.stringify(values))

      // Update the value in the copied object
      update(updatedValues, keys, val)

      updateStateValues(updatedValues)
    }
  }

  const _disabled = disabled || _item.ReadOnly

  if (_item.Type === "Text") {
    return (
      <Text
        value={value || ""}
        item={_item}
        disabled={_disabled}
        updateValue={val => updateValue(val)}
      />
    )
  }

  if (_item.Type === "Rich Text Field") {
    return (
      <RichText
        item={_item}
        disabled={_disabled}
        updateValue={val => updateValue(val)}
        value={value || ""}
        path="Images/"
      />
    )
  }

  if (_item.Type === "Date") {
    return (
      <DateInput
        item={_item}
        updateValue={val => updateValue(val)}
        value={value ? new Date(value) : new Date()}
        disabled={_disabled}
      />
    )
  }

  if (_item.Type === "Time") {
    return (
      <Time
        item={_item}
        updateValue={val => updateValue(val)}
        value={value ? new Date(value) : new Date()}
        disabled={_disabled}
      />
    )
  }

  if (_item.Type === "DateTime") {
    return (
      <DateTime
        item={_item}
        updateValue={val => updateValue(val)}
        value={value ? new Date(value) : new Date()}
        disabled={_disabled}
      />
    )
  }

  if (_item.Type === "Working Hours") {
    return (
      <WorkingHours
        item={_item}
        updateValue={val => updateValue(val)}
        value={value || "unknown"}
        disabled={_disabled}
      />
    )
  }

  if (_item.Type === "Radio Button") {
    return (
      <RadioButton
        item={_item}
        disabled={_disabled}
        updateValue={val => updateValue(val)}
      />
    )
  }

  if (_item.Type === "Checkbox Buttons") {
    return (
      <CheckboxButton
        item={_item}
        disabled={_disabled}
        value={value || []}
        updateValue={newSelections => updateValue(newSelections)}
      />
    )
  }

  if (_item.Type === "Dropdown") {
    return (
      <Dropdown
        item={_item}
        disabled={_disabled}
        value={value}
        updateValue={val => updateValue(val)}
      />
    )
  }

  if (_item.Type === "Multiple Choice Dropdown") {
    return (
      <MultipleChoiceDropdown
        item={_item}
        disabled={_disabled}
        value={value || []}
        updateValue={newSelections => updateValue(newSelections)}
      />
    )
  }

  if (_item.Type === "Dropdown Categories") {
    return (
      <DropdownCategories
        item={_item}
        disabled={_disabled}
        value={value || []}
        updateValue={val => updateValue(val)}
      />
    )
  }

  if (_item.Type === "Switch") {
    return (
      <Switch
        item={_item}
        disabled={_disabled}
        value={value || false}
        updateValue={val => updateValue(val)}
      />
    )
  }

  if (_item.Type === "Gallery") {
    return (
      <Gallery
        item={_item}
        disabled={_disabled}
        value={value || []}
        updateValue={val => updateValue(val)}
      />
    )
  }

  if (_item.Type === "Single Image") {
    return (
      <SingleImage
        item={_item}
        disabled={_disabled}
        value={value}
        updateValue={val => updateValue(val)}
      />
    )
  }

  if (_item.Type === "Audio") {
    return (
      <Audio
        item={_item}
        disabled={_disabled}
        value={value}
        updateValue={val => updateValue(val)}
        path="EntityAudio/"
      />
    )
  }

  if (_item.Type === "City Selector") {
    return (
      <CitySelector
        item={_item}
        disabled={_disabled}
        value={value || []}
        updateValue={val => updateValue(val)}
      />
    )
  }

  if (_item.Type === "Editable Table") {
    return (
      <EditableTable
        item={_item}
        disabled={_disabled}
        value={value || []}
        values={values}
        updateValue={val => updateValue(val)}
        updateStateValues={val => updateStateValues(val)}
      />
    )
  }

  if (_item.Type === "Separator") {
    return <div className="separator" />
  }

  console.log(`False Field input ${_item.Type}`)
  return <div />
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth

  return { currentUser }
}

export default connect(mapStateToProps, {})(FieldPicker)
