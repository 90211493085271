import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import React from "react"
import { withRouter } from "react-router-dom"
import CitySportForm from "./_tabs/CitySportForm"
import CitySportTable from "./_tabs/CitySportTable"

const CitySport = ({ type, typeID }) => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => (
            <CitySportForm opened={opened} type={type} typeID={typeID} />
          ),
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CitySportTable type={type} />,
        },
      ]}
    />
  )
}

export default withRouter(CitySport)
