import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import { withRouter } from "react-router-dom"
import CityEmergencyCommunicationForm from "./_tabs/CityEmergencyCommunicationForm"
import CityEmergencyCommunicationTable from "./_tabs/CityEmergencyCommunicationTable"

const CityEmergencyCommunication = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => (
            <CityEmergencyCommunicationForm opened={opened} />
          ),
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityEmergencyCommunicationTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityEmergencyCommunication)
