import React, { Component } from "react"
import DropzoneComponent from "react-dropzone-component"
import { connect } from "react-redux"

import { storage } from "helpers/Firebase"
import { getFileFromURL } from "helpers/Utils"
import NotificationAlert from "components/Common/Notifications/NotificationAlert"
import { Label } from "reactstrap"
import { AvGroup } from "availity-reactstrap-validation"

const ReactDOMServer = require("react-dom/server")

const dropzoneComponentConfig = {
  postUrl: "no-url",
}

const dropzoneConfig = {
  thumbnailHeight: 160,
  uploadMultiple: true,
  autoProcessQueue: false,
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div className="dz-preview dz-file-preview mb-3">
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress />
          </div>
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#/" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
}

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  addDefault = async () => {
    const { value } = this.props

    value.forEach(async val => {
      await getFileFromURL(val, fileToAdd =>
        this.afterFileGotten(fileToAdd, val)
      )
    })
  }

  afterFileGotten = (blobToAdd, url) => {
    const file = new File([blobToAdd], url)

    this.blobToDataURL(blobToAdd, dataURL =>
      this.afterConvertedToDataURL(file, dataURL)
    )
  }

  blobToDataURL = (blob, callback) => {
    const fileReader = new FileReader()
    fileReader.onload = e => callback(e.target.result)
    fileReader.readAsDataURL(blob)
  }

  afterConvertedToDataURL = (file, dataURL) => {
    const fileToAdd = file
    fileToAdd.dataURL = dataURL
    fileToAdd.accepted = true

    this.dropzone.options.addedfile.call(this.dropzone, fileToAdd)
    this.dropzone.options.thumbnail.call(this.dropzone, fileToAdd, dataURL)
    this.dropzone.options.complete.call(this.dropzone, fileToAdd)
  }

  clear() {
    this.dropzone.removeAllFiles(true)
  }

  render() {
    const { firebaseUID, item, disabled, value, updateValue } = this.props

    const ref = storage
      .ref()
      .child(`Clients/DataBase/${firebaseUID}/ClientData/`)

    return (
      <AvGroup className="error-t-negative error-l-150">
        <Label>{item.Label}</Label>

        <DropzoneComponent
          config={dropzoneComponentConfig}
          djsConfig={dropzoneConfig}
          style={{ height: "0.8vh" }}
          isMulti
          eventHandlers={{
            init: dropzone => {
              this.dropzone = dropzone
              this.addDefault()
            },
            addedfile: file => {
              const timeStamp = Date.now()

              const imageRef = ref.child(`${timeStamp}`)

              imageRef.put(file).then(
                async () => {
                  const downloadURL = await imageRef.getDownloadURL()

                  this.dropzone.options.success.call(this.dropzone, file)
                  this.dropzone.options.complete.call(this.dropzone, file)

                  updateValue([...value, downloadURL])
                },
                () => {
                  NotificationAlert({ type: "error", title: "Failed" })
                }
              )
            },
            removedfile: async file => {
              updateValue(value.filter(x => x !== file.name))
            },
          }}
        />
      </AvGroup>
    )
  }
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  return { firebaseUID: client.ID }
}
export default connect(mapStateToProps, {})(Gallery)
