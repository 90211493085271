import RelationAddExistingWithFormModule from "components/Modules/Relations/RelationAddExistingWithFormModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import { useState } from "react"
import { connect } from "react-redux"
import PromotionForm from "../PromotionForm/PromotionForm"

const FirestorePromotions = ({ onCancel, parent, firebaseUID }) => {
  const [refresh, setRefresh] = useState(0)

  const [city, setCity] = useState(firebaseUID)
  const [category, setCategory] = useState()

  const [lastVisible, setLastVisible] = useState([])

  return (
    <div>
      {/* <div className="pl-3 pr-3 pt-3 row">
        <Dropdown
          className={"col-6"}
          noMargin
          item={{
            ID: "EDE95D97-B65D-4C8A-BD44-52063897643",
            Name: "City",
            Label: "City",
            Type: "Dropdown",
            GetSelectionOptions: async () => {
              const cities = await getDataFromFirestoreCollection({
                path: `City/Cities/DataBase`,
                sortBy: { key: "Name" },
              })
              return [...cities]
            },
          }}
          value={city}
          updateValue={cityID => {
            setCity(cityID)
            setRefresh(refresh + 1)
          }}
        />

        <Dropdown
          className={"col-6"}
          noMargin
          item={{
            ID: "EDE95D97-B65D-4C8A-BD44-520635353897643",
            Name: "Category",
            Label: "Category",
            Type: "Dropdown",
            GetSelectionOptions: async () => {
              const categories = await getDataFromFirestoreCollection({
                path: `City/Business/PartnerCategories`,
                sortBy: { key: "Name" },
              })
              return [...categories]
            },
            CreateSelectionOption: async (input, values, firebaseUID) => {
              return await createFirestoreDocument({
                path: `City/Business/PartnerCategories`,
                values: { Name: input, ID: input },
              })
            },
            DeleteSelectionOption: async (optionID, values, firebaseUID) => {
              return await deleteFirestoreDocument({
                path: `City/Business/PartnerCategories/${optionID}`,
              })
            },
          }}
          value={category}
          updateValue={categoryID => {
            setCategory(categoryID)
            setRefresh(refresh + 1)
          }}
        />
      </div> */}

      <RelationAddExistingWithFormModule
        getData={async params => {
          const where = [
            {
              key: "Cities",
              operation: "array-contains",
              value: city,
            },
          ]

          if (category)
            where.push({
              key: "Category",
              operation: "==",
              value: category,
            })

          const data = await getDataFromFirestoreCollection({
            path: "City/Business/Promotions",
            limit: params.entriesPerPage + 1,
            startAfter: params.page !== 0 && lastVisible[params.page - 1].Title,
            sortBy: { key: "Title" },
            where,
          })

          setLastVisible([
            ...lastVisible.slice(0, params.page),
            data[data.length - 1],
          ])

          return data
        }}
        getRelations={async () => {
          // const relations = await getDataFromFirestoreCollection({
          //   path: "City/Business/Promotions",
          //   where: [
          //     {
          //       key: "Campaign_ID",
          //       operation: "==",
          //       value: parent,
          //     },
          //   ],
          // })

          // return relations.map(i => i.Partner_ID)

          return []
        }}
        noInitialSort
        columns={[{ key: "Title", label: "Title" }]}
        noCreateNew
        refresh={refresh}
        shouldQueryDataInParent={true}
        FormComponent={(values, onFormCancel) => (
          <PromotionForm
            parent={parent}
            defaultValues={{
              City_ID: firebaseUID,
              ActionButtons: values.ActionButtons,
              Description: values.Description,
              SubTitle: values.SubTitle,
              Title: values.Title,
              Type: values.Type,
              HeaderImage: values.HeaderImage,
              IconImage: values.IconImage,
              BannerImage: values.BannerImage,
              Status: "Active",
              Partner_ID: values.ID,
            }}
            onCancel={onFormCancel}
          />
        )}
      />
    </div>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  console.log(client)

  return { firebaseUID: client.ID }
}
export default connect(mapStateToProps, {})(FirestorePromotions)
