import React from "react"
import { withRouter } from "react-router"

import Controler from "../../../../components/Admin/AccountManager/Editor/Controler"
import Modules from "./Tabs/Modules"
import CityForm from "./Tabs/CityForm"
import CityInfo from "./Tabs/CityInfo"

const Editor = ({ history, location }) => {
  const params = new URLSearchParams(location.search)

  const ID = params.get("ID")

  return (
    <Controler
      history={history}
      location={location}
      ID={ID}
      form={() => <CityForm ID={ID} history={history} deleteEnabled={ID} />}
      tabs={[
        {
          name: "city-info",
          label: "client-editor.city-info",
          Component: () => <CityInfo clientID={ID} />,
        },
        {
          name: "modules",
          label: "client-editor.modules",
          Component: () => <Modules clientID={ID} />,
        },
      ]}
    />
  )
}

export default withRouter(Editor)
