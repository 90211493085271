import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import ApplicationSpecificConfig from "applications/ApplicationSpecificConfig"
import { applicationSubdomain } from "constants/defaultValues/defaultValues"
import { withTranslation } from "react-i18next"
import styles from "../../constants/styles"
import SidebarContent from "./SidebarContent"

const Sidebar = ({}) => {
  const style = ApplicationSpecificConfig[applicationSubdomain].style

  return (
    <React.Fragment>
      <div className={`vertical-menu`}>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        {style && styles[style] && styles[style].images.navBottom && (
          <div className="sidebar-bottom-image">
            <span className="sidebar-bottom-lg w-100">
              <img
                src={styles[style].images.navBottom}
                className="w-100"
                alt=""
              />
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({}) => {
  return {}
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
