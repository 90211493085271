import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import React from "react"
import { withRouter } from "react-router-dom"
import CityPartnersForm from "./_tabs/CityPartnersForm"
import CityPartnersTable from "./_tabs/CityPartnersTable"

const CityPartners = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <CityPartnersForm opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityPartnersTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityPartners)
