import CitySelectionModule from "components/Modules/Documents/CitySelectionModule"
import {
  createOrUpdateFirestoreDocument,
  getFirestoreDocument,
} from "helpers/Firestore"
import React from "react"

const CityVisibilityCitySelection = ({ opened }) => {
  return (
    <CitySelectionModule
      getDefaultValues={async ({ firebaseUID }) => {
        if (!opened) return {}

        const data = await getFirestoreDocument({
          path: `City/Modules/DataBase/107/DataBase/${opened}`,
        })
        return data.Client_ID
      }}
      onSubmit={async (values,{ firebaseUID}) => {
        const Filter_Client_ID = {}

        values.forEach(val => (Filter_Client_ID[val] = true))
        return await createOrUpdateFirestoreDocument({
          path: `City/Modules/DataBase/107/DataBase`,
          values: {
            ID: opened,
            Client_ID: values,
            Filter_Client_ID,
          },
        })
      }}
      ID={opened}
    />
  )
}

export default CityVisibilityCitySelection
