import { AvGroup } from "availity-reactstrap-validation"
import React, { Component } from "react"
import { Button, Label, Table } from "reactstrap"
import Editable from "react-x-editable"
import { randomFirestoreID, swapPositionsInArray } from "helpers/Utils"

class EditableTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  updateCellValue(ID, Name, newValue) {
    const { updateValue, value } = this.props

    const item = value.find(x => x.ID === ID)

    const keys = Name.split(".")

    function update(obj, keyParts, newValue) {
      const currentKey = keyParts[0]

      if (keyParts.length === 1) {
        obj[currentKey] = newValue
        return
      }

      if (!obj[currentKey]) {
        obj[currentKey] = {}
      }

      update(obj[currentKey], keyParts.slice(1), newValue)
    }

    const updatedValues = JSON.parse(JSON.stringify(item))

    update(updatedValues, keys, newValue)

    updateValue(value.map(x => (x.ID === ID ? updatedValues : x)))
  }

  addValue() {
    const { updateValue, value, item } = this.props

    const newValue = {}

    item.Columns.forEach(column => {
      newValue[column.Name] = column.DefaultValue || ""
    })

    newValue.ID = `NEW_${randomFirestoreID()}`

    updateValue([...value, newValue])
  }

  render() {
    const { item, value, values, updateStateValues, updateValue, disabled } =
      this.props

    return (
      <AvGroup className="error-t-negative error-l-150">
        <Label>{item.Label}</Label>
        {item.Creatable && (
          <Button
            color="none"
            className="float-right mb-2"
            onClick={() => this.addValue()}
          >
            Add
          </Button>
        )}

        <div className="table-responsive mb-2">
          <Table className="table table-striped table-sm mb-0">
            <thead>
              <tr>
                {[
                  ...item.Columns.map(column => (
                    <th key={`heading_${column.Name}`}>{column.Label}</th>
                  )),
                  item.Orderable && <th key={`heading_order`}>Order</th>,
                  item.DeletedName && <th key={`heading_delete`}>Delete</th>,
                ]}
              </tr>
            </thead>
            <tbody>
              {value?.map((i, index) => (
                <tr key={`row_${i.ID}`}>
                  {[
                    ...item.Columns.map((column, index) => (
                      <td key={`${column.Name}_${i[column.Name]}_${i.ID}`}>
                        <Editable
                          name={column.Name}
                          value={column.Name.split(".").reduce(
                            (o, i) => o?.[i],
                            i
                          )}
                          dataType={column.Type || "text"}
                          showButtons={true}
                          options={column.Options?.filter(
                            x => x.ID !== "select"
                          )}
                          handleSubmit={e => {
                            this.updateCellValue(i.ID, column.Name, e.newValue)
                          }}
                          disabled={disabled || !column.Editable}
                        />
                      </td>
                    )),
                    item.Orderable && (
                      <td key={`${i.ID}_orderable`}>
                        <button
                          className="btn text-only-button btn-xs"
                          type="button"
                          disabled={disabled || index === 0}
                          onClick={() => {
                            updateValue(
                              swapPositionsInArray(value, index - 1, index)
                            )
                          }}
                        >
                          <i className="glyph-icon simple-icon-arrow-up " />
                        </button>
                        <button
                          className="btn text-only-button btn-xs"
                          type="button"
                          disabled={disabled || index === value.length - 1}
                          onClick={() => {
                            updateValue(
                              swapPositionsInArray(value, index + 1, index)
                            )
                          }}
                        >
                          <i className="glyph-icon simple-icon-arrow-down " />
                        </button>
                      </td>
                    ),
                    item.DeletedName && (
                      <td key={`${i.ID}_delete`}>
                        <button
                          className="btn btn-danger btn-xs"
                          disabled={disabled}
                          onClick={() => {
                            updateStateValues({
                              ...values,
                              [item.Name]: value.filter(x => x.ID !== i.ID),
                              [item.DeletedName]: [
                                ...(values[item.DeletedName] || []),
                                i.ID,
                              ],
                            })
                          }}
                        >
                          <i className="glyph-icon simple-icon-trash " />
                        </button>
                      </td>
                    ),
                  ]}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </AvGroup>
    )
  }
}

export default EditableTable
