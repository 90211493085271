import { adminRoot } from "constants/defaultValues/defaultValues"

import CitiesManagerCreator from "./cities/creator"
import CitiesManagerEditor from "./cities/editor"
import CitiesManager from "./cities/list"

export default [
  {
    path: `${adminRoot}manager/cities/new`,
    component: CitiesManagerCreator,
  },

  {
    path: `${adminRoot}manager/cities/edit`,
    component: CitiesManagerEditor,
  },

  {
    path: `${adminRoot}manager/cities`,
    component: CitiesManager,
  },
]
