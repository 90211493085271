import { AvForm } from "availity-reactstrap-validation"
import NotificationAlert from "components/Common/Notifications/NotificationAlert"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Button } from "reactstrap"

import ButtonWithWarning from "components/Common/UI/ButtonWithWarning"

import FieldPicker from "./Fields/FieldPicker"

// Form that expects a form object with:
// title - to be displayed at the top of the form
// fields - list of fields to be rendered with the FieldPicker
// onSubmit - function to be triggered with the values submitted
// getDefaultValues - function to get default values for the entry
// also expects an onCancel function, ID of the opened item and it can be disabled as a whole
const FormControler = ({
  form,
  deleteOnBottom,
  ID,
  disabled,
  onCancel,
  onCreate,
  t,
}) => {
  const [loading, setLoading] = useState(true)
  const [stateValues, setStateValues] = useState({})
  const [working, setWorking] = useState(false)

  useEffect(() => {
    setTimeout(async () => {
      if (typeof form.getDefaultValues === "function") {
        const values = await form.getDefaultValues()

        setStateValues({ ...values, ID: ID })
        setLoading(false)
      } else {
        setLoading(false)
      }
    }, 100)
  }, [ID])

  const onFormSubmit = async (event, _errors, formValues) => {
    const values = { ...stateValues, ...formValues }

    const errors = form.fields
      .filter(field => {
        if (field.Required) {
          const value = field.Name.split(".").reduce((o, i) => o[i], values)

          if (value === undefined || value === null || value === "") {
            console.log("value", value, field.Label)
            console.log("values", values)
            console.log("field", field.Name)
            return true
          }
        }

        return false
      })
      .map(f => f.Label)

    if (errors.length === 0) {
      setWorking(true)
      const mergedValues = stateValues

      const newValues = await form.onSubmit(mergedValues)

      if (!mergedValues.ID && typeof onCreate === "function")
        onCreate(newValues.ID)

      NotificationAlert({
        type: "success",
        title: "",
        message: "Success",
        expiration: 3000,
      })

      // setStateValues(newValues)
      setWorking(false)
    } else {
      let errorMessage = "Missing: " + errors.join(", ")

      NotificationAlert({
        type: "warning",
        title: "",
        message: errorMessage,
        expiration: 3000,
      })
    }
  }

  if (loading) return <div className="loading" />

  return (
    <>
      <AvForm
        className="av-tooltip tooltip-label-right p-3 "
        onSubmit={(event, errors, values) =>
          onFormSubmit(event, errors, values)
        }
        model={stateValues}
      >
        {ID && form.onDelete && !deleteOnBottom && (
          <ButtonWithWarning
            color="danger"
            onClick={async () => {
              await form.onDelete()
              if (onCancel) onCancel()
            }}
            className="float-right mb-2"
          >
            {t("form.delete")}
          </ButtonWithWarning>
        )}

        <h4>{form.title}</h4>

        {form.fields.map(field => (
          <div key={field.ID}>
            <FieldPicker
              item={{
                ...field,
              }}
              updateStateValues={val => setStateValues(val)}
              values={stateValues}
              disabled={disabled}
            />
          </div>
        ))}

        <>
          {onCancel && (
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                onCancel()
              }}
            >
              {t("form.cancel")}
            </Button>
          )}

          <Button
            color="primary"
            className="float-right"
            disabled={working || disabled}
          >
            {t("form.submit")}
          </Button>

          {ID && form.onDelete && deleteOnBottom && (
            <ButtonWithWarning
              color="danger"
              onClick={async () => {
                await form.onDelete()
                if (onCancel) onCancel()
              }}
              className="float-right mb-2 mr-2"
            >
              {t("form.delete")}
            </ButtonWithWarning>
          )}
        </>
      </AvForm>
    </>
  )
}

export default withTranslation()(FormControler)
