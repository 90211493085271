import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Redirect, Route } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isClientProtected,
  currentUser,
  loading,
  roles,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (loading) return <div />

        if (isAuthProtected && !currentUser) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        if (roles && !roles.includes(currentUser.role)) {
          return (
            <Redirect
              to={{ pathname: "/401", state: { from: props.location } }}
            />
          )
        }

        if (Layout)
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )

        return <Component {...props} />
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser, loading } = Auth

  return { currentUser, loading }
}
export default connect(mapStateToProps, null)(Authmiddleware)
