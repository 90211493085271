import FormModule from "components/Modules/Documents/FormModule"
import {
  createFirestoreDocument,
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  getDataFromFirestoreCollection,
  getFirestoreDocument,
} from "helpers/Firestore"

const CityAddressBookForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Address Book",
        fields: [
          {
            ID: "1",
            Name: "name",
            Label: "Name",
            Type: "Text",
            Required: true,
            Prompt: "Name is required",
          },
          {
            ID: "2",
            Name: "description",
            Label: "Description",
            Type: "Rich Text Field",
          },
          {
            ID: "3",
            Name: "address",
            Label: "Address",
            Type: "Text",
          },
          {
            ID: "4",
            Name: "phoneNumber",
            Label: "Phone Number",
            Type: "Text",
          },
          {
            ID: "5",
            Name: "email",
            Label: "Email",
            Type: "Text",
          },
          {
            ID: "6",
            Name: "website",
            Label: "Website",
            Type: "Text",
          },
          {
            ID: "7",
            Name: "facebook",
            Label: "Facebook",
            Type: "Text",
          },
          {
            ID: "8",
            Name: "twitter",
            Label: "Twitter",
            Type: "Text",
          },
          {
            ID: "9",
            Name: "instagram",
            Label: "Instagram",
            Type: "Text",
          },
          {
            ID: "10",
            Name: "yelp",
            Label: "Yelp",
            Type: "Text",
          },
          {
            ID: "11",
            Name: "workingHours",
            Label: "Working Hours",
            Type: "Working Hours",
          },
          {
            ID: "12",
            Name: "workingHoursNote",
            Label: "Working Hours Note",
            Type: "Text",
          },
          {
            ID: "13",
            Type: "Multiple Choice Dropdown",
            Name: "categories",
            Label: "Categories",
            GetSelectionOptions: async (values, { firebaseUID }) => {
              return await getDataFromFirestoreCollection({
                path: `City/Cities/DataBase/${firebaseUID}/Modules/133/Categories`,
              })
            },
            CreateSelectionOption: async (input, values, firebaseUID) => {
              return await createFirestoreDocument({
                path: `City/Cities/DataBase/${firebaseUID}/Modules/133/Categories`,
                values: { Name: input, ID: input },
              })
            },
            DeleteSelectionOption: async (optionID, values, firebaseUID) => {
              return await deleteFirestoreDocument({
                path: `City/Cities/DataBase/${firebaseUID}/Modules/133/Categories/${optionID}`,
              })
            },
          },
          {
            ID: "14",
            Name: "image",
            Label: "Image",
            Type: "Single Image",
            Path: "AddressBook/",
          },
          {
            ID: "15",
            Name: "geolocation",
            Label: "Geolocation (lat, lng)",
            Type: "Text",
          },
        ],
        fieldsToTranslate: ["name", "description", "workingHoursNote"],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          const data = await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/133/DataBase/${opened}`,
          })

          return {
            ...data,
            categories:
              data.categories?.map(item => ({
                ID: item,
                Name: item,
              })) || [],
          }
        },
        onSubmit: async (values, { firebaseUID }) => {
          const _values = { ...values }

          _values.categories = _values.categories?.map(item => item.ID) || []

          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/133/DataBase`,
            values: _values,
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/133/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityAddressBookForm
