import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
} from "reactstrap"

import { pageSizeOptions } from "constants/defaultValues/defaultValues"

import DynamicTable from "./DynamicTable"

// wrapper around the DynamicTable
// handles things like filters, pagination, performing getCalls etc. for the DynamicTable component
const TableControler = ({
  getData,
  noInitialSort,
  topButtons,
  openButton,
  actionButtons,
  columns,
  shouldUpdate, // parent components can change this prop to force update
  noSearch,
  t,
}) => {
  const [data, setData] = useState([])

  const [loading, setLoading] = useState(true)

  const [currentPage, setCurrentPage] = useState(0)
  const [entriesPerPage, setEntriesPerPage] = useState(50)
  const [canPrevious, setCanPrevious] = useState(currentPage > 0)
  const [canNext, setCanNext] = useState(false)
  const [sortBy, setSortBy] = useState(
    !noInitialSort && { key: columns[0].key, inverse: false }
  )
  const [searchKeyword, setSearchKeyword] = useState()

  // update when search filter is entered or entriesPerPage are changed or sortBy
  useEffect(() => {
    changePage(0)
  }, [searchKeyword, entriesPerPage, sortBy, shouldUpdate])

  // changes page and canNext and canPrevious
  const changePage = async page => {
    const params = { page, entriesPerPage }
    if (sortBy) params.sortBy = sortBy
    if (searchKeyword) params.searchKeyword = searchKeyword

    const newData = await getData(params)

    setData(newData.slice(0, entriesPerPage))
    setCurrentPage(page)
    setCanPrevious(page > 0)
    setCanNext(newData.length > entriesPerPage)
    setLoading(false)
  }

  // changes entries per page, if canNext should be allowed and if the page should be changed
  const changeEntriesPerPage = size => {
    setEntriesPerPage(size)
  }

  // sorts data
  const setSort = key => {
    const inverse = sortBy?.key === key && !sortBy?.inverse

    setSortBy({ key, inverse })
  }

  if (loading) return <div className="loading" />

  return (
    <div className="p-3 pb-0">
      <>
        <div className="d-flex justify-content-between mb-2">
          {!noSearch && (
            <div className="search-sm d-inline-block float-md-left align-top mt-2 mb-2">
              <input
                type="text"
                name="keyword"
                id="search"
                placeholder={t("Search")}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    setSearchKeyword(e.target.value)
                  }
                }}
              />
            </div>
          )}

          <div className="d-flex justify-content-end">
            {topButtons?.map(topButton => (
              <div key={topButton.label} className="ml-2">
                {topButton.show && (
                  <Button
                    color="primary"
                    onClick={() => topButton.onClick()}
                    disabled={topButton.disabled}
                  >
                    {topButton.label}
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>

        <DynamicTable
          data={data}
          columns={columns}
          sortBy={sortBy}
          setSort={k => {
            setSort(k)
          }}
          openButton={openButton}
          actionButtons={actionButtons}
        />

        <div className="d-inline-block pt-2">
          <Pagination
            className="d-flex align-items-center"
            size="sm"
            listClassName="justify-content-center m-0"
            aria-label="Pagination"
          >
            <PaginationItem className={`${canPrevious ? "" : "disabled"}`}>
              <PaginationLink
                className="prev"
                onClick={() => {
                  if (!canPrevious) return
                  changePage(currentPage - 1)
                }}
                disabled={!canPrevious}
                style={canPrevious ? { color: "#e73c73" } : {}}
              >
                <i className="simple-icon-arrow-left" />
              </PaginationLink>
            </PaginationItem>

            <PaginationItem active={true}>
              <PaginationLink onClick={() => changePage(currentPage)}>
                {currentPage + 1}
              </PaginationLink>
            </PaginationItem>

            <PaginationItem className={`${canNext ? "" : "disabled"}`}>
              <PaginationLink
                className="next"
                onClick={() => {
                  if (!canNext) return
                  changePage(currentPage + 1)
                }}
                disabled={!canNext}
                style={canNext ? { color: "#e73c73" } : {}}
              >
                <i className="simple-icon-arrow-right" />
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>

        <div className="float-right pt-2">
          <span className="text-muted text-small mr-1">Items </span>
          <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle caret color="outline-primary" size="xs">
              {entriesPerPage}
            </DropdownToggle>
            <DropdownMenu right>
              {pageSizeOptions.map(size => {
                return (
                  <DropdownItem
                    key={size}
                    onClick={() => {
                      changeEntriesPerPage(size)
                    }}
                  >
                    {size}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </>
    </div>
  )
}

export default withTranslation()(React.memo(TableControler))
