import { adminRoot } from "constants/defaultValues/defaultValues"
import { ThemeColors } from "helpers/ThemeColors"

export const makeAccountEditorMenu = () => {
  return [
    {
      id: "content-editor-cities",
      label: "menu.account-editor-cities",
      to: `${adminRoot}editor/cities`,
      icon: "iconsminds-the-white-house",
    },
    {
      id: "content-editor-tools",
      label: "menu.account-editor-tools",
      icon: "simple-icon-wrench",
      to: `${adminRoot}editor/tools`,
    },
  ]
}

export const makeAccountManagerMenu = () => {
  const colors = ThemeColors()

  return [
    {
      id: "accounts-manager-cities",
      label: "menu.account-editor-cities",
      to: `${adminRoot}manager/cities`,
      icon: "iconsminds-the-white-house",
      subs: [
        {
          id: "accounts-manager-view-and-edit-cities",
          label: "menu.accounts-manager-view-and-edit-cities",
          icon: "iconsminds-the-white-house",
          to: `${adminRoot}manager/cities`,
        },
        {
          id: "accounts-manager-add-new-city",
          label: "menu.accounts-manager-add-new",
          to: `${adminRoot}manager/cities/new`,
          icon: "simple-icon-note",
          color: colors.primary,
        },
      ],
    },
  ]
}
