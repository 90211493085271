import TableControler from "components/ModuleElements/Table/TableControler"
import { queryDataByParameters } from "helpers/Utils"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Modal } from "reactstrap"

// a quick way to handle the TableControler Component for use with relation tables like Parents/Child
const RelationTableModule = ({
  getData,
  searchKey,
  columns,
  deleteRelation, // add if you want to have a column with an action to remove each relation
  OpenedComponent, // add if you want to have the capability of opening each entry
  CreateComponent, // add if you want to have the capability of creating a new entry
  AddExistingComponent, // add if you want to have the caability of adding an existing entry
  clientID,
  firebaseUID,
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const [opened, setOpened] = useState()
  const [createModal, setCreateModal] = useState(false)
  const [addExistingModal, setAddExistingModal] = useState(false)

  const [shouldUpdate, setShouldUpdate] = useState(0)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const _data = await getData({ firebaseUID })
    setData(_data)
    setShouldUpdate(shouldUpdate + 1)
    setLoading(false)
  }

  const queryData = async params => {
    const _params = { ...params }
    if (params.searchKeyword) {
      _params.searchBy = { key: searchKey, value: params.searchKeyword }
    }
    return queryDataByParameters(data, _params)
  }

  if (loading) return <div className="loading" />

  return (
    <>
      <Modal
        size="lg"
        isOpen={opened !== undefined}
        toggle={() => {
          setOpened(undefined)
          loadData()
        }}
      >
        {opened !== undefined &&
          OpenedComponent(opened, () => {
            setOpened(undefined)
            loadData()
          })}
      </Modal>

      <Modal
        size="lg"
        isOpen={createModal}
        toggle={() => {
          setCreateModal(!createModal)
          loadData()
        }}
      >
        {createModal &&
          CreateComponent(() => {
            setCreateModal(false)
            loadData()
          })}
      </Modal>

      <Modal
        size="lg"
        isOpen={addExistingModal}
        toggle={() => {
          setAddExistingModal(!addExistingModal)
          loadData()
        }}
      >
        {addExistingModal &&
          AddExistingComponent(() => {
            setAddExistingModal(false)
            loadData()
          })}
      </Modal>

      <TableControler
        getData={async params => await queryData(params)}
        shouldUpdate={shouldUpdate}
        topButtons={[
          {
            label: "Add existing",
            show: AddExistingComponent !== undefined,
            onClick: () => setAddExistingModal(true),
          },
          {
            label: "Create new",
            show: CreateComponent !== undefined,
            onClick: () => setCreateModal(true),
          },
        ]}
        openButton={{
          show: OpenedComponent !== undefined,
          disabled: false,
          onClick: ID => setOpened(ID),
          index: 0,
        }}
        actionButtons={
          deleteRelation
            ? [
                {
                  ID: "rowuinsfcr4u2",
                  title: "Relations",
                  buttonTitle: "Remove",
                  function: async x => {
                    await deleteRelation({ firebaseUID }, x)
                    await loadData()
                  },
                },
              ]
            : []
        }
        columns={columns}
      />
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  return { firebaseUID: client.ID }
}
export default connect(mapStateToProps, {})(withRouter(RelationTableModule))
