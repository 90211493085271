import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import React from "react"
import NewsCatcherForm from "./_tabs/NewsCatcherForm"
import NewsCatcherTable from "./_tabs/NewsCatcherTable"

const NewsCatcher = () => {
  return (
    <div className="page-content">
      <TabModulesTemplate
        documentTabs={[
          {
            label: "Form",
            Component: opened => <NewsCatcherForm opened={opened} />,
          },
        ]}
        collectionTabs={[
          {
            label: "Table",
            Component: () => <NewsCatcherTable />,
          },
        ]}
      />
    </div>
  )
}

export default NewsCatcher
