import { supportedLanguages } from "applications/city/helpers/defaultValues"
import FormControler from "components/ModuleElements/Form/FormControler"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const FormModule = ({
  form,
  ID,
  location,
  history,
  firebaseUID,
  noCancel,
  languages,
  defaultLanguage,
}) => {
  const onCancel = () => {
    history.push(
      `${location.pathname}${location.search}`
        .split("?opened=")[0]
        .split("&opened=")[0]
    )
  }

  const onCreate = newID => {
    history.push(
      `${
        `${location.pathname}${location.search}`
          .split("?opened=")[0]
          .split("&opened=")[0]
      }${"?"}opened=${newID}`
    )
  }

  const fields = []

  form.fields.forEach(x => {
    const field = {
      ...x,
      GetSelectionOptions:
        typeof x.GetSelectionOptions === "function"
          ? async stateValues =>
              x.GetSelectionOptions(stateValues, { firebaseUID })
          : undefined,
      CreateSelectionOption:
        typeof x.CreateSelectionOption === "function"
          ? async (input, stateValues) =>
              x.CreateSelectionOption(input, stateValues, {
                firebaseUID,
              })
          : undefined,
      DeleteSelectionOption:
        typeof x.DeleteSelectionOption === "function"
          ? async (optionID, stateValues) =>
              x.DeleteSelectionOption(optionID, stateValues, {
                firebaseUID,
              })
          : undefined,
      GetCategorySelectionOptions:
        typeof x.GetCategorySelectionOptions === "function"
          ? async (parents, stateValues) =>
              x.GetCategorySelectionOptions(parents, stateValues, {
                firebaseUID,
              })
          : undefined,
      CreateCategorySelectionOption:
        typeof x.CreateCategorySelectionOption === "function"
          ? async (input, parents, stateValues) =>
              x.CreateCategorySelectionOption(input, parents, stateValues, {
                firebaseUID,
              })
          : undefined,
      DeleteCategorySelectionOption:
        typeof x.DeleteCategorySelectionOption === "function"
          ? async (optionID, parents, stateValues) =>
              x.DeleteCategorySelectionOption(optionID, parents, stateValues, {
                firebaseUID,
              })
          : undefined,
    }

    fields.push(field)

    if (form.fieldsToTranslate?.includes(x.Name)) {
      languages.forEach(lang => {
        if (lang.ID === defaultLanguage) {
          return
        }

        fields.push({
          ...field,
          Name: `Translate.${lang.ID}.${x.Name}`,
          Label: `${x.Label} (${lang.Name})`,
          ID: `${x.ID}-${lang.ID}`,
        })
      })
    }
  })

  return (
    <FormControler
      form={{
        ...form,
        fields,
        getDefaultValues: async () => {
          return await form.getDefaultValues({ firebaseUID, defaultLanguage })
        },
        onSubmit: async values => await form.onSubmit(values, { firebaseUID }),
        onDelete:
          typeof form.onDelete === "function"
            ? async () => {
                await form.onDelete({ firebaseUID })
              }
            : undefined,
      }}
      ID={ID}
      onCancel={
        noCancel
          ? undefined
          : () => {
              onCancel()
            }
      }
      onCreate={newID => {
        onCreate(newID)
      }}
    />
  )
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  const clientLanguages = client?.MetaData?.SupportedLanguages || []

  return {
    firebaseUID: client.ID,
    languages: supportedLanguages.filter(x => clientLanguages.includes(x.ID)),
    defaultLanguage: client?.MetaData?.DefaultLanguage,
  }
}
export default connect(mapStateToProps, {})(withRouter(FormModule))
