import { firestore, firestoreObject } from "./Firebase"

//

// gets data from firestore collection based on parameters
export const getDataFromFirestoreCollection = async params => {
  const {
    path,
    startAfter,
    limit,
    sortBy,
    searchBy,
    where,
    keys,
    asMap,
    timestampKeys,
  } = params

  let ref = firestore.collection(path)

  if (where) {
    where.forEach(w => {
      ref = ref.where(w.key, w.operation, w.value)
    })
  }

  if (searchBy) {
    ref = ref
      .where(searchBy.key, ">=", searchBy.value)
      .where(searchBy.key, "<=", searchBy.value + "\uf8ff")
  }

  if (sortBy) {
    ref = ref.orderBy(sortBy.key, sortBy.inverse ? "desc" : "asc")
  }

  if (limit) {
    ref = ref.limit(limit)
  }

  if (startAfter) {
    ref = ref.startAfter(startAfter)
  }

  if (asMap) {
    let ret = {}

    await ref.get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        ret[doc.id] = { ID: doc.id, ...doc.data() }
      })
    })

    if (keys) {
      ret = Object.keys(ret).map(x => {
        const r = {}

        keys.forEach(k => {
          r[k] = ret[x][k]
        })

        ret[x] = r
      })
    }

    return ret
  }

  let ret = []

  await ref.get().then(querySnapshot => {
    querySnapshot.forEach(doc => ret.push({ ID: doc.id, ...doc.data() }))
  })

  if (keys) {
    ret = ret.map(x => {
      const r = {}

      keys.forEach(k => {
        r[k] = x[k]
      })

      return r
    })
  }

  if (timestampKeys) {
    ret = ret.map(x => {
      const r = { ...x }

      timestampKeys.forEach(timestampKey => {
        r[timestampKey] =
          r[timestampKey] && new Date(0).setUTCSeconds(r[timestampKey].seconds)
      })

      return r
    })
  }

  return ret
}

// gets data from firestore document
export const getFirestoreDocument = async params => {
  let ret

  await firestore
    .doc(params.path)
    .get()
    .then(snapshot => {
      ret = { ...snapshot.data(), ID: snapshot.id }
    })

  // processes each key from timestamp to date string format
  if (params.timestampKeys) {
    params.timestampKeys.forEach(timestampKey => {
      ret[timestampKey] =
        ret[timestampKey] &&
        new Date(0).setUTCSeconds(ret[timestampKey].seconds)
    })
  }

  return ret
}

// deletes firestore document
export const deleteFirestoreDocument = async params => {
  await firestore.doc(params.path).delete()
}

// updates or creates firestore document and returns new values
// path needs to be passed without the document ID
// if values have an ID field, updates the document, otherwise it creates a new one
export const createOrUpdateFirestoreDocument = async params => {
  const { values, path, timestampKeys } = params

  const _values = {}

  // firestore can't handle undefined fields so they have to be removed
  Object.keys(values).forEach(k => {
    if (values[k] !== undefined) {
      if (timestampKeys && timestampKeys.includes(k))
        _values[k] = firestoreObject.Timestamp.fromDate(new Date(values[k]))
      else _values[k] = values[k]
    }
  })

  let ret
  if (_values.ID) {
    const query = await firestore.doc(`${path}/${_values.ID}`).update(_values)
    ret = { ..._values }
  } else {
    const query = await firestore.collection(path).add(_values)
    ret = { ..._values, ID: query.id }
  }

  return ret
}

// creates firestore document and returns new values
// path needs to be passed without the document ID
// if values have an ID field, creates the document with that ID, otherwise it creates a new one
export const createFirestoreDocument = async params => {
  const { values, path } = params

  const _values = {}

  // firestore can't handle undefined fields so they have to be removed
  Object.keys(values).forEach(k => {
    if (values[k] !== undefined) _values[k] = values[k]
  })

  let ret
  if (_values.ID) {
    const query = await firestore.doc(`${path}/${_values.ID}`).set(_values)
    ret = { ..._values }
  } else {
    const query = await firestore.collection(path).add(_values)
    ret = { ..._values, ID: query.id }
  }

  return ret
}

// executes a firestore batch query with the gotten array of data
export const firestoreBatchCommit = async params => {
  const { commits } = params

  const batch = firestore.batch()

  const ret = { newValues: [] }

  commits.forEach(commit => {
    const _values = {}

    // firestore can't handle undefined fields so they have to be removed
    Object.keys({ ...commit.values }).forEach(k => {
      if (commit.values[k] !== undefined) _values[k] = commit.values[k]
    })

    switch (commit.type) {
      case "add":
        const addRef = firestore.collection(commit.path).doc()
        batch.set(addRef, _values)
        ret.newValues.push({ ..._values, ID: addRef.id })
        break
      case "update":
        const updateRef = firestore.doc(commit.path)
        batch.update(updateRef, _values)
        ret.newValues.push({ ..._values })
        break
      case "set":
        const setRef = firestore.doc(commit.path)
        batch.set(setRef, _values)
        ret.newValues.push({ ..._values, ID: setRef.id })
        break
      case "delete":
        const deleteRef = firestore.doc(commit.path)
        batch.delete(deleteRef)
        break
    }
  })

  batch.commit()

  return ret
}

export const createOrUpdateFirestoreBusiness = async params => {
  const { values, isNew } = params

  const path = "Clients/ClientsList/DataBase"

  if (isNew) {
    return await createFirestoreDocument({
      values: values,
      path: path,
    })
  } else {
    return await createOrUpdateFirestoreDocument({
      values: values,
      path: path,
    })
  }
}

export const deleteFirestoreBusiness = async params => {
  const { ID } = params

  const path = `Clients/ClientsList/DataBase/${ID}`

  return await deleteFirestoreDocument({
    path: path,
  })
}
