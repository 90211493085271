import React, { useRef, useState } from "react"
import { Label, Button } from "reactstrap"
import ReactQuill from "react-quill"

import {
  quillFormats,
  quillModules,
} from "../../../../../constants/defaultValues/defaultValues"
import { storage } from "helpers/Firebase"
import { connect } from "react-redux"
import { AvField } from "availity-reactstrap-validation"

const RichText = ({
  item,
  disabled,
  value,
  updateValue,
  firebaseUID,
  path,
}) => {
  const ref = storage
    .ref()
    .child(`Clients/DataBase/${firebaseUID}/ClientData/${path}`)

  const [plainText, setPlainText] = useState(false)
  const [cursorIndex, setCursorIndex] = useState()
  const richTextRef = useRef()
  const [modules] = useState({
    toolbar: {
      container: quillModules.toolbar,
      handlers: {
        image: () => {
          handleImage()
        },
        code: () => {
          setPlainText(!plainText)
        },
      },
    },
  })

  const handleImage = () => {
    setCursorIndex(
      richTextRef.current.getEditorSelection()
        ? richTextRef.current.getEditorSelection().index
        : richTextRef.current.getEditor().getLength()
    )
    const input = document.createElement("input")

    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")
    input.click()

    input.onchange = async () => {
      const file = input.files[0]

      await uploadFile(file, val => {
        richTextRef.current.getEditor().insertEmbed(cursorIndex, "image", val)
      })
    }
  }

  const uploadFile = async (file, callback) => {
    const timeStamp = Date.now()

    const imageRef = ref.child(`${timeStamp}`)

    imageRef.put(file).then(
      async () => {
        const downloadURL = await imageRef.getDownloadURL()

        callback(downloadURL)
      },
      () => {
        NotificationAlert({ type: "error", title: "Failed" })
      }
    )
  }

  return (
    <>
      <Label>{item.Label}</Label>
      {plainText ? (
        <>
          <Button
            className="float-right mb-1"
            type="button"
            onClick={() => {
              setPlainText(!plainText)
            }}
          >
            Back to Editor
          </Button>
          <AvField
            value={value}
            type={"textarea"}
            name={"RichText"}
            disabled={disabled}
            onChange={e => {
              updateValue(e.target.value)
            }}
          />
        </>
      ) : (
        <ReactQuill
          className="mb-3"
          theme="snow"
          value={value}
          ref={richTextRef}
          onChange={val => {
            updateValue(val)
          }}
          modules={modules}
          formats={quillFormats}
          readOnly={disabled}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  return { firebaseUID: client.ID }
}
export default connect(mapStateToProps, {})(RichText)
