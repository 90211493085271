import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import React from "react"
import { withRouter } from "react-router-dom"
import CityPromotionsForm from "./_tabs/CityPromotionsForm"
import CityPromotionsTable from "./_tabs/CityPromotionsTable"

const CityPromotions = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <CityPromotionsForm opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityPromotionsTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityPromotions)
