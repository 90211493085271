import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import UploadPage from "components/ModuleElements/UploadPage/UploadPage"
import React from "react"
import { withRouter } from "react-router-dom"
import CitySurveysForm from "./_tabs/CitySurveysForm"
import CitySurveysTable from "./_tabs/CitySurveysTable"

const CitySurveys = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Survey",
          Component: opened => <CitySurveysForm opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CitySurveysTable />,
        },
      ]}
    />
  )
}

export default withRouter(CitySurveys)
