import React, { useEffect, useState } from "react"
import { Modal, ModalBody, Button } from "reactstrap"
import Select from "react-select"
import { AvForm } from "availity-reactstrap-validation"

import {
  createClientAdministrator,
  updateClientAdministrator,
  getClientAdministrators,
  getClientAdministrator,
  getClientApplicationRolesByClientAndApplicationID,
  getClientAdministratorRoles,
  getUserByEMail,
} from "../../../../helpers/API"
import NotificationAlert from "../../../Common/Notifications/NotificationAlert"
import Text from "components/ModuleElements/Form/Fields/FieldTypes/Text"
import { getSelectTheme } from "helpers/Utils"
import TableControler from "components/ModuleElements/Table/TableControler"

const AdministratorsEditor = ({ clientID, appID }) => {
  const [loading, setLoading] = useState(true)

  const [roleOptions, setRoleOptions] = useState()

  const [modalOpened, setModalOpened] = useState(false)
  const [openedID, setOpenedID] = useState()
  const [defaultData, setDefaultData] = useState()
  const [role, setRole] = useState()

  useEffect(() => {
    load()
  }, [clientID, appID])

  const load = async () => {
    setLoading(true)
    const roleOptionsAPI =
      await getClientApplicationRolesByClientAndApplicationID(clientID, appID)

    setRoleOptions(roleOptionsAPI.Data)
    setLoading(false)
  }

  const openForm = async ID => {
    setDefaultData(undefined)

    if (!ID) {
      setModalOpened(true)
      return
    }

    const apiCall = await getClientAdministrator(ID)

    const roleApiCall = await getClientAdministratorRoles(clientID, ID)

    setDefaultData(apiCall)
    setOpenedID(ID)
    setRole(roleApiCall.Relations[0].Role_ID)
    setModalOpened(true)
  }

  const onAdminUpdate = async (event, errors, values) => {
    if (errors.length === 0) {
      const mergedValues = { ...defaultData, ...values }

      const existingUser = await getUserByEMail(mergedValues.EMail)

      const apiCall = existingUser
        ? await updateClientAdministrator(
            { ...existingUser, ...mergedValues },
            clientID,
            appID,
            role
          )
        : await createClientAdministrator(mergedValues, clientID, appID, role)

      console.log("apiCall", apiCall)

      if (apiCall) {
        setOpenedID(apiCall.MetaData.ID)
        setDefaultData(apiCall.MetaData)
        setRole(Object.keys(apiCall.Roles)[0])
      }
    } else {
      let errorMessage = "Missing: "
      errors.forEach(error => {
        errorMessage += error
        errorMessage += ", "
      })
      NotificationAlert({
        type: "warning",
        title: "",
        message: errorMessage,
        expiration: 3000,
      })
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <Modal
            isOpen={modalOpened}
            toggle={() => {
              if (modalOpened) {
                setOpenedID(undefined)
                setDefaultData(undefined)
                setRole(undefined)
                load()
              }
              setModalOpened(!modalOpened)
            }}
          >
            <ModalBody>
              {modalOpened && (
                <AvForm
                  model={defaultData}
                  onSubmit={(event, errors, values) => {
                    onAdminUpdate(event, errors, values)
                  }}
                >
                  <Text
                    item={{
                      Label: "First Name",
                      Name: "FirstName",
                    }}
                  />

                  <Text
                    item={{
                      Label: "Last Name",
                      Name: "LastName",
                    }}
                  />

                  <Text
                    item={{
                      Label: "Display Name",
                      Name: "DisplayName",
                      Prompt: "Display Name is required",
                    }}
                    required
                  />

                  <Text
                    item={{
                      Label: "E-mail",
                      Name: "EMail",
                      FieldType: "email",
                      Prompt: "E-mail is required",
                    }}
                    required
                  />

                  {openedID && (
                    <Text
                      item={{
                        Label: "Phone Number",
                        Name: "PhoneNumber",
                        Prompt: "Phone Number is required",
                      }}
                    />
                  )}

                  <Select
                    theme={getSelectTheme}
                    className="mb-3"
                    classNamePrefix="select2-selection"
                    onChange={e => setRole(e.ID)}
                    options={[...roleOptions, { ID: "None", Name: "No Role" }]}
                    defaultValue={roleOptions.find(x => x.ID === role)}
                    isOptionSelected={option => option.ID === role}
                    getOptionLabel={option => option.Name}
                  />

                  <Button color="primary" className="float-right">
                    Submit
                  </Button>
                </AvForm>
              )}
              <div />
            </ModalBody>
          </Modal>

          <TableControler
            getData={async params => {
              const data = await getClientAdministrators(clientID)
              return data.Relations
            }}
            columns={[
              { key: "DisplayName", label: "Display Name" },
              { key: "EMail", label: "EMail" },
            ]}
            openButton={{ show: true, onClick: ID => openForm(ID), index: 0 }}
            topButtons={[
              {
                label: "Create new",
                show: true,
                onClick: () => openForm(),
              },
            ]}
          />
        </>
      )}
    </>
  )
}

export default AdministratorsEditor
