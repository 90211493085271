import React from "react"
import { withTranslation } from "react-i18next"
import { Table } from "reactstrap"

import DynamicTableStyledCell from "./_elements/DynamicTableStyledCell"

// shows a reactstrap table with all the given data and options to sort
// dynamically makes the columns based on the received keys
// openButton - shows on the ID column and allows for opening of a certain entry
// actionButtons - adds actionButton columns for quick actions with entries
const DynamicTable = ({
  data,
  columns,
  sortBy,
  setSort,
  openButton,
  actionButtons,
}) => {
  return (
    <div className="table-responsive mb-2">
      <Table className="table table-striped table-sm mb-0">
        <thead>
          <tr>
            {[
              ...columns.map(column => (
                <th
                  key={`heading_${column.key}`}
                  onClick={() => {
                    if (setSort) setSort(column.key)
                  }}
                  className={`${
                    sortBy?.key === column.key
                      ? sortBy?.inverse
                        ? "sorted-asc"
                        : "sorted-desc"
                      : ""
                  }`}
                >
                  {column.label}
                </th>
              )),
              actionButtons &&
                actionButtons.map(actionButton => (
                  <th key={`actionButtonTitle_${actionButton.ID}`}>
                    {actionButton.title}
                  </th>
                )),

              <th key={`copy`}>Copy ID</th>,
            ]}
          </tr>
        </thead>
        <tbody>
          {data.map(i => (
            <tr key={`row_${i.ID || JSON.stringify(i)}`}>
              {[
                ...columns.map((column, index) =>
                  index === openButton.index && openButton?.show ? (
                    <td
                      key={`${column.key}_${i[column.key]}_${
                        i.ID || JSON.stringify(i)
                      }`}
                    >
                      <button
                        className="text-only-button"
                        type="button"
                        onClick={() =>
                          openButton.onClick(i.ID || JSON.stringify(i))
                        }
                        disabled={openButton.disabled}
                      >
                        {i[column.key]}
                      </button>
                    </td>
                  ) : (
                    <DynamicTableStyledCell
                      key={`${column.key}_${i[column.key]}_${
                        i.ID || JSON.stringify(i)
                      }`}
                      _key={`${column.key}_${i[column.key]}_${
                        i.ID || JSON.stringify(i)
                      }`}
                      content={i[column.key]}
                      styling={column.styling}
                    />
                  )
                ),
                actionButtons &&
                  actionButtons.map(actionButton => (
                    <td
                      key={`actionButtonItem_${actionButton.ID}_${
                        i.ID || JSON.stringify(i)
                      }`}
                    >
                      <button
                        className={
                          actionButton.disabled
                            ? "text-only-button-disabled"
                            : "text-only-button"
                        }
                        type="button"
                        onClick={() => actionButton.function(i)}
                        disabled={actionButton.disabled}
                      >
                        {typeof actionButton.buttonTitle === "function"
                          ? actionButton.buttonTitle(i)
                          : actionButton.buttonTitle}
                      </button>
                    </td>
                  )),

                <td key={`copy_${i.ID || JSON.stringify(i)}`}>
                  <button
                    className="text-only-button"
                    type="button"
                    onClick={() =>
                      window.navigator.clipboard.writeText(
                        i.ID || JSON.stringify(i)
                      )
                    }
                  >
                    <i className="simple-icon-note" />
                  </button>
                </td>,
              ]}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default withTranslation()(React.memo(DynamicTable))
