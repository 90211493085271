import FormModule from "components/Modules/Documents/FormModule"
import {
  createFirestoreDocument,
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  getDataFromFirestoreCollection,
  getFirestoreDocument,
} from "helpers/Firestore"

const CityNewsForm = ({ opened }) => {
  let fields = [
    {
      ID: "1",
      Name: "Title",
      Label: "Title",
      Type: "Text",
      Required: true,
      Prompt: "Title is required",
    },
    {
      ID: "18E5F152-AC4A-4F7D-829B-832FEE2FF1B2",
      Type: "Text",
      Name: "Subtitle",
      Label: "Subtitle",
    },
    {
      ID: "FF8F216E-107A-4C1E-A663-74867B5A549E",
      Type: "Text",
      Name: "Supertitle",
      Label: "Supertitle",
    },
    {
      ID: "5",
      Name: "sendNotification",
      Label: "Send Notification",
      Type: "Switch",
    },
    {
      ID: "EDE95D97-B65D-4C8A-BD44-523452345",
      Name: "BreakingNews",
      Label: "Breaking News",
      Type: "Switch",
    },
    {
      ID: "14A6377F-5778-41D2-B29A-A9ED890D3112",
      Type: "DateTime",
      Name: "DateOfEntry",
      Label: "Date of entry",
      Prompt: "Date of Entry is required",
      Required: true,
    },
    {
      ID: "DA248088-A785-472B-9516-2A7E67F1FDB7",
      Type: "DateTime",
      Name: "DateOfPublication",
      Label: "Date of publication",
      Prompt: "Date of publication is required",
      Required: true,
    },
    {
      ID: "675BFF1A-1638-4257-83D8-3EAD1C774070",
      Type: "Text",
      Name: "Author",
      Label: "Autor",
    },
    {
      ID: "87E30CD9-82D0-442F-8698-FF87BF3E8A96",
      Type: "Text",
      FieldType: "textarea",
      Name: "ShortText",
      Label: "Short text",
    },
    {
      ID: "339C10F4-389B-461F-94F9-85B7737D3497",
      Type: "Rich Text Field",
      Name: "FullText",
      Label: "Text",
    },
    {
      ID: "AC3EF22B-D27D-4B3B-B327-B196AE1C6317",
      Type: "Multiple Choice Dropdown",
      Name: "Categories",
      Label: "Categories",
      GetSelectionOptions: async (values, { firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          path: `City/Cities/DataBase/${firebaseUID}/Modules/83/Categories`,
        })
      },
      CreateSelectionOption: async (input, values, { firebaseUID }) => {
        return await createFirestoreDocument({
          path: `City/Cities/DataBase/${firebaseUID}/Modules/83/Categories`,
          values: { Name: input, ID: input },
        })
      },
      DeleteSelectionOption: async (optionID, values, { firebaseUID }) => {
        return await deleteFirestoreDocument({
          path: `City/Cities/DataBase/${firebaseUID}/Modules/83/Categories/${optionID}`,
        })
      },
    },
    {
      ID: "CF5E0A4F-BF8D-4553-9DFF-0A1DDD1CC4A1",
      Type: "Single Image",
      Name: "Picture",
      Label: "Picture",
      Path: "News/",
    },
    {
      ID: "EDE95D97-B65D-4C8A-BD44-523523523",
      Name: "URL",
      Label: "URL",
      Type: "Text",
    },
  ]

  if (opened) {
    fields = fields.filter(x => x.Name !== "sendNotification")
  }

  return (
    <FormModule
      form={{
        title: "News",
        fields: fields,
        fieldsToTranslate: [
          "Title",
          "Subtitle",
          "Supertitle",
          "ShortText",
          "FullText",
        ],
        getDefaultValues: async ({ firebaseUID, defaultLanguage }) => {
          if (!opened)
            return {
              Cities: [firebaseUID],
              BreakingNews: false,
              OriginalLanguage: defaultLanguage,
              sendNotification: false,
            }

          const data = await getFirestoreDocument({
            path: `City/Modules/DataBase/83/DataBase/${opened}`,
            timestampKeys: ["DateOfEntry", "DateOfPublication"],
          })

          return { ...data, sendNotification: false }
        },
        onSubmit: async (values, { firebaseUID }) => {
          // const Translate = await translateObjectWithGoogleAPI(
          //   {
          //     Title: values.Title,
          //     Subtitle: values.Subtitle,
          //     Supertitle: values.Supertitle,
          //     ShortText: values.ShortText,
          //     FullText: values.FullText,
          //   },
          //   {
          //     targets: supportedLanguages
          //       .filter(x => x.ID !== values.OriginalLanguage)
          //       .map(x => x.Code),
          //     source: values.OriginalLanguage,
          //     plainTextFields: ["Title", "Subtitle", "Supertitle", "ShortText"],
          //   }
          // )

          return await createOrUpdateFirestoreDocument({
            path: `City/Modules/DataBase/83/DataBase`,
            values: { ...values },
            timestampKeys: ["DateOfEntry", "DateOfPublication"],
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Modules/DataBase/83/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityNewsForm
