import TabNavigation from "components/Common/UI/TabNavigation"
import React, { useState } from "react"
import { withRouter } from "react-router-dom"

// module for handling tabs
// expects a list of tabs with:
// label - title
// Component - component to render when that tab is opened
const CollectionTabModule = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      {tabs.length > 1 && (
        <div className="p-3">
          <TabNavigation
            activeTab={activeTab}
            setActive={val => setActiveTab(val)}
            navList={tabs.map((tab, i) => ({
              ...tab,
              name: i,
            }))}
          />
        </div>
      )}

      {tabs[activeTab].Component()}
    </>
  )
}

export default withRouter(CollectionTabModule)
