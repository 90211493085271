import CitiesEditor from "./cities"
import Tools from "./tools"
import { adminRoot } from "../../../constants/defaultValues/defaultValues"
import NewsAPI from "./tools/newsapi.org/NewsAPI"
import NewsCatcher from "./tools/newscatcher/NewsCatcher"
import WebsiteNews from "./tools/website-news/WebsiteNews"
import WebsiteBlog from "./tools/website-blog/WebsiteBlog"

export default [
  {
    path: `${adminRoot}editor/cities`,
    component: CitiesEditor,
  },

  //tools
  {
    path: `${adminRoot}editor/tools/news-api`,
    component: NewsAPI,
  },
  {
    path: `${adminRoot}editor/tools/news-catcher`,
    component: NewsCatcher,
  },
  {
    path: `${adminRoot}editor/tools/website-news`,
    component: WebsiteNews,
  },
  {
    path: `${adminRoot}editor/tools/website-blog`,
    component: WebsiteBlog,
  },

  {
    path: `${adminRoot}editor/tools`,
    component: Tools,
  },
]
