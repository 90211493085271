import FormModule from "components/Modules/Documents/FormModule"
import { campaignTypeOptions } from "constants/defaultValues/firebase"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  getFirestoreDocument,
} from "helpers/Firestore"
import HorizontalBannerListFields from "./CampaignTypesForms/HorizontalBannerListFields"
import SquareBannerFields from "./CampaignTypesForms/SquareBannerFields"

const fieldsToTranslate = [
  "Title",
  "SuperSuperTitle",
  "SuperTitle",
  "SubTitle",
  "SecondPage_Title",
  "SecondPage_Description",
]

const types = [
  {
    fields: SquareBannerFields,
    ID: "SquareBanner",
  },
  {
    fields: HorizontalBannerListFields,
    ID: "HorizontalBannerList",
  },
]

const CityCampaignForm = ({ opened }) => {
  const addOn = []

  types.forEach(type => {
    type.fields.forEach(field => {
      const Visible =
        typeof field.Visible === "function" ? field.Visible : vals => true

      addOn.push({
        ...field,
        Visible: vals => vals.Type === type.ID && Visible(vals),
      })
    })
  })

  return (
    <FormModule
      form={{
        title: "Campaign",
        fields: [
          {
            ID: "AC3EF22B-D27D-4B3B-B327-B196AE1C6317",
            Type: "Dropdown",
            Name: "Type",
            Label: "Type",
            GetSelectionOptions: async () => {
              return campaignTypeOptions
            },
          },
          {
            ID: "87E30CD9-82D0-442F-8698-FF87BF3E8A96",
            Type: "Text",
            FieldType: "number",
            Name: "Order",
            Label: "Order",
            Required: true,
            Prompt: "Order is required",
          },
          {
            ID: "DA248088-A785-472B-9516-9208547",
            Type: "DateTime",
            Name: "StartTime",
            Label: "Date of campaign start",
          },
          {
            ID: "DA248088-A785-472B-534534-9208547",
            Type: "DateTime",
            Name: "EndTime",
            Label: "Date of campaign end",
          },

          ...addOn,
        ],
        fieldsToTranslate,
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return { Type: "SquareBanner" }

          const data = await getFirestoreDocument({
            path: `City/Business/Campaigns/${opened}`,
            timestampKeys: ["StartTime", "EndTime"],
          })

          return {
            ...data,
          }
        },
        onSubmit: async (values, { firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: "City/Business/Campaigns",
            timestampKeys: ["StartTime", "EndTime"],
            values: {
              ...values,
              City_ID: firebaseUID,
            },
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Business/Campaigns/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityCampaignForm
