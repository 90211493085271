const Viator = [
  {
    ID: "EDE95D97-B65D-4C8A-BD44-7AC1C8A5B9DE",
    Name: "Title",
    Label: "Title",
    Type: "Text",
    Required: true,
    Prompt: "Title is required",
  },
  {
    ID: "EDE95D97-B65D-4C8A-BD44-42556463",
    Name: "Viator_ID",
    Label: "Viator_ID",
    Type: "Text",
    Required: true,
    Prompt: "Viator_ID is required",
  },
]

export default Viator
