const ImageWithActionButtons = [
  {
    ID: "EDE95D97-B65D-4C8A-BD44-7AC1C8A5B9DE",
    Name: "Title",
    Label: "Title",
    Type: "Text",
    Required: true,
    Prompt: "Title is required",
  },
  {
    ID: "EDE95D97-B65D-4C8A-BD44-52342345",
    Name: "SubTitle",
    Label: "SubTitle",
    Type: "Text",
  },
  {
    ID: "339C10F4-389B-461F-94F9-85B7737D3497",
    Type: "Rich Text Field",
    Name: "Description",
    Label: "Description",
  },
  {
    ID: "CF5E0A4F-BF8D-235464-9DFF-0A1DDD1CC4A1",
    Type: "Single Image",
    Name: "HeaderImage",
    Label: "Header Image",
    Path: "Promotions/",
  },
  {
    ID: "CF5E0A4F-BF8D-235464-9DFF-24352345",
    Type: "Single Image",
    Name: "IconImage",
    Label: "Icon Image",
    ResizeWidth: 600,
    Path: "Promotions/",
  },
  {
    ID: "CF5E0A4F-BF8D-235464-523452-341234",
    Type: "Single Image",
    Name: "BannerImage",
    Label: "Banner Image",
    Path: "Promotions/",
  },
  {
    ID: "CF5E0A4F-BF8D-4553-9DFF-fdrgt",
    Type: "Editable Table",
    Name: "ActionButtons",
    DeletedName: "DeletedActionButtons",
    Label: "Action Buttons",
    Columns: [
      {
        Name: "ID",
        Label: "ID",
      },
      {
        Name: "Type",
        Label: "Type",
        Editable: true,
        Type: "select",
        Title: "Select type",
        Options: [
          { value: "Telephone", text: "Telephone" },
          { value: "Website", text: "Website" },
          { value: "Email", text: "Email" },
          // { value: "VisibilityLink", text: "VisibilityLink" },
        ],
      },
      { Name: "Title", Label: "Title", Editable: true },
      { Name: "Translate.en.Title", Label: "Title in English", Editable: true },
      { Name: "SuperTitle", Label: "SuperTitle", Editable: true },
      {
        Name: "Translate.en.SuperTitle",
        Label: "SuperTitle in English",
        Editable: true,
      },
      { Name: "Value", Label: "Value", Editable: true },
    ],
    Required: false,
    Creatable: true,
    Orderable: true,
  },
]

export default ImageWithActionButtons
