import React, { useEffect, useState } from "react"
import { CustomInput, FormGroup, Label } from "reactstrap"

import { compareCroatianString } from "../../../helpers/Utils"
import CheckboxButtons from "../Form/Fields/FieldTypes/CheckboxButtons"
import { getDataFromFirestoreCollection } from "helpers/Firestore"

const CitySelection = ({ selected, setSelected, disabled }) => {
  const [cities, setCities] = useState([])
  // const [regions, setRegions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(async () => {
      const data = await getDataFromFirestoreCollection({
        path: "City/Cities/DataBase",
      })

      // const regionsAPI = await getAllRegions()

      // regionsAPI.sort((a, b) => compareCroatianString(a.Name, b.Name))

      const sortedData = data

      sortedData.sort((a, b) => compareCroatianString(a.Name, b.Name))

      setCities(sortedData)
      // setRegions(regionsAPI)
      setLoading(false)
    }, 100)
  }, [])

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <div className="p-2">
          <CustomInput
            disabled={disabled}
            type="checkbox"
            id="select-all-button"
            label={
              cities.length === selected.length ? "Remove All" : "Select All"
            }
            className="mb-3"
            checked={cities.length === selected.length}
            onChange={() => {
              if (cities.length === selected.length) {
                const newList = []
                setSelected(newList)
              } else {
                setSelected([...cities.map(x => x.ID)])
              }
            }}
          />

          {/* <Label className="mt-3">Regions</Label>
          <FormGroup check style={{ padding: "0" }} className="mb-3 col-12">
            {regions.map(region => {
              const list = region.List.split(", ")
              const checked = list.every(x => selected.includes(x))
              return (
                <CustomInput
                  type="checkbox"
                  key={`${region.ID}_checkbox_region`}
                  id={`${region.ID}_checkbox_region`}
                  label={region.Name}
                  checked={checked}
                  onChange={() => {
                    if (checked) {
                      setSelected(
                        selected_full
                          .filter(x => !list.includes(x.ID))
                          .map(y => y.ID)
                      )
                    } else {
                      const newSelected = [...selected]
                      list.forEach(city => {
                        if (!selected.includes(city)) {
                          newSelected.push(city)
                        }
                      })
                      setSelected(newSelected)
                    }
                  }}
                  className="col-4 mr-0"
                  inline
                />
              )
            })}
          </FormGroup> */}

          <CheckboxButtons
            item={{
              Label: "Cities",
              GetSelectionOptions: () => cities,
              ID: "city-selection",
            }}
            value={selected}
            updateValue={val => {
              setSelected(val)
            }}
          />
        </div>
      )}
    </>
  )
}

export default CitySelection
