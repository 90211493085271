import TableModule from "components/Modules/Collections/TableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"

const WebsiteBlogTable = () => {
  return (
    <TableModule
      getData={async () => {
        return await getDataFromFirestoreCollection({
          path: `Web/Corporate/DataBase/Blog/DataBase`,
          sortBy: { key: "publishDate", inverse: true },
        })
      }}
      columns={[{ key: "title", label: "Title" }]}
      searchKey={"title"}
    />
  )
}

export default WebsiteBlogTable
