import { getSelectTheme } from "helpers/Utils"
import React, { useEffect, useState } from "react"
import AsyncCreatable from "react-select/async-creatable"
import { components } from "react-select"
import { Label } from "reactstrap"

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.Name}</span>
  </div>
)

const Option = props => {
  const { Name } = props.data
  const { editingMode, onDelete } = props

  return (
    <components.Option {...props}>
      <span>{Name}</span>
      {editingMode ? (
        <button
          type="button"
          className="float-right text-only-button"
          onClick={() => onDelete()}
        >
          <i className="simple-icon-trash" />
        </button>
      ) : (
        <div />
      )}
    </components.Option>
  )
}

const MultipleChoiceDropdown = ({ item, value, updateValue, disabled }) => {
  const [loading, setLoading] = useState(true)
  const [modifiedOptions, setModifiedOptions] = useState([])
  const [editingMode, setEditingMode] = useState(false)

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const options = await item.GetSelectionOptions()

    setModifiedOptions([...options])
    setLoading(false)
  }

  const loadOptions = async input => {
    return modifiedOptions.filter(x =>
      x.Name.toLowerCase().includes(input.toLowerCase())
    )
  }

  // let lastGroupIndex = false

  // item.Options.forEach(option => {
  //   if (option.Type === "Group") {
  //     modifiedOptions.push({
  //       ...option,
  //       options: [],
  //     })
  //     lastGroupIndex = modifiedOptions.length - 1
  //   } else if (lastGroupIndex || lastGroupIndex === 0) {
  //     modifiedOptions[lastGroupIndex].options.push({
  //       ...option,
  //     })
  //   } else {
  //     modifiedOptions.push({ ...option })
  //   }
  // })

  if (loading) return <div />

  return (
    <>
      <Label>
        {item.Label + " "}
        {typeof item.DeleteSelectionOption === "function" ? (
          <>
            (
            <button
              className="text-only-button"
              type="button"
              onClick={() => setEditingMode(!editingMode)}
            >
              <Label>{editingMode ? "Završi" : "Uredi"}</Label>
            </button>
            )
          </>
        ) : (
          <></>
        )}
      </Label>

      <AsyncCreatable
        defaultOptions
        className="mb-3"
        classNamePrefix="select2-selection"
        onCreateOption={async input => {
          if (typeof item.CreateSelectionOption === "function") {
            setLoading(true)
            await item.CreateSelectionOption(input)
            const newValues = [...value]
            if (newValues.find(x => x.ID === input) === undefined)
              newValues.push({ ID: input, Name: input })
            updateValue(newValues)
            setModifiedOptions([...modifiedOptions, { ID: input, Name: input }])
            setLoading(false)
          }
        }}
        onChange={(e, f) => {
          if (editingMode) return

          if (f.action === "select-option") {
            const newValues = [...value]
            newValues.push(f.option)
            updateValue(newValues)
          } else if (f.action === "clear") {
            updateValue([])
          } else {
            const newValues = [...value]
            newValues.splice(
              value.indexOf(value.find(x => x.ID === f.removedValue.ID)),
              1
            )
            updateValue(newValues)
          }
        }}
        value={modifiedOptions.filter(option =>
          value.find(x => x.ID === option.ID)
        )}
        isOptionSelected={option => value.find(x => x.ID === option.ID)}
        getOptionLabel={option => option.Name}
        getOptionValue={option => option.ID}
        isMulti
        formatGroupLabel={formatGroupLabel}
        isDisabled={disabled}
        theme={getSelectTheme}
        loadOptions={loadOptions}
        components={{
          Option: props => (
            <Option
              editingMode={editingMode}
              onDelete={async () => {
                if (typeof item.DeleteSelectionOption === "function") {
                  setLoading(true)
                  await item.DeleteSelectionOption(props.data.ID)
                  const newValues = [...value]
                  newValues.splice(
                    value.indexOf(value.find(x => x.ID === props.data.ID)),
                    1
                  )
                  updateValue(newValues)
                  setModifiedOptions([
                    ...modifiedOptions.filter(x => x.ID !== props.data.ID),
                  ])
                  setLoading(false)
                }
              }}
              {...props}
            />
          ),
        }}
        isCreatable={!editingMode}
      />
    </>
  )
}

export default MultipleChoiceDropdown
